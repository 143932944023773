import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { Field, FieldArray, FieldProps, Form, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadTankCleaningWithkWh } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { queryClient } from 'react-query/queryClient';

export default function TankCleaning() {
    /** Retrieves the VesselID from the Redux store. */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /**
     * Fetches the tank cleaning data along with machinery kWh meter details using the `useQuery` hook.
     * It loads data specific to the vessel's ID and keeps the result in cache indefinitely (`staleTime: Infinity`).
     */
    const {
        data: tankCleaningObject,
        isLoading: tankCleaningLoading,
        isError: tankCleaningError,
    } = useQuery(
        [queryKeyes.vessel.TankCleaningWithkWhMachineries.key, VesselID],
        async () => {
            return await loadTankCleaningWithkWh(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    /**
     * Determines the initial values for the tank cleaning form based on the fetched data.
     * If there is any tank cleaning data available, it is returned for use as initial values.
     * 
     * @returns {Array} Initial values for tank cleaning operations or an empty array.
     */
    const getInitialvaluesSpecialOperationTankCleaning = () => {
        if (tankCleaningObject && tankCleaningObject.length > 0) {
            return tankCleaningObject;
        } else {
            return [];
        }
    };

    /** Defines the Formik configuration object for handling the tank cleaning form. */
    const SpecialOperationTankCleaningFormik = {
        initialValues: {
            tankCleaning: getInitialvaluesSpecialOperationTankCleaning(),
        },
        validationSchema: {},
    };

    return (
        <Card className="border-0">
            {tankCleaningLoading && <Loading message="Loading required data!" />}
            {tankCleaningError && <ErrorComponent message="Unable to load required data!" />}
            {!tankCleaningLoading && !tankCleaningError && (
                <Formik
                    onSubmit={(values: any, actions: any) => {
                        actions.setSubmitting(false);
                        values?.tankCleaning?.forEach((machine: any) => {
                            apiGlobal
                                .put(`/special_operation_energy_setting/${machine.id}/`, machine)
                                .then((res) => {
                                    if (res.status === 200) {
                                        successToast("Data saved successfully!");
                                        queryClient.invalidateQueries(queryKeyes.vessel.TankCleaningWithkWhMachineries.key)
                                    }
                                })
                                .catch((err) => {
                                    if (errResponse.includes(err.response.status)) {
                                        errorToast("Internal error occurred, please contact the admin");
                                    }
                                });
                        });
                    }}
                    initialValues={SpecialOperationTankCleaningFormik.initialValues}
                >
                    {(props: any) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete="off">
                            <CardBody className="p-0">
                                <Row>
                                    <Col sm={12}>
                                        <div className="table-responsive mb-5">
                                            <table className="table">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                        <th className='p-2 align-middle'>Machinery name</th>
                                                        <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FieldArray name="tankCleaning">
                                                        {() => (SpecialOperationTankCleaningFormik?.initialValues &&
                                                            SpecialOperationTankCleaningFormik?.initialValues?.tankCleaning?.map(
                                                                (cargoHeating: any, index: any) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                            <td className='p-2 align-middle'>{cargoHeating.vessel_machinery_ec_group_name}</td>
                                                                            <td className='p-2 align-middle text-center'>
                                                                                <Field name={`tankCleaning.${index}.tank_cleaning_kwh_meter`}>
                                                                                    {({ field }: FieldProps) => (
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id={`tankCleaning.${index}.tank_cleaning_kwh_meter`}
                                                                                            {...field}
                                                                                        />
                                                                                    )}
                                                                                </Field>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )
                                                        )}
                                                    </FieldArray>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="p-2 py-3 mb-3">
                                <Button type="submit" color="primary" className="justify_right">
                                    Save
                                </Button>
                            </CardFooter>
                            <Row className="mt-2">
                                <FormValuesDebug values={[props?.values, props?.errors, SpecialOperationTankCleaningFormik.initialValues]} />
                            </Row>
                        </Form>
                    )}
                </Formik>
            )}
        </Card>
    );
}
