import React, { useEffect, useState } from 'react';
import apiGlobal, { BaseURL } from '../global/api.global';
import { Modal } from 'reactstrap';

interface ViewReportModalType {
    state: any,
    setState: any,
    vesselId: number,
    reportId: number,
    modalId: any
}

const ViewReportModal = ({
    state,
    setState,
    vesselId,
    reportId,
    modalId
}: ViewReportModalType) => {
    /** State variables */
    const [error, setError] = useState(false);
    /** State variables end */

    /** Check if pdf is loading or not */
    useEffect(() => {
        apiGlobal.get(`${BaseURL}/noon-report-pdf/${vesselId}/${reportId}/`).then(response => {
            if (response.data) {
                setError(false);
            }
            setError(false)
        }).catch(err => {
            if (err) {
                setError(true);
            }
        })
    }, [vesselId, reportId])

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setState(false);
        removeBodyCss();
    }

    return (
        <Modal
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={modalId}
            size={error ? 'md' : 'xl'}
            className={error ? "" : "modal-fullscreen p-5 pb-0"}
        >
            <div className="modal-header p-2">
                <h5 className="modal-title" id={modalId}>
                    View Report
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setState(false);
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body p-0">
                {error ?
                    <p style={{ color: '#fd625e', textAlign: 'center', padding: '0px' }}>Error loading report! Please try again later</p> :
                    <iframe
                        src={`${BaseURL}/noon-report-pdf/${vesselId}/${reportId}/`}
                        width='100%'
                        height='100%'
                        title={`${vesselId}/${reportId}`}
                    />
                }
            </div>
        </Modal >
    )
}

export default ViewReportModal