/**
 * file used in Machinery Fuel tab in vessel configuration
 */
import {
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Form,
  CardFooter,
  Input,
} from "reactstrap";
import { useSelector } from "react-redux";
import { RootState } from "../..";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
  loadFuelSettings,
  loadMachineryFuelGroup,
  loadVesselFuelTypes,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldProps, Formik } from "formik";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import apiGlobal from "global/api.global";
import { errorToast, successToast } from "Components/Toasts";
import { errResponse } from "GenericForms/Helper";

const FuelMachineryConfiguration = () => {
  /** State variables start */
  const { VesselID } = useSelector((state: RootState) => state.Reporting);
  /** State variables end */

  /** useQueries */
  /** Load distinct fuel types configured on vessel */
  const {
    data: FuelTypes,
    isLoading: FuelTypesLoading,
    isError: FuelTypesError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.vesselFuelType.key, VesselID],
    async () => {
      return await loadVesselFuelTypes(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load FC machinery */
  const {
    data: VesselMachinery,
    isLoading: VesselMachineryLoading,
    isError: VesselMachineryError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.MachinaryFuelGroup.key, VesselID],
    async () => {
      return await loadMachineryFuelGroup(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** Load Fuel setting */
  const {
    data: FuelSetting,
    isLoading: FuelSettingLoading,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.FuelSettings.key, VesselID],
    async () => {
      return await loadFuelSettings(VesselID);
    },
    {
      enabled: true,
      staleTime: Infinity,
    }
  );
  /** useQueries end */

  /** Assign initial values to fuel consumption Formik object */
  const getFuelInitialValues = () => {
    if (FuelSetting && FuelSetting.length > 0) {
      return { fuel: FuelSetting };
    } else if (VesselMachinery && FuelTypes) {
      const FuelConsumption = VesselMachinery.flatMap((machine: any) =>
        FuelTypes.map((fuel: any) => ({
          vessel: VesselID,
          vessel_machinery_fc_group: machine.id,
          vessel_machinery_fc_group_name: machine.machinery_name,
          fuel_name: fuel.fuel_type,
          selected: false,
        }))
      );
      return { fuel: FuelConsumption };
    }

    return { fuel: [] };
  };

  /** General Settings Formik Object */
  const FuelFormik = {
    initialValues: getFuelInitialValues(),
    validationSchema: {},
  };
  return (
    <>
      <Card className="border-0">
        {(FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) && (
          <Loading message="Loading required data!" />
        )}
        {(FuelTypesError || VesselMachineryError) && (
          <ErrorComponent message="Error loading required data!" />
        )}
        {/* {FuelSettingError && FuelFormik.initialValues=getFuelInitialValues()} */}
        {!(FuelTypesLoading || VesselMachineryLoading || FuelSettingLoading) &&
          !(FuelTypesError || VesselMachineryError) && (
            <Formik
              onSubmit={(values: any, actions: any) => {
                actions.setSubmitting(false);
                if (FuelSetting.length > 0) {
                  values.fuel.forEach((val: any) => {
                    apiGlobal
                      .put(`/vessel_fuel_setting/${val.id}/`, val)
                      .then((res) => {
                        if (res.status === 200) {
                          successToast("Data saved successfully!");
                        }
                      })
                      .catch((err) => {
                        if (errResponse.includes(err?.response?.status)) {
                          errorToast(
                            "Internal error occurred, please contact the admin"
                          );
                        }
                      });
                  });
                } else {
                  apiGlobal
                    .post(`/vessel_fuel_setting/`, values.fuel)
                    .then((res) => {
                      if (res.status === 201) {
                        successToast("Data saved successfully!");
                      }
                    })
                    .catch((err) => {
                      if (errResponse.includes(err?.response?.status)) {
                        errorToast(
                          "Internal error occurred, please contact the admin"
                        );
                      }
                    });
                }
              }}
              initialValues={FuelFormik.initialValues}
            >
              {(props) => (
                <Form
                  onSubmit={props.handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <CardBody className="p-0">
                    <Row>
                      <Col md={12} className="px-0">
                        <div className="table-responsive p-0">
                          <table className="table mb-2">
                            <thead className="table-light">
                              <tr>
                                <th className="p-2 align-middle sr-no-width">
                                  #
                                </th>
                                <th className="p-2 align-middle">
                                  Machinery name
                                </th>
                                {FuelTypes.length > 0 &&
                                  FuelTypes.map(
                                    (fuel_name: any, index: number) => (
                                      <th
                                        key={fuel_name.id || index}
                                        className="p-2 align-middle text-center"
                                        style={{ width: "18%" }}
                                      >
                                        {fuel_name.fuel_type_name}
                                      </th>
                                    )
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {VesselMachinery?.map(
                                (machine: any, machineIndex: number) => {
                                  const filteredFuels =
                                    props.values.fuel?.filter(
                                      (fuel: any) =>
                                        fuel.vessel_machinery_fc_group ===
                                        machine.id
                                    );

                                  return (
                                    <tr key={machine.id || machineIndex}>
                                      <td className="p-2 align-middle text-center">
                                        {machineIndex + 1}
                                      </td>
                                      <td className="p-2 align-middle">
                                        {machine.vessel_machinery_fc_group_name}
                                      </td>
                                      {FuelTypes.map(
                                        (fuelType: any, fuelIndex: number) => {
                                          const fuelEntry = filteredFuels?.find(
                                            (fuel: any) =>
                                              fuel.fuel_name === fuelType.fuel_type
                                          );

                                          const fieldName = `fuel.${props.values.fuel.indexOf(
                                            fuelEntry || {}
                                          )}.selected`;

                                          return (
                                            <td
                                              key={fuelIndex}
                                              className="p-2 align-middle text-center"
                                            >
                                              <Field name={fieldName}>
                                                {({ field }: FieldProps) => (
                                                  <Input
                                                    type="checkbox"
                                                    id={`${machine.id}_${fuelType.id}`}
                                                    name={field.name}
                                                    onBlur={props.handleBlur}
                                                    onChange={(e: any) => {
                                                      props.handleChange(e);
                                                    }}
                                                    defaultChecked={fuelEntry?.selected || false}
                                                  />
                                                )}
                                              </Field>
                                            </td>
                                          );
                                        }
                                      )}
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="p-2 py-3 mb-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="justify_right"
                    >
                      Save
                    </Button>
                  </CardFooter>
                  <Row className="mt-2">
                    <FormValuesDebug
                      values={[
                        props.values,
                        props.errors,
                        FuelFormik.initialValues,
                      ]}
                    />
                  </Row>
                </Form>
              )}
            </Formik>
          )}
      </Card>
      <p>- Set all types of fuels used by the machinery.</p>
    </>
  );
};

export default FuelMachineryConfiguration;
