import { Field, FieldProps, useFormikContext } from 'formik';
import React from 'react'
import { Button, Col, Input, Label, Modal, Row } from 'reactstrap';
import ErrorTooltip from './ErrorTooltip';

const FuelAdjustmentPopup = ({
    state,
    setState,
    tog_backdrop,
    target,
    fuelBatch,
    handleFuelBatchROB,
    bunker
}: any) => {
    const { values, errors, handleChange }: { values: any, errors: any, handleChange: any } = useFormikContext<any>();
    return (
        <Modal
            size='sm'
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={target}
        >
            <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                    Fuel adjustment
                </h5>
                <button
                    onClick={() => {
                        setState(false);
                    }}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Row className='mb-1'>
                    <Col lg={12}>
                        <Label className='mb-0'>Adjustment quantity</Label>
                        <Field name={`fuelConsumptionBatch.${fuelBatch}.temp_adjustment_quantity`}>
                            {({ field }: FieldProps) => (
                                <Input
                                    type="text"
                                    name={field.name}
                                    id={`quantity_${fuelBatch}`}
                                    className="form-control"
                                    onChange={(e: any) => {
                                        handleChange(e);
                                        handleFuelBatchROB(bunker, e, 'adjustment', values?.fuelConsumptionBatch[fuelBatch]);
                                    }}
                                    onBlur={(e: any) => {
                                        handleChange(e);
                                        handleFuelBatchROB(bunker, e, 'adjustment', values?.fuelConsumptionBatch[fuelBatch]);
                                    }}
                                    defaultValue={values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_quantity}
                                />
                            )}
                        </Field>
                        {errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch] &&
                            <ErrorTooltip
                                target={`quantity_${fuelBatch}`}
                                message={errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_quantity}
                                open={
                                    (errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_quantity && state)
                                        ? true : false
                                }
                            />
                        }
                    </Col>
                </Row>
                <Row className='mb-2'>
                    <Col lg={12}>
                        <Label className='mb-0'>Reason for adjustment</Label>
                        <Field name={`fuelConsumptionBatch.${fuelBatch}.temp_adjustment_reason`}>
                            {({ field, form }: FieldProps) => (
                                <Input
                                    type='textarea'
                                    name={field.name}
                                    id={`reason_${fuelBatch}`}
                                    className='form-control'
                                    onChange={(e: any) => form.setFieldValue(`${field.name}`, e.target.value)}
                                    onBlur={(e: any) => form.setFieldValue(`${field.name}`, e.target.value)}
                                    defaultValue={values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_reason}
                                />
                            )}
                        </Field>
                        {errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch] &&
                            <ErrorTooltip
                                target={`reason_${fuelBatch}`}
                                message={errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_reason}
                                open={
                                    (errors && errors?.fuelConsumptionBatch && errors?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_reason && state) ?
                                        true : false
                                }
                            />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col lg={2}>
                        <Button
                            type='button'
                            className='btn'
                            color='primary'
                            onClick={() => {
                                tog_backdrop();
                                values.fuelConsumptionBatch[fuelBatch].adjustment_quantity = values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_quantity;
                                values.fuelConsumptionBatch[fuelBatch].adjustment_reason = values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_reason;
                            }}
                            disabled={!(values && values?.fuelConsumptionBatch[fuelBatch] &&
                                values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_quantity !== null &&
                                (values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_reason !== null &&
                                    values?.fuelConsumptionBatch[fuelBatch]?.temp_adjustment_reason !== ""))}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default FuelAdjustmentPopup