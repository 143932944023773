import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errResponse } from 'GenericForms/Helper';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { Row, Col, Button, Card, CardHeader, CardBody, Form, Label } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys';
import { loadBDNFiles, loadBunkeringObject } from 'VesselMaster/vesselMaster.hooks';
import AsyncSelect from "react-select/async";
import { customStyle } from 'shared/CommonCSS';
import { FieldArray, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal, { apiMedia } from 'global/api.global';
import { BDNConstant, FileStatus } from 'shared/constants';
import { queryClient } from 'react-query/queryClient';
import DeletePopOver from 'Components/DeletePopOver';
import ReportingFileUpload from 'Components/ReportingFileUpload';

interface BunkeringType {
    ReportID: number;
    VesselID: number;
    setModalState?: (value: boolean) => void;
    modalState?: boolean;
}

const BunkeringFileUpload = ({
    ReportID,
    VesselID,
    setModalState,
    modalState,
}: BunkeringType) => {
    /** State variables */
    const [count, setCount] = useState(0);
    const [formKey, setFormKey] = useState(0);
    const [newFileBool, setNewFileBool] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popOverId, setPopOverId] = useState(0);
    const [BDNFile, setBDNFile] = useState<Array<any>>([{}]);
    const [refreshKey, setRefreshKey] = useState<number>(0)
    const DeleteFileObj: any = {
        file_name: null,
        file_path: null,
    }
    /** State variables end */

    /** useQueries */
    /**Bunkering object used for edit */
    const { data: BunkeringObject, isLoading: BunkeringObjectLoading, isError: BunkeringObjectError } = useQuery(
        [queryKeyes.vessel.BunkeringObject.key, VesselID, ReportID],
        async () => {
            return await loadBunkeringObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /**Bunkering object used for edit */
    const { data: BDNFiles, isLoading: BDNFilesLoading, isError: BDNFilesError } = useQuery(
        [queryKeyes.vessel.BDNfiles.key, VesselID, ReportID],
        async () => {
            return await loadBDNFiles(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to formik's initial object */
    const getInitialValues = (): any[] => {
        let BDNArray: any[] = []
        if (BDNFiles && BDNFiles.length > 0) {
            BDNFiles.forEach((obj: any) => {
                obj = {
                    ...obj,
                    bunkering: obj?.bunkering?.split(',')?.map(Number)
                }
                BDNArray.push(obj)
            })

        } else {
            BDNArray.push({
                bdn_file: null,
                status: BDNConstant.PENDING,
                file_name: "",
                bunkering: [],
                vessel_reporting_information: ReportID
            });
        }
        return BDNArray
    }

    /** BDN Upload Formik object */
    const BDNFileUploadFromik = {
        initialValues: {
            bunkering: getInitialValues(),
        }
    }

    /** Post submit actions based on response */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
            setFormKey(formKey + 1);
            if (modalState) {
                setModalState(!modalState);
            }
        }
    }

    /** POST request for Bunkering */
    const BunkeringFileUploadSubmit = (values: any, actions: any) => {
        values?.bunkering?.forEach((bunker: any, index: number) => {
            if (bunker.id) {
                const formData = new FormData();
                Object.keys(bunker).forEach(key => {
                    if (bunker[key] === null || undefined) return;
                    formData.append(key, bunker[key]);
                });
                if (BDNFile && Array.isArray(BDNFile) && BDNFile[index].file) {
                    formData.set('bdn_file', BDNFile[index].file)
                }
                apiMedia.put(`/bdn_file_upload/${bunker.id}/`, formData).then(async res => {
                    await queryClient.invalidateQueries(queryKeyes.vessel.BDNfiles.key);
                    await queryClient.invalidateQueries(queryKeyes.vessel.BDNFileUploadList.key);
                    values.bunkering = res.data;
                    handleResponse(res);
                    actions.setSubmitting(false);
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });
            }
            else {
                const formData = new FormData();
                Object.keys(bunker).forEach(key => {
                    if (values.bunkering[key] === null || undefined) return;
                    formData.append(key, bunker[key]);
                });
                if (BDNFile && Array.isArray(BDNFile) && BDNFile[index].file) {
                    formData.set('bdn_file', BDNFile[index].file)
                }
                apiMedia.post(`/bdn_file_upload/`, formData).then(async res => {
                    await queryClient.invalidateQueries(queryKeyes.vessel.BDNfiles.key);
                    values.bunkering = res.data;
                    handleResponse(res);
                    actions.setSubmitting(false);
                }).catch(err => {
                    if (errResponse.includes(err.response.status)) {
                        errorToast("Internal error occured, please contact the admin");
                    }
                });

            }
        })
    }

    const deleteFileUploadItem = (index: number) => {
        const updatedItems = [...BDNFile]; // Clone the state array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        setBDNFile(updatedItems); // Update the state
    };

    useEffect(() => {
        setFormKey(formKey + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BunkeringObject])

    /** Filter Bunkering object to display unselected batches */
    const loadFilteredBunkeringObject = async (vesselId: number, reportId: number, values: any) => {
        try {
            const response = await apiGlobal.get(
                queryKeyes.vessel.BunkeringObject.url(vesselId, reportId)
            );
            let res;
            if (values.bunkering[0]?.bunkering?.length > 0) {
                let ids: any[] = [];
                values.bunkering.forEach((item: any) => {
                    item.bunkering.map((val: any) => ids.push(val));
                })
                res = response.data.filter((item: any) => !ids.includes(item.id));
            }
            else {
                res = response.data;
            }
            if (res.length <= 0) {
                setNewFileBool(true);
            } else {
                setNewFileBool(false);
            }
            return res;
        } catch (error) {
            console.error(error);
            return [];
        }
    }
    return (
        <React.Fragment>
            {(BunkeringObjectLoading || BDNFilesLoading) && <Loading message='Loading required data!' />}
            {(BunkeringObjectError || BDNFilesError) && <ErrorComponent message='Error loading component' />}
            {BDNFilesError && getInitialValues()}
            {BDNFilesError && <ErrorComponent message='Error loading component' />}
            {(!BunkeringObjectLoading && !BunkeringObjectError && !BDNFilesLoading && !BDNFilesError) &&
                <Card className='p-0 mb-0 border-0'>
                    <CardHeader className='p-2'>
                        <div className="text-center">
                            <Row>
                                <Col>
                                    <h4 className="page_title pos-start mb-0">BDN(s) Upload</h4>
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <CardBody className='px-2'>

                        {!(BunkeringObjectLoading || BDNFilesLoading) && !(BunkeringObjectError || BDNFilesError) &&
                            <Formik
                                initialValues={BDNFileUploadFromik.initialValues}
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(true);
                                    BunkeringFileUploadSubmit(values, actions);
                                }}
                                key={formKey}
                            >
                                {props => (
                                    <Form autoComplete="off" onSubmit={props?.handleSubmit}>
                                        <FieldArray name="bunkering">
                                            {({ push, remove }) => (
                                                <>
                                                    <h5>Select bunkers for common BDN file upload</h5>
                                                    {props?.values?.bunkering.map((bunker: any, index: number) => {
                                                        return (
                                                            <React.Fragment>
                                                                <Row className='mb-2'>
                                                                    <Col sm={6} className='d-flex align-items-center'>
                                                                        <strong className='mb-0 mr-2'>Select bunkers</strong>
                                                                        <AsyncSelect
                                                                            key={count}
                                                                            name={`bunkering.${index}.bunkering`}
                                                                            className='label-w-20'
                                                                            cacheOptions
                                                                            defaultOptions
                                                                            loadOptions={() => loadFilteredBunkeringObject(VesselID, ReportID, props?.values)}
                                                                            getOptionLabel={(e: any) => e.bunkering_supply_name}
                                                                            getOptionValue={(e: any) => e.id}
                                                                            styles={customStyle}
                                                                            isMulti={true}
                                                                            onChange={
                                                                                (e: any) => {
                                                                                    props?.setFieldValue(`bunkering.${index}.bunkering`, e.map((bunker: any) => { return bunker?.id }))
                                                                                    setCount(count + 1);
                                                                                }
                                                                            }
                                                                            defaultValue={
                                                                                props?.values?.bunkering[index]?.bunkering?.length > 0 &&
                                                                                props?.values?.bunkering[index]?.bunkering?.map((id: any) => {
                                                                                    const matchedItem = BunkeringObject.find((item: any) => item.id === id);
                                                                                    return matchedItem ? matchedItem : null;
                                                                                }).filter((item: any) => item !== null)
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col sm={bunker?.status === BDNConstant.UPLOADED ? 3 : 5}>
                                                                        {index !== undefined &&
                                                                            <ReportingFileUpload
                                                                                key={index}
                                                                                setFile={setBDNFile}
                                                                                file={BDNFile}
                                                                                fileUploadStatus={props?.values?.bunkering[index]?.bunkering?.status === FileStatus.UPLOADED}
                                                                                fileURL={props?.values?.bunkering[index]?.bunkering?.file_path}
                                                                                deleteURL={`/bdn_file_upload/`}
                                                                                invalidateQuery={queryKeyes.vessel.BDNfiles.key}
                                                                                deleteID={props?.values?.bunkering[index]?.bunkering?.id}
                                                                                refreshKey={refreshKey}
                                                                                setRefreshKey={setRefreshKey}
                                                                                title={''}
                                                                                DeleteFileObj={DeleteFileObj}
                                                                                sm={5}
                                                                                index={index}
                                                                            />}
                                                                    </Col>
                                                                    <Col sm={1}>
                                                                        {props?.values?.bunkering?.length === 1 ? null : (
                                                                            <React.Fragment>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn justify_right"
                                                                                    id={`delete-button-${index + 1}`}
                                                                                >
                                                                                    <i
                                                                                        className="dripicons-trash icon_s18"
                                                                                        onClick={() => {
                                                                                            setPopoverOpen(true);
                                                                                            setPopOverId(index + 1);
                                                                                        }}
                                                                                        onBlur={(e: any) => { props?.handleBlur(e); }}
                                                                                    />
                                                                                </button>
                                                                            </React.Fragment>
                                                                        )}

                                                                    </Col>
                                                                </Row>
                                                                {index === (props?.values.bunkering.length - 1) && !newFileBool && BunkeringObject?.length > 1 &&
                                                                    props?.values.bunkering.length < BunkeringObject?.length &&
                                                                    <Label
                                                                        className="link_color_blue mt-2"
                                                                        onClick={() => {
                                                                            push({
                                                                                bdn_file: null,
                                                                                status: BDNConstant.PENDING,
                                                                                file_name: "",
                                                                                bunkering: [],
                                                                                vessel_reporting_information: ReportID
                                                                            });
                                                                            const addEmptyBDNObject = [...BDNFile, {}]
                                                                            setBDNFile(addEmptyBDNObject)
                                                                        }}
                                                                    >
                                                                        Add another BDN file
                                                                    </Label>
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                    {popoverOpen &&
                                                        < DeletePopOver
                                                            target={`delete-button-${popOverId}`}
                                                            state={popoverOpen}
                                                            setState={setPopoverOpen}
                                                            onClick={() => {
                                                                remove(popOverId - 1);
                                                                setCount(count + 1);
                                                                setPopoverOpen(false);
                                                                deleteFileUploadItem(popOverId - 1)
                                                            }}
                                                        />
                                                    }
                                                </>
                                            )}
                                        </FieldArray>
                                        {BunkeringObject && BunkeringObject?.length > 0 &&
                                            <Row>
                                                <Col sm={{ size: 1, offset: 11 }} className='p-2'>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="btn_size_cstm justify_right"
                                                        disabled={props?.isSubmitting}
                                                    >
                                                        Save
                                                    </Button>
                                                </Col>
                                            </Row>
                                        }
                                        <FormValuesDebug values={[props?.values, props?.errors, BDNFileUploadFromik.initialValues]} />
                                    </Form>
                                )}
                            </Formik>
                        }
                    </CardBody>
                </Card >
            }
        </React.Fragment>
    )
}

export default BunkeringFileUpload