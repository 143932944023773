import React, {  useEffect } from 'react';
import { CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '..';
import { getVoyagesAction } from "../Store/Generic/ReportingSlice";
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import { voyageList } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import VoyageTableHeadComponent from './VoyageTableHeadComponent';

const PreviousVoyages = () => {
    const dispatch = useDispatch();
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    useEffect(() => {
        dispatch(getVoyagesAction('voyage_information' as string));
    }, [dispatch]);

   
    const { data: voyages, isFetching: voyagesFetching, isError: voyagesError } = useQuery([queryKeyes.vessel.VoyageList.key, VesselID],
        async () => await voyageList(VesselID), {
        staleTime: 600000
    })
    return (
        <>
            <React.Fragment>
                <div className='ele_row mb-4'>
                    <h4 id='owner_table'>Voyage Reporting Information</h4>
                </div>
                <CardBody className='p-0'>
                    <div className="table-responsive" id='owner_table'>
                        {(voyagesFetching) && (
                            <Loading message='Voyage list is loading!' />
                        )}
                        {voyagesError && (
                            <ErrorComponent message='Unable to load voyage list!' />
                        )}
                        {((!voyagesFetching && !voyagesError) && voyages.length > 0) &&
                            <table className="table mb-0">
                                <VoyageTableHeadComponent />
                                <tbody>
                                    {(voyagesFetching) && (
                                        <tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <Loading message='Voyage list is loading' />
                                                </td>
                                            </tr>
                                        </tr>
                                    )}
                                    {voyagesError && (
                                        <tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="alert-border-left alert alert-danger alert-dismissible fade show" role="alert">
                                                        <button type="button" className="btn-close" aria-label="Close"></button>
                                                        <i className="mdi mdi-block-helper me-3 align-middle"></i>
                                                        <strong>Error</strong> - Unable to load voyage list!</div>
                                                </td>
                                            </tr>
                                        </tr>
                                    )}
                                    {((!voyagesFetching && !voyagesError) && voyages.filter((item: any) => item.voyage_status === 3).length > 0) ?
                                        voyages.filter((item: any) => item.voyage_status === 3).map((Voyage: any, index: number) => (
                                            <tr key={Voyage.id}>
                                                <td className="p-2 align-middle text-center">{index + 1}</td>
                                                <td className="p-2 align-middle">{Voyage.voyage_number}</td>
                                                <td className="p-2 align-middle text-left">Finished</td>
                                                <td className="p-2 align-middle text-center">
                                                    {/* <i className='dripicons-preview icon_s18 text-center' onClick={() => {
                                                        tog_backdrop_view();
                                                    }}
                                                        data-toggle="modal"
                                                        id='view_details'
                                                    />
                                                    <ToolTip target='view_details' message='View details' /> */}
                                                </td>
                                            </tr>
                                        )) :
                                        <tr>
                                            <td colSpan={8}>
                                                <p className='text-center mb-0'>No previous voyages available</p>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </CardBody>
            </React.Fragment>
        </>
    )
}

export default PreviousVoyages