import React, { useState } from 'react'
import Layout from 'HorizontalMenu/Menu'
import Select from 'react-select'
import { Navbar, Container, Row, Label, Col } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import PopUp from 'Components/PopUp'
import CreatePool from './CreatePool'

export const complainceBalanceTable = [
    { id: 1, vessel: 'Vessel-A', imo: 'PO-1112', compliance_balance: 950, available_balance: 950 },
    { id: 2, vessel: 'Vessel-B', imo: 'PO-1113', compliance_balance: -350, available_balance: -350 },
    { id: 3, vessel: 'Vessel-C', imo: 'PO-1114', compliance_balance: -150, available_balance: -150 },
    { id: 4, vessel: 'Vessel-D', imo: 'PO-1115', compliance_balance: 100, available_balance: 100 },
]

export const years = [
    { id: 1, year: 2024 },
    { id: 2, year: 2025 },
    { id: 3, year: 2026 },
    { id: 4, year: 2027 },
    { id: 5, year: 2028 },
    { id: 6, year: 2029 },
    { id: 7, year: 2030 },
]

const Pooling = () => {
    /** State variables start */
    const [createBool, setCreateBool] = useState(false)
    /** State variables end */
    return (
        <React.Fragment>
            <Layout children={Navbar} />
            <div className="page-content">
                <Container fluid>
                    <Row className="mb-1">
                        <Col sm={{ size: 2, offset: 2 }}>
                            <h4>Pooling</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 2, offset: 2 }}>
                            <Label className="mb-0">Select year</Label>
                            <Select
                                options={years}
                                getOptionLabel={(option: any) => option.year}
                                getOptionValue={(option: any) => option.id}
                                menuPortalTarget={document.body}
                                styles={customStyle}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col sm={{ size: 3, offset: 2 }} className='d-flex align-items-center'>
                            <h5 className='mb-0'>Compliance Balance</h5>
                        </Col>
                        <Col sm={{ size: 2, offset: 3 }}>
                            <button color='primary' className='btn btn-primary justify_right' onClick={() => { setCreateBool(true) }}>
                                <i className="dripicons-plus font-size-16 align-middle" />
                                Create New
                            </button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{ size: 8, offset: 2 }}>
                            <div className="table-responsive p-0">
                                <table className="table mb-2">
                                    <thead className="table-light">
                                        <tr>
                                            <th className="p-2 align-middle text-center sr-no-width">#</th>
                                            <th className="p-2 align-middle text-center">Vessel</th>
                                            <th className="p-2 align-middle text-center">IMO number</th>
                                            <th className="p-2 align-middle text-right">Compliance balance</th>
                                            <th className="p-2 align-middle text-right">Available balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {complainceBalanceTable?.map((compliance: any) => {
                                            return (
                                                <tr>
                                                    <td className="p-2 align-middle sr-no-width">{compliance?.id}</td>
                                                    <td className="p-2 align-middle text-center">{compliance?.vessel}</td>
                                                    <td className="p-2 align-middle text-center">{compliance?.imo}</td>
                                                    <td className='p-2 align-middle text-right'>
                                                        <Label className=
                                                            {`mb-0  ${compliance?.compliance_balance > 0 ? 'coloured-bg-green' : 'label-red'}`}
                                                        >
                                                            {compliance?.compliance_balance}
                                                        </Label>
                                                    </td>
                                                    <td className='p-2 align-middle text-right'>
                                                        <Label className=
                                                            {`mb-0  ${compliance?.available_balance > 0 ? 'coloured-bg-green' : 'label-red'}`}
                                                        >
                                                            {compliance?.available_balance}
                                                        </Label>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            <th colSpan={3} className='p-2 align-middle text-right'>Total</th>
                                            <td className='p-2 align-middle text-right'><Label className='coloured-bg-green'>550</Label></td>
                                            <td className='p-2 align-middle text-right'><Label className='coloured-bg-green'>550</Label></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {createBool &&
                        <PopUp
                            state={createBool}
                            setState={setCreateBool}
                            body={
                                <CreatePool
                                    years={years}
                                    setCreateBool={setCreateBool}
                                />
                            }
                            title='Create Pool'
                            size='lg'
                        />
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Pooling