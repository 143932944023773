import React, { useState } from 'react';
import { Card, CardBody, Col, Row, Input, Label, Button, Form, FormFeedback, Container } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import apiGlobal from '../../global/api.global';
import { successToast } from 'Components/Toasts';
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import '../../global/GlobalCSS.css';
import { Link } from 'react-router-dom';

const ProfileDetail = () => {
    const [data, setData] = useState({
        first_name: "",
        last_name: "",
        user_role: "",
        assigne_ship: "",
        login_email: "",
        pass_recovery_email: "",
    })

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: '',
            last_name: '',
            user_role: '',
            assigne_ship: '',
            login_email: '',
            pass_recovery_email: '',
        },
        validationSchema: Yup.object({
            first_name: Yup.string().matches(
                /^[A-Za-z ]{1,50}$/, "Please enter upto 50 alphabets only"),
            last_name: Yup.string().matches(
                /^[A-Za-z ]{1,50}$/, "Please enter upto 50 alphabets only"),
            user_role: Yup.string().matches(
                /^[A-Za-z ]{1,50}$/, "Please enter upto 50 alphabets only"),
            assigne_ship: Yup.string().matches(
                /^[A-Za-z,0-9 ]{1,50}$/, "Please enter upto 50 alphabets only"),
            login_email: Yup.string().email("Must be a valid login_email"),
            pass_recovery_mail: Yup.string().email("Must be a valid login_email"),
        }),
        onSubmit: () => {
            // console.log(values)
        }
    })

    const handleChanges = (event: any, validation: any) => {
        validation.handleChange(event);
        handle(event);
    }

    function handle(e: any) {
        const newdata: any = { ...data }
        newdata[e.target.name] = e.target.value
        setData(newdata)
    }

    const datas = {
        first_name: data.first_name,
        last_name: data.last_name,
        user_role: data.user_role,
        assigne_ship: data.assigne_ship,
        login_email: data.login_email,
        pass_recovery_login_email: data.pass_recovery_email
    }

    function submit() {
        apiGlobal.post(`/profiledetails/`, datas).then(() => {
            successToast("Data saved successfully!");
        }).catch(err => {
            console.error(err);
        });
    }

    return (
        <>
            <React.Fragment>
                <Container>
                    <div className='page-content'>
                        <Row>
                            <Col lg={2}>
                                <Link to='/dashboard' color='primary' className='btn btn-primary mb-3'><i className="bx bx-chevron-left me-1" /> Back</Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <Card className='pos-center mt-2'>
                                    <CardBody>
                                        <div className="d-flex">
                                            <div className="ms-3">
                                                <img
                                                    src={avatar1}
                                                    alt=""
                                                    className="avatar-1 rounded-circle img-thumbnail img_height"
                                                />
                                            </div>
                                            {<div className="flex-grow-1 align-self-center ms-3">
                                                <div className="text-muted">
                                                    {/* <h5>{first_name}</h5>
                        <p className="mb-1">{login_email}</p>
                        <p className="mb-0">Id no: #{idx}</p> */}
                                                    <h3 className="mb-2 ml-1">Change User Details</h3>
                                                </div>
                                            </div>}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div >
                            <Card className='pos-center' style={{ maxWidth: '25rem' }}>
                                <CardBody>
                                    <Form className="needs-validation" autoComplete='off'
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            submit();
                                            return false;
                                        }}>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="mb-3">
                                                    <Label for="first_name" className='mb-0'>First name</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="first_name"
                                                        name="first_name"
                                                        onChange={(e: any) => handleChanges(e, validation)}
                                                        value={validation.values.first_name || data.first_name}
                                                        invalid={
                                                            validation.touched.first_name && validation.errors.first_name ? true : false
                                                        }
                                                        autoFocus
                                                    />
                                                    {validation.touched.first_name && validation.errors.first_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="mb-3">
                                                    <Label for="last_name" className='mb-0'>Last name</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="last_name"
                                                        name="last_name"
                                                        onChange={(e: any) => handleChanges(e, validation)}
                                                        value={validation.values.last_name || data.last_name}
                                                        invalid={
                                                            validation.touched.last_name && validation.errors.last_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.last_name && validation.errors.last_name ? (
                                                        <FormFeedback type="invalid">{validation.errors.last_name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="mb-3">
                                                    <Label for="user_role" className='mb-0'>User role</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="user_role"
                                                        name="user_role"
                                                        onChange={(e: any) => handleChanges(e, validation)}
                                                        value={validation.values.user_role || data.user_role}
                                                        invalid={
                                                            validation.touched.user_role && validation.errors.user_role ? true : false
                                                        }
                                                    />
                                                    {validation.touched.user_role && validation.errors.user_role ? (
                                                        <FormFeedback type="invalid">{validation.errors.user_role}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="mb-3">
                                                    <Label for="assigne_ship" className='mb-0'>Assigne ship</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="assigne_ship"
                                                        name="assigne_ship"
                                                        onChange={(e: any) => handleChanges(e, validation)}
                                                        value={validation.values.assigne_ship || data.assigne_ship}
                                                        invalid={
                                                            validation.touched.assigne_ship && validation.errors.assigne_ship ? true : false
                                                        }
                                                    />
                                                    {validation.touched.assigne_ship && validation.errors.assigne_ship ? (
                                                        <FormFeedback type="invalid">{validation.errors.assigne_ship}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="mb-3">
                                                    <Label for="login_email" className='mb-0'>Email id</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="login_email"
                                                        name="login_email"
                                                        onChange={(e: any) => handleChanges(e, validation)}
                                                        value={validation.values.login_email || data.login_email}
                                                        invalid={
                                                            validation.touched.login_email && validation.errors.login_email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.login_email && validation.errors.login_email ? (
                                                        <FormFeedback type="invalid">{validation.errors.login_email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="mb-3">
                                                    <Label for="login_email" className='mb-0'>Recovery email id</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="pass_recovery_email"
                                                        name="pass_recovery_email"
                                                        onChange={(e: any) => handleChanges(e, validation)}
                                                        value={validation.values.pass_recovery_email || data.pass_recovery_email}
                                                        invalid={
                                                            validation.touched.pass_recovery_email && validation.errors.pass_recovery_email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.pass_recovery_email && validation.errors.pass_recovery_email ? (
                                                        <FormFeedback type="invalid">{validation.errors.pass_recovery_email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className=" text-center mt-4 pos-center">
                                                <Button type="submit" color="primary" onClick={submit}>Update user profile</Button>
                                                {/* <Button type="reset" color="danger" className='btn_size10_5_cstm'>Reset</Button> */}
                                            </div>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Container>
            </React.Fragment>
        </>
    )
}
export default ProfileDetail;