import React from "react";
import { Container, Row, Col } from "reactstrap";

import error from "../Media/error-img.png"

const PageNotFound = () => {
    return (
        <React.Fragment>
            <div className="my-5 pt-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mb-5">
                                <h1 className="display-1 fw-semibold">
                                    4<span className="text-primary">0</span>4
                                </h1>
                                <h4>You either do not have the necessary permissions to access this page or the page was not found. Please check the URL or contact the administrator if you believe this is an error.
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-xl-8">
                            <div>
                                <img src={error} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PageNotFound;
