import ReportDetailsHeader from 'Components/ReportDetailsHeader'
import React from 'react'
import { Row, Col, TabContent, TabPane, Card, CardBody, CardHeader } from 'reactstrap'
import OtherDetailsComponent from './partials/OtherDetailsComponent'

const ProactiveAddInformation = ({
    ReportID,
    VesselID,
    VoyageID,
    tabsIdList,
    toggleDynamicTabs,
    previousTabIndex,
    toggleTab,
    record,
    tabsList
}: any) => {
    /** State variables */
    // const [activeAddTab, setactiveAddTab] = useState(1);
    /** State variables end */
    return (
        <Card className='p-0 mb-0 border-0'>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className='p-0'>
                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <TabContent activeTab={1}>
                        <TabPane tabId={1}>
                            <OtherDetailsComponent
                                ReportID={ReportID}
                                VesselID={VesselID}
                                VoyageID={VoyageID}
                                toggleTab={toggleTab}
                                tabsIdList={tabsIdList}
                                toggleDynamicTabs={toggleDynamicTabs}
                                previousTabIndex={previousTabIndex}
                                record={record}
                                tabsList={tabsList}
                            />
                        </TabPane>
                    </TabContent>
                </div>
            </CardBody>
        </Card>
    )
}

export default ProactiveAddInformation