import React from 'react'
import { FieldArray, useFormikContext } from 'formik'
import FirstReportFormRow from './FirstReportFormRow'
import { CardBody } from 'reactstrap';

export interface fieldObjectInterface {
    label: string,
    field: string,
    input: string,
    selectLabel?: string,
    selectValue?: string,
    unit?: string,
    loadOptions?: () => Promise<{ [key: string]: string | number }[]>;
    value?: string | number;
}

interface FirstReportFormType {
    fieldArray: fieldObjectInterface[],
    isArray: boolean,
    arrayName?: string,
    initialValues?: { [key: string]: string | number | Date | boolean | null }[];
}

const FirstReportForm = ({
    fieldArray,
    isArray,
    arrayName,
    initialValues
}: FirstReportFormType) => {
    const { values }: { values: { [key: string]: { [key: string]: string | number | Date | boolean | null }[] } } = useFormikContext();
    return (
        <React.Fragment>
            {isArray === true ?
                <FieldArray name={arrayName}>
                    {({ push, remove }) => (
                        <React.Fragment>
                            {values[arrayName]?.map((_value: { [key: string]: string | number | Date | boolean | null }, index: number) => {
                                return (
                                    <CardBody key={index} className='p-2'>
                                        <FirstReportFormRow
                                            fieldArray={fieldArray}
                                            index={index}
                                            isArray={isArray}
                                            arrayName={arrayName}
                                            push={push}
                                            remove={remove}
                                            initialValues={initialValues}
                                        />
                                    </CardBody>
                                )
                            })}
                        </React.Fragment>
                    )}
                </FieldArray>
                :
                <CardBody className='p-2'>
                    <FirstReportFormRow
                        fieldArray={fieldArray}
                        isArray={isArray}
                    />
                </CardBody>
            }
        </React.Fragment>
    )
}

export default FirstReportForm