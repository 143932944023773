import { RootState } from 'index';
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { queryKeyes } from 'shared/queryKeys';
import { vesselFuel } from 'VesselMaster/vesselMaster.hooks';
import Loading from './Loading';
import ErrorComponent from './ErrorComponent';
import { calculateEUA } from 'GenericForms/Helper';

interface EUMRVFuelEUTableType {
    eumrvData: any,
    operation: string,
    totalEUA?: number,
    setTotalEUA?: any
}

const EUMRVFuelEUTable = ({
    eumrvData,
    operation,
    setTotalEUA
}: EUMRVFuelEUTableType) => {
    /** State variables start */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [consumptionEmmission, setConsumptionEmmission] = useState<Array<any>>([]);
    /** State varibles end */

    /** useQueries */
    /** Fetch Vessel distinct fuel  */
    const {
        data: fuelTypes,
        isLoading: fuelTypesLoading,
        isError: fuelTypesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    /** useEffect */
    useEffect(() => {
        let array: any[] = []
        if (operation === 'FuelEU') {
            array.push('Fuel Consumption');
        } else {
            array.push('Fuel Consumption', 'CO2', 'CH4', 'N2O');
        }
        setConsumptionEmmission(array);
    }, [operation])
    useEffect(() => {
        if (operation === 'EUMRV') {
            let total = 0;
            eumrvData?.forEach((item: any) => {
                total += parseFloat(calculateEUA(item));
            })
            setTotalEUA(total?.toFixed(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eumrvData])
    /** useEffect end */

    return (
        <React.Fragment>
            {fuelTypesLoading && <Loading message='Loading required data!' />}
            {fuelTypesError && <ErrorComponent message='Error loading required data!' />}
            {(!fuelTypesLoading && !fuelTypesError) &&
                <div className="table-responsive vertical-table-scroll">
                    <table className="table m-0">
                        <thead className="table-light">
                            <tr>
                                <th className="text-center p-2">Sr. No.</th>
                                <th className='text-center p-2'>Start date</th>
                                <th className='text-center p-2'>End date</th>
                                <th className='text-center p-2'>Departure port</th>
                                <th className='text-center p-2'>Arrival port</th>
                                <th className='text-center p-2' colSpan={fuelTypes?.length}>Fuel consumption</th>
                                {operation === 'EUMRV' &&
                                    <React.Fragment>
                                        <th className='text-center p-2' colSpan={fuelTypes?.length}>CO<sub>2</sub> emmission</th>
                                        <th className='text-center p-2' colSpan={fuelTypes?.length}>CH<sub>4</sub> emmission</th>
                                        <th className='text-center p-2' colSpan={fuelTypes?.length}>N<sub>2</sub>O emmission</th>
                                    </React.Fragment>
                                }
                                <th className='text-center p-2'>Applicability</th>
                                {operation === 'EUMRV' &&
                                    <th className='text-center p-2'>Emmission applicable to EU</th>
                                }
                                {operation === 'EUMRV' ?
                                    <th className='text-center p-2'>EUA</th> :
                                    <React.Fragment>
                                        <th className='text-center p-2'>GHGIE</th>
                                        <th className='text-center p-2'>CB</th>
                                        <th className='text-center p-2'>Penalty</th>
                                    </React.Fragment>
                                }
                            </tr>
                            <tr>
                                <th colSpan={5}></th>
                                {consumptionEmmission?.map((item: any) => (
                                    <React.Fragment key={item}>
                                        {fuelTypes?.sort((a: any, b: any) => a.precedence_id - b.precedence_id)?.map((fuel: any) => {
                                            return (
                                                <th key={fuel.id} className='text-center p-2'>
                                                    {fuel?.fuel_type_name}
                                                </th>
                                            )
                                        })}
                                    </React.Fragment>
                                ))}
                                <th colSpan={4}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {eumrvData && eumrvData?.length > 0 && eumrvData[0]?.fuel_consumption && eumrvData[0]?.fuel_consumption?.length > 0 ?
                                (eumrvData?.map((item: any, index: number) => {
                                    return (
                                        <tr>
                                            <td className='text-center p-2'>{index + 1}</td>
                                            <td className='text-center p-2'>{item?.start_date?.toString().substring(0, 10)} {item?.start_date?.toString().substring(11, 16)}</td>
                                            <td className='text-center p-2'>{item?.end_date?.toString().substring(0, 10)} {item?.end_date?.toString().substring(11, 16)}</td>
                                            <td className='text-center p-2'>{item?.departure_port}</td>
                                            <td className='text-center p-2'>{item?.arrival_port}</td>
                                            {item?.fuel_consumption?.sort((a: any, b: any) => a.fuel_type_precedence_id - b.fuel_type_precedence_id)?.map((fuel: any) => {
                                                return (
                                                    <td className='text-center p-2'>{fuel?.fuel_consumption?.toFixed(2)}</td>
                                                )
                                            })}
                                            {operation === 'EUMRV' &&
                                                <React.Fragment>
                                                    {item?.co2_emission?.sort((a: any, b: any) => a.fuel_type_precedence_id - b.fuel_type_precedence_id)?.map((fuel: any) => {
                                                        return (
                                                            <td className='text-center p-2'>{fuel?.co2_emission?.toFixed(2)}</td>
                                                        )
                                                    })}
                                                    {item?.ch4_emission?.sort((a: any, b: any) => a.fuel_type_precedence_id - b.fuel_type_precedence_id)?.map((fuel: any) => {
                                                        return (
                                                            <td className='text-center p-2'>{fuel?.ch4_emission?.toFixed(2)}</td>
                                                        )
                                                    })}
                                                    {item?.n2o_emission?.sort((a: any, b: any) => a.fuel_type_precedence_id - b.fuel_type_precedence_id)?.map((fuel: any) => {
                                                        return (
                                                            <td className='text-center p-2'>{fuel?.n2o_emission?.toFixed(2)}</td>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            }
                                            <td className='text-center p-2'>{item?.applicability} %</td>
                                            {operation === 'EUMRV' &&
                                                <td className='text-center p-2'>{item?.emission_applicable_to_eu}</td>
                                            }
                                            {operation === 'EUMRV' ?
                                                <td className='text-center p-2'>{calculateEUA(item)}</td> :
                                                <React.Fragment>
                                                    <td className='text-center p-2'>{item?.fuel_eu_record?.final_GHG_intensity?.toFixed(2)}</td>
                                                    <td className='text-center p-2'>{item?.fuel_eu_record?.Compliance_Balance}</td>
                                                    <td className='text-center p-2'>€ {
                                                        ((Math.abs(item?.fuel_eu_record?.Compliance_Balance) /
                                                            (item?.fuel_eu_record?.final_GHG_intensity * 41000)) * 2400)?.toFixed(0)}</td>
                                                </React.Fragment>
                                            }
                                        </tr>
                                    )
                                })) :
                                <tr>
                                    <td colSpan={operation === 'EUMRV' ? 12 : 10} className='text-left p-2'>No data avaliable</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
        </React.Fragment>
    )
}

export default EUMRVFuelEUTable