import { errorToast, successToast } from 'Components/Toasts';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React from 'react'
import { useSelector } from 'react-redux';
import AsyncSelect from 'react-select/async'
import { Button, Card, Col, Form, Label, Row } from 'reactstrap';
import { customStyle } from 'shared/CommonCSS';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadLubeOilMaster } from 'VesselMaster/vesselMaster.hooks';

interface CreateLubeOilType {
    setCreateNewBool: (value: boolean) => void
}

const CreateLubeOil = ({
    setCreateNewBool
}: CreateLubeOilType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    /** State variables end */

    /** Asign initial values to formik object */
    const getInitialValues = () => {
        let arr: { [key: string]: string | number | boolean | null }[] = [];
        arr.push({
            vessel_lube_oil_name: "",
            status: false,
            vessel: VesselID,
            lube_oil_name: null as number
        })
        return arr;
    }

    /** Vessel lube oil formik object */
    const CreateLubeOilFormik = {
        initialValues: {
            lubeOil: getInitialValues(),
        }
    }
    return (
        <Card className='p-2 mb-0 border-0'>
            <Formik
                onSubmit={(values: { [key: string]: { [key: string]: string | number | boolean | null }[] },
                    _actions: { setSubmitting: (arg: boolean) => void }) => {
                    values?.lubeOil?.forEach((lubeOil: { [key: string]: string | number | boolean | null }) => {
                        lubeOil.lube_oil_name = values?.lubeOil[0]?.lube_oil_name;
                    })
                    apiGlobal.post(`/vessel_lube_oil_list/`, values?.lubeOil).then(() => {
                        successToast("Data saved successfully!");
                        setCreateNewBool(false);
                    }).catch(err => {
                        if (errResponse.includes(err.response.status)) {
                            errorToast("Internal error occured, please contact the admin")
                        }
                    })
                }}
                initialValues={CreateLubeOilFormik.initialValues as
                    { [key: string]: { [key: string]: string | number | boolean | null }[] }}
            >
                {(props) => (
                    <Form onSubmit={props.handleSubmit} noValidate autoComplete='off'>
                        <Row className='mb-2'>
                            <Col sm={8}>
                                <Label className='asteric mb-0' for='vessel_lube_oil'>Select Lube Oil</Label>
                                <Field name='lubeOil.0.lube_oil_name'>
                                    {({ field }: FieldProps) => (
                                        <AsyncSelect
                                            name={field.name}
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={loadLubeOilMaster}
                                            getOptionLabel={(e: { [key: string]: string | number | boolean; }) => e.lube_oil_name as string}
                                            getOptionValue={(e: { [key: string]: string | number | boolean | null }) => e.id as string}
                                            className="select-height"
                                            styles={customStyle}
                                            onChange={(e: { [key: string]: string | number | boolean | null }) =>
                                                props?.setFieldValue(field.name, e?.id)
                                            }
                                        />
                                    )}
                                </Field>
                            </Col>
                        </Row>
                        <FieldArray name='lubeOil'>
                            {({ push, remove }) => (
                                <React.Fragment>
                                    {props?.values?.lubeOil?.map((_lubeOil: { [key: string]: string | number | boolean | null }, index: number) => {
                                        return (
                                            <React.Fragment>
                                                <Row className='mb-2'>
                                                    <Col sm={8}>
                                                        <Label className='asteric mb-0' for='vessel_lube_oil'>Lube Oil name</Label>
                                                        <Field
                                                            type='text'
                                                            className='form-control'
                                                            id='vessel_lube_oil'
                                                            name={`lubeOil.${index}.vessel_lube_oil_name`}
                                                        />
                                                    </Col>
                                                    <Col sm={4}>
                                                        <div className='ele_row1 align-middle'>
                                                            {index ===
                                                                props?.values?.lubeOil?.length -
                                                                1 && (
                                                                    <Button
                                                                        type="button"
                                                                        className="btn"
                                                                        color="primary mt-4"
                                                                    >
                                                                        <i
                                                                            className="dripicons-plus icon_s18 navbar_menu"
                                                                            onClick={() =>
                                                                                push({
                                                                                    vessel_lube_oil_name: "",
                                                                                    status: false,
                                                                                    vessel: VesselID,
                                                                                    lube_oil_name: props?.values?.lubeOil[0]?.lube_oil_name
                                                                                })
                                                                            }
                                                                        />
                                                                    </Button>
                                                                )}
                                                            {props?.values?.lubeOil
                                                                ?.length === 1 ? null : (
                                                                <button
                                                                    type="button"
                                                                    className="btn mt-4"
                                                                >
                                                                    <i
                                                                        className="dripicons-trash icon_s18 cursor-pointer"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            remove(index)
                                                                        }
                                                                    />
                                                                </button>
                                                            )}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        )
                                    })}
                                </React.Fragment>
                            )}
                        </FieldArray>
                        <Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                >
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                >
                                    Reset
                                </Button>
                            </div>
                        </Row>
                        <FormValuesDebug values={[props.values, props.errors, CreateLubeOilFormik.initialValues]} />
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default CreateLubeOil