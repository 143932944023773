import React from 'react';
import { Row, Collapse, Col } from 'reactstrap';
import { CSSModule } from 'reactstrap/types/lib/utils';
import BadgeComponent from './RenderBadge';
import NotificationDetails from './NotificationDetails';
import { NotificationUserStatusConstant } from 'shared/constants';
import { truncateString } from 'GenericForms/Helper';
import { HandleNotificationUserState } from './Notification';

// Define types
interface objType {
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
}

interface EmailListProps {
    notification: objType;
    handleNotificationClick: (notification: objType | null) => void;
    selectedNotification: any;
    handleBackClick: any;
    notificationUserStatusObject: any[];
    notificationUser: any[];
    notificationStateObject: any[];
    activeTab: String;
    count: number;
    setCount: any;
    refetch: () => any;
}


// Main component
const NotificationTableBody: React.FC<EmailListProps> = ({
    notification,
    handleNotificationClick,
    selectedNotification,
    handleBackClick,
    notificationUserStatusObject,
    notificationUser,
    activeTab,
    notificationStateObject,
    count,
    setCount,
    refetch,
}) => {
    return (
        <>
            <tbody key={count}>
                {notification?.results?.map((notificationBody: any) => (
                    <React.Fragment key={notificationBody?.id}>
                        <tr
                            onClick={() => {
                                handleNotificationClick(notificationBody);
                                HandleNotificationUserState(
                                    selectedNotification,
                                    NotificationUserStatusConstant.READ,
                                    notificationUserStatusObject,
                                    notificationUser,
                                    count,
                                    setCount,
                                )
                            }}
                            className={notificationBody?.current_state ? 'text-muted' : 'font-weight-bold'}
                            style={{ cursor: 'pointer' }}
                        >
                            <td className='p-2 text-center align-middle '>
                                <input type="checkbox" />
                            </td>
                            <td className='p-2 text-center align-middle'>
                                <BadgeComponent text={notificationBody?.category_name} badgeColors={notificationBody?.category_color_light} />
                            </td>
                            <td className='p-2 text-center align-middle'>
                                <BadgeComponent text={notificationBody?.priority_name} badgeColors={notificationBody?.priority_color_light} />
                            </td>
                            <td className='p-2 align-middle text-center'>
                                {new Date(notificationBody?.date).toLocaleString()}
                            </td>
                            <td className='p-2 align-middle text-center'>
                                {notificationBody?.vessel_name}
                            </td>
                            <td className='p-2 align-middle'>
                                {truncateString(notificationBody?.title, 40)}
                            </td>
                        </tr>

                        {/* Render NotificationDetails below the table row if it matches the selected notification */}
                        {selectedNotification?.notification_id === notificationBody?.notification_id && (
                            <tr>
                                <td colSpan={6} className="p-0">
                                    <Collapse isOpen={!!selectedNotification}>
                                        <Row>
                                            <Col xs="12">
                                                <NotificationDetails
                                                    notification={selectedNotification}
                                                    handleBackClick={handleBackClick}
                                                    notificationUser={notificationUser}
                                                    notificationStateObject={notificationStateObject}
                                                    notificationUserStatusObject={notificationUserStatusObject}
                                                    selectedNotification={selectedNotification}
                                                    activeTab={activeTab as string}
                                                    count={count}
                                                    setCount={setCount}
                                                    refetch={refetch}
                                                />
                                            </Col>
                                        </Row>
                                    </Collapse>
                                </td>
                            </tr>
                        )}
                    </React.Fragment>
                ))}
            </tbody>
        </>
    );
};

export default NotificationTableBody;