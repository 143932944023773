import { FieldArray, Field, FieldProps, useFormikContext } from 'formik';
import { RootState } from 'index';
import React from 'react'
import { useSelector } from 'react-redux';
import { Input, Label } from 'reactstrap';

interface VesselFuelTableType {
    minIndex: number,
    maxIndex: number
}

const VesselFuelTable = ({
    minIndex,
    maxIndex
}: VesselFuelTableType) => {
    /** State variables start */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const { values, handleBlur, handleChange } = useFormikContext<{ [key: string]: { [key: string]: string | number | boolean | Date | null }[] }>();
    /** State variables end */

    return (
        <div className="table-responsive p-0">
            <table className="table mb-2" key={VesselID}>
                <thead className="table-light">
                    <tr>
                        <th className="p-2 align-middle sr-no-width">
                            #
                        </th>
                        <th className="p-2 align-middle">
                            Fuel Type
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <FieldArray name='vesselFuels'>
                        {() => (
                            <React.Fragment>
                                {values && values?.vesselFuels?.filter(
                                    (_item: { [key: string]: string | number | boolean | Date | null }, indx: number) =>
                                        indx >= minIndex && indx < maxIndex)?.map(
                                            (value: { [key: string]: string | number | boolean | Date | null }, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <Field name={`vesselFuels.${index}.selected`}>
                                                                {({ field }: FieldProps) => (
                                                                    <Input
                                                                        type="checkbox"
                                                                        id={`selected_${index}`}
                                                                        name={field.name}
                                                                        onBlur={handleBlur}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                                                        defaultChecked={value?.selected as boolean}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </td>
                                                        <td>
                                                            <Label className='mb-0' htmlFor={`selected_${index}`}>
                                                                {value?.fuel_type_name as string}
                                                            </Label>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                }
                            </React.Fragment>
                        )}
                    </FieldArray>
                </tbody>
            </table>
        </div>
    )
}

export default VesselFuelTable