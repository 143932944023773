import React, { useState } from 'react'
import { Card, CardBody, Row, TabContent, TabPane, } from 'reactstrap'
import FirstVesselReport from './FirstVesselReport'
import FirstBunkering from './FirstBunkering';
import CreateVoyage from 'VoyageInformation/CreateVoyage';
import FirstLoBunkering from './FirstLoBunkering';
import FirstOtherROB from './FirstOtherROB';
import FirstLNGTank from './FirstLNGTank';

const FirstReport = () => {
    /** State variables */
    const [activeTab, setactiveTab] = useState(1);
    const [voyageId, setVoyageId] = useState<number>(0);
    const [reportId, setReportId] = useState<number>(0);
    const [report, setReport] = useState({});
    /** State variables end */

    /** Change tabs */
    function toggleTab(tab: number) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 10) {
                setactiveTab(tab);
            }
        }
    }

    return (
        <Row>
            <Card className='mb-0 p-0 border-0'>
                <CardBody className='p-0'>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                        <TabContent
                            activeTab={activeTab}
                        >
                            <TabPane tabId={1} className="tabHeight">
                                <CreateVoyage
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    setVoyageId={setVoyageId}
                                />
                            </TabPane>
                            <TabPane tabId={2} className="tabHeight">
                                <FirstVesselReport
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    voyageId={voyageId}
                                    setReportId={setReportId}
                                    setReport={setReport}
                                />
                            </TabPane>
                            <TabPane tabId={3} className="tabHeight">
                                <FirstBunkering
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    voyageId={voyageId}
                                    reportId={reportId}
                                    report={report}
                                />
                            </TabPane>
                            <TabPane tabId={4} className="tabHeight">
                                <FirstLoBunkering
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    voyageId={voyageId}
                                    reportId={reportId}
                                />
                            </TabPane>
                            <TabPane tabId={5} className="tabHeight">
                                <FirstOtherROB
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    voyageId={voyageId}
                                    reportId={reportId}
                                />
                            </TabPane>
                            <TabPane tabId={6} className="tabHeight">
                                <FirstLNGTank
                                    toggleTab={toggleTab}
                                    activeTab={activeTab}
                                    voyageId={voyageId}
                                    reportId={reportId}
                                />
                            </TabPane>
                        </TabContent>
                    </div>
                </CardBody>
            </Card>
        </Row>
    )
}

export default FirstReport