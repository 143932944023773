import React, { useState } from 'react';
import { Button, Row, Col, Label } from 'reactstrap';
import Dropzone, { Accept } from 'react-dropzone';
import { maxUploadSizeFile, RemoveFile } from 'shared/constants';
import { MediaBaseURL } from 'global/api.global';
import { CSSModule } from 'reactstrap/types/lib/utils';
import FilePreviewComponent from './FilePreviewComponent';

interface parameter {
    [key: string]: string | Object;
}
interface ReportingFileUploadInterface {
    setFile: (value: parameter[]) => void;
    file: any[];
    deleteURL: string;
    invalidateQuery: string;
    deleteID: number;
    fileUploadStatus: boolean;
    refreshKey: number;
    fileURL?: string;
    setRefreshKey: any;
    title: string;
    DeleteFileObj: any;
    sm?: number;
    index?: number;
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
}

/**
 * Component to upload file, replace file or to show selected file
 * @param file 
 * @returns 
 */
const ReportingFileUpload = ({
    setFile,
    file,
    deleteURL,
    invalidateQuery,
    deleteID,
    fileUploadStatus,
    refreshKey,
    title,
    DeleteFileObj,
    fileURL,
    setRefreshKey,
    index,
    sm = 2,
}: ReportingFileUploadInterface) => {
    const [fileErrorMsg, setFileErrorMsg] = useState<string>()
    if (index === undefined) {
        return
    }

    /**
     * to accept uploaded files from dropzone
     * @param acceptedFiles files in binary
     */
    const handleAcceptedFiles = (acceptedFiles: any[]) => {
        const uploadedFile = acceptedFiles[0];
        if (uploadedFile && index !== undefined) {
            const errorMsg = validateFile(uploadedFile)
            if (errorMsg) {
                setFileErrorMsg(errorMsg);  // Set error message if validation fails
                removeIndexFile(index)
            } else {
                let updateFile = [...file]
                updateFile[index] = {
                    name: uploadedFile.name,
                    preview: URL.createObjectURL(uploadedFile),
                    file: uploadedFile,
                };
                setFile(updateFile);
                setFileErrorMsg(null)
            }
        }
    }

    /**
     * Validate the uploaded file
     * @param file The file to validate
     * @returns Error message if validation fails, otherwise null
     */
    const validateFile = (file: any) => {
        let errorMsg: string | null = null;
        // Validate file size
        if (file.size > maxUploadSizeFile) {
            /** Convert to MB and give validate msg*/
            errorMsg = `File should not be greater than ${maxUploadSizeFile / (1024 * 1024)} MB`;
        }
        return errorMsg
    }

    /**
     * File Types accepted by Dropzone
     */
    const acceptedFileTypes: Accept = {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
    };

    /**
     * to handle file rejection (when user selects an unsupported file type)
     * @param rejectedFiles rejected files
     */
    const handleRejectedFiles = () => {
        setFileErrorMsg('Only .pdf, .jpg, .jpeg, .png files are allowed!');
        removeIndexFile(index)
    };

    /**
     * Function to remove file from specific index
     * @param index 
     */
    const removeIndexFile = (index: number) => {
        let updateFile = [...file]
        updateFile[index] = {};
        setFile(updateFile);
    }

    return (
        <Row className='mt-3'>
            <Col sm={sm ?? 2}>
                <Label className="mb-0">{title && title}</Label>
                <Dropzone
                    onDropAccepted={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
                    onDropRejected={() => handleRejectedFiles()}
                    multiple={false}
                    accept={acceptedFileTypes}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="needsclick">
                            <input {...getInputProps()} />
                            <Button
                                type="button"
                                color="primary"
                                className="btn waves-effect btn-label waves-light"
                            >
                                <i className="mdi mdi-upload label-icon" />
                                {fileUploadStatus ? 'Replace File' : 'Upload File'}
                            </Button>
                            <p>
                                {/* Display error message */}
                                {fileErrorMsg && <p className='error'>{fileErrorMsg}</p>}
                            </p>
                        </div>
                    )}
                </Dropzone>
            </Col>
            {/* See preview of selected file */}
            {file && file[index]?.preview &&
                (<FilePreviewComponent
                    fileName={file[index]?.file?.name}
                    url={file[index]?.preview}
                    title={'Selected File'}
                    setFile={setFile}
                    size={file[index]?.file?.size}
                    file={file}
                    fileType={RemoveFile.SELECTED}
                    index={index}
                    key={index}
                    sm={sm ?? 2}
                />)
            }
            {/* See preview of uploaded file */}
            {fileUploadStatus && (
                <FilePreviewComponent
                    fileName={fileURL?.split('/')?.pop()}
                    url={`${MediaBaseURL}/${fileURL}`}
                    title={'Uploaded File'}
                    invalidateQuery={invalidateQuery}
                    deleteURL={deleteURL}
                    deleteID={deleteID}
                    DeleteFileObj={DeleteFileObj}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                    fileType={RemoveFile.UPLOADED}
                    sm={sm ?? 2}
                />
            )}
        </Row>
    );
};

export default ReportingFileUpload;
