import LNGCargoQuality from 'Components/LNGCargoQuality';
import PopOver from 'Components/PopOver';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { Label, Row, Col, Button, Modal } from 'reactstrap';
import { AdjustmentApprovalStatus, Roles } from 'shared/constants';
import { hasRole } from 'utils/auth/authUtils';
import N2AdjustmentTableHeadComponent from './N2AdjustmentTableHeadComponent';
import AdjustmentPaginationComponent from 'Adjustments/AdjustmentPaginationComponent';

const DisapprovedN2Adjustment = ({
    active
}: any) => {
    /** State variables */
    const [tableDetails, setTableDetails] = useState({
        size: 5,
        index: 0,
        n2Adjustment: []
    });
    const [count, setCount] = useState(0);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [adjustmentPopup, setAdjustmentPopup] = useState(false);
    const [n2adjustment, setN2Adjustment] = useState<any>({});
    const formSubmitRef = useRef<any>();
    const [revokeBool, setRevokeBool] = useState<any>({});
    /** State variables end */

    /** useEffect start */
    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselID, active])
    /** useEffect end */

    /** Load N2 corrections of selected vessel that are disapproved */
    const loadData = async () => {
        await apiGlobal.get(`n2_adjustment/get_n2_adjustment_by_vessel/?vessel_id=${VesselID}`).then(response => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                n2Adjustment: response.data.filter((item: any) => item.approval_status === AdjustmentApprovalStatus.DISAPPROVED)
            }));
            setCount(count => count + 1);
        }).catch(() => {
            setTableDetails(prevDetails => ({
                ...prevDetails,
                n2Adjustment: []
            }));
        })
    };

    /** Open-close modal */
    function tog_backdrop() {
        setAdjustmentPopup(!adjustmentPopup);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    /** Function to revoke N2 Correction */
    const revokeN2Correction = () => {
        n2adjustment.approval_status = AdjustmentApprovalStatus.PENDING;
        delete n2adjustment.ctms_start_file
        delete n2adjustment.ctms_end_file
        apiGlobal.put(`/n2_adjustment/${n2adjustment?.id}/`, n2adjustment).then(res => {
            if (res.status === 200) {
                successToast("Data saved successfully!");
                loadData();
                setRevokeBool(false);
            }
        }).catch(err => {
            if (errResponse.includes(err?.response?.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }
    return (
        <>
            {tableDetails?.n2Adjustment?.length === 0 ?
                <div className='text-center'>
                    <Label className='mb-3'>No N<sub>2</sub> corrections are cancelled for this vessel</Label>
                </div> :
                <>
                    <Row className='mb-0'>
                        <Col lg={7}>
                            <div className="table-responsive">
                                <table className="table mb-0" key={count}>
                                    <N2AdjustmentTableHeadComponent />
                                    <tbody>
                                        {tableDetails && tableDetails?.n2Adjustment?.slice(tableDetails?.index * tableDetails?.size,
                                            tableDetails?.index * tableDetails?.size + tableDetails?.size)?.map((n2: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td className='nopadtop align-middle p-2 text-center'>{index + 1}</td>
                                                        <td className='nopadtop align-middle p-2'>
                                                            <>
                                                                <b>{n2?.start_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {n2?.start_date_time?.toString().substring(11, 16)}</b> to
                                                                <b> {n2?.end_date_time?.toString().substring(0, 10)}&nbsp;
                                                                    {n2?.end_date_time?.toString().substring(11, 16)}</b>
                                                            </>
                                                        </td>
                                                        {!(hasRole(Roles.CHIEFENGINEER)) &&
                                                            <td className='align-middle text-center p-2'>
                                                                <Button
                                                                    type='button'
                                                                    className='btn btn-primary waves-effect btn-label waves-light'
                                                                    color='primary'
                                                                    id={`disapprove_btn${n2?.id}`}
                                                                    onClick={() => {
                                                                        setN2Adjustment(n2);
                                                                        setRevokeBool(true);
                                                                    }}
                                                                >
                                                                    <i className="bx bx-left-arrow-circle label-icon"></i>
                                                                    Mark as pending
                                                                </Button>
                                                                {revokeBool === true &&
                                                                    <PopOver
                                                                        target={`disapprove_btn${n2adjustment?.id}`}
                                                                        handleClick={revokeN2Correction}
                                                                        message={'Are you sure you want to revoke?'}
                                                                        state={revokeBool}
                                                                        setState={setRevokeBool}
                                                                    />
                                                                }
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        {adjustmentPopup === true &&
                                            <Modal
                                                size='xl'
                                                isOpen={adjustmentPopup}
                                                toggle={() => {
                                                    tog_backdrop();
                                                }}
                                                backdrop={"static"}
                                                id="staticBackdrop"
                                            >
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="staticBackdropLabel">
                                                        LNG Cargo Quality Details
                                                    </h5>
                                                    <button
                                                        onClick={() => {
                                                            setAdjustmentPopup(false);
                                                        }}
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <Row>
                                                        <Col lg={6}>
                                                            <LNGCargoQuality
                                                                VesselID={VesselID}
                                                                reportId={n2adjustment?.start_report}
                                                                cargoOperation={'loading'}
                                                                cardHeader={'LNG Cargo Loading Quality Details'}
                                                                form="n2Adjustment"
                                                                adjustmentDetails={n2adjustment}
                                                                ref={formSubmitRef}
                                                                setState={setAdjustmentPopup}
                                                            />
                                                        </Col>
                                                        <Col lg={6}>
                                                            <LNGCargoQuality
                                                                VesselID={VesselID}
                                                                reportId={n2adjustment?.end_report}
                                                                cargoOperation={'discharging'}
                                                                cardHeader={'LNG Cargo Discharging Quality Details'}
                                                                form="n2Adjustment"
                                                                adjustmentDetails={n2adjustment}
                                                                ref={formSubmitRef}
                                                                setState={setAdjustmentPopup}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={{ size: 2, offset: 10 }}>
                                                            <Button type='button' className='btn justify_right' color='primary' onClick={() => {
                                                                if (formSubmitRef.current) {
                                                                    formSubmitRef.current.submitForm();
                                                                }
                                                            }}>Approve</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Modal>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    {tableDetails?.n2Adjustment?.length > tableDetails?.size &&
                        <AdjustmentPaginationComponent
                            count={count}
                            setCount={setCount}
                            tableDetails={tableDetails}
                            setTableDetails={setTableDetails}
                        />
                    }
                </>
            }
        </>
    )
}

export default DisapprovedN2Adjustment