import React from 'react'
import { Formik } from 'formik'
import { Card, CardHeader, Row, Form, Button, CardFooter } from 'reactstrap'
import { RootState } from 'index'
import { useSelector } from 'react-redux'
import env from 'environment/env'
import FirstReportForm, { fieldObjectInterface } from './FirstReportForm'
import FormValuesDebug from 'utils/debugTools/FormValuesDebug'

interface FirstOtherRobType {
    toggleTab: (activeTab: number) => void,
    activeTab: number,
    voyageId: number,
    reportId: number
}

const FirstOtherROB = ({
    toggleTab,
    activeTab,
    voyageId,
    reportId
}: FirstOtherRobType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    const fieldArray: fieldObjectInterface[] = [
        {
            label: "Fresh water ROB",
            field: "amount_of_fresh_water_produced",
            input: "text",
            unit: "mt"
        },
        {
            label: "Total cargo onboard",
            field: "total_amount_of_cargo_onboard",
            input: "text",
            unit: "mt"
        },
        {
            label: "Vessel",
            field: "vessel",
            input: "text",
            value: VesselID
        },
        {
            label: "Voyage Information",
            field: "voyage_information",
            input: "text",
            value: voyageId
        },
        {
            label: "Vessel Reporting Information",
            field: "vessel_reporting_information",
            input: "text",
            value: reportId
        }
    ]

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        let arr: { [key: string]: string | number | boolean | null }[] = [];
        let obj: { [key: string]: string | number | boolean | null } = {};
        fieldArray.forEach((fieldObj: fieldObjectInterface) => {
            obj[fieldObj?.field] = fieldObj?.value ?? null;
        })
        arr.push(obj);
        return arr;
    }

    /** First Fresh Water ROB's formik object */
    const FirstFreshWaterRobFormik: {
        initialValues: {
            freshWaterRob: { [key: string]: string | number | boolean | null }[];
        };
    } = {
        initialValues: {
            freshWaterRob: getInitialValues(),
        },
    };

    return (
        <Card className="mb-0 p-0">
            <React.Fragment>
                <CardHeader className="p-2 border-0">
                    <h5 className="mb-0">Other ROB</h5>
                </CardHeader>
                <Formik
                    onSubmit={(_values: any, _actions: any) => {
                    }}
                    initialValues={FirstFreshWaterRobFormik.initialValues}
                >
                    {(props) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <FirstReportForm
                                fieldArray={fieldArray}
                                isArray={true}
                                arrayName='freshWaterRob'
                                initialValues={FirstFreshWaterRobFormik.initialValues.freshWaterRob}
                            />
                            <CardFooter className="p-2 py-3">
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleTab(activeTab + 1);
                                                }
                                            }}
                                        >
                                            Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm"
                                            onClick={() => {
                                                toggleTab(activeTab - 1);
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[props?.values, props?.errors, FirstFreshWaterRobFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </Card>
    )
}

export default FirstOtherROB