import apiGlobal from "global/api.global";
import { Generic } from "../Store/Generic/GenericSlice";

export const updateObjectById = (
  array: Generic[], // Specify the type of the array elements
  idToUpdate: number,
  updatedData: any // You might want to specify a type for updatedData as well
) => {
  const index = array.findIndex((item) => item.id === idToUpdate);
  if (index !== -1) {
    const newArray = [...array];
    newArray[index] = { ...newArray[index], ...updatedData };
    return newArray;
  }
  return array;
};

export const getRecordFromArray = (
  array: Generic[], // Specify the type of the array elements
  idToUpdate: number
) => {
  let index = array.findIndex((item) => item.id === idToUpdate);
  if (index !== -1) {
    return array[index];
  }
};

export const replaceObject = (
  array: Generic[],
  condition: (obj: Generic) => boolean,
  newObject: Generic
): Generic[] => {
  return array.map((obj) => (condition(obj) ? newObject : obj));
};

/**
 * Removes extra fields of the objects from array of objects
 * @param dataArray
 * @param propertiesToRemove
 * @returns
 */
export const removeProperties = (
  dataArray: any[],
  propertiesToRemove: string[]
) => {
  return dataArray.map((obj) => {
    const newObj = { ...obj };
    propertiesToRemove.forEach((property) => delete newObj[property]);
    return newObj;
  });
};

/**
 * Function will take two args which will substract subtractArray from dataArray
 * @param dataArray
 * @param subtractArray
 * @returns
 */
export const arraySubtract = (dataArray: any[], subtractArray: any[]) => {
  const subtractedArray = dataArray.filter((totalEvent: any) => {
    return !subtractArray.some(
      (onGoingEvent: any) =>
        onGoingEvent.cii_adjustment === totalEvent.cii_adjustment
    );
  });
  return subtractedArray;
};

/**
 * Retruns formated string if present else sends todays date
 * @param dateTime
 * @returns
 */
export const dataTimeFormat = (
  dateTime: string,
  skipTime = false,
  returnEmpty = false
) => {
  let position = skipTime ? 10 : 16;
  try {
    if (dateTime) {
      return new Date(dateTime).toISOString().slice(0, position);
    }
    if (returnEmpty) {
      return "";
    }
    return new Date().toISOString().slice(0, position);
  } catch (error) {
    console.error(error);
    return new Date().toISOString().slice(0, position);
  }
};

/**
 * function for formating the bytes
 * @param bytes
 * @param decimals
 * @returns
 */
export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

/**
 * Sort an object based on keys
 * @param inputObject
 * @returns
 */
export const sortArrayObject = (inputObject: any) => {
  if (inputObject) {
    const keys = Object.keys(inputObject);
    keys.sort();
    const sortedObject: any = {};
    keys.forEach((key) => {
      sortedObject[key] = inputObject[key];
    });
    return sortedObject;
  } else {
    return null;
  }
};

/**
 * Return an array with unique ids from first array
 * @param original array
 * @returns array
 */
export const filterUniqueByFields = (array: any[], fields: string[]) => {
  return array.reduce((acc, current) => {
    if (
      !acc.some((obj: any) =>
        fields.every((field) => obj[field] === current[field])
      )
    ) {
      acc.push(current);
    }
    return acc;
  }, []);
};

/** Return CII Category range according to vessel type & dead weight
 * @param dead weight
 * @returns array
 */
export const getCIICategoryRange = (type: string, dwt: number) => {
  if (type === "Bulk Carrier") {
    return {
      a: 0.86,
      b: 0.94,
      c: 1.06,
      d: 1.18,
      e: 1000,
    };
  } else if (type === "Gas Carrier") {
    return {
      a: dwt >= 65000 ? 0.81 : 0.85,
      b: dwt >= 65000 ? 0.91 : 0.95,
      c: dwt >= 65000 ? 1.12 : 1.06,
      d: dwt >= 65000 ? 1.44 : 1.25,
      e: 1000,
    };
  } else if (type === "Tanker") {
    return {
      a: 0.82,
      b: 0.93,
      c: 1.08,
      d: 1.28,
      e: 1000,
    };
  } else if (type === "Container") {
    return {
      a: 0.83,
      b: 0.94,
      c: 1.07,
      d: 1.19,
      e: 1000,
    };
  } else if (type === "General Cargo Ship") {
    return {
      a: 0.83,
      b: 0.94,
      c: 1.06,
      d: 1.19,
      e: 1000,
    };
  } else if (type === "LNG Carrier") {
    return {
      type: "LNG Carrier",
      a: dwt >= 100000 ? 0.89 : 0.78,
      b: dwt >= 100000 ? 0.98 : 0.92,
      c: dwt >= 100000 ? 1.06 : 1.1,
      d: dwt >= 100000 ? 1.13 : 1.37,
      e: 1000,
    };
  } else if (type === "RoRo Cargo VC") {
    return {
      a: 0.86,
      b: 0.94,
      c: 1.06,
      d: 1.16,
      e: 1000,
    };
  } else if (type === "RoRo Cargo Ship") {
    return {
      a: 0.66,
      b: 0.9,
      c: 1.11,
      d: 1.41,
      e: 1000,
    };
  }
};

/**
 * Return CII category
 * @param vessel type & CII ratio
 * @returns string
 */
export const CIICategory = (type: any, ratio: any, dwt: number) => {
  if (type === "Bulk Carrier") {
    if (ratio >= 0 && ratio <= 0.86) {
      return "A";
    } else if (ratio >= 0.87 && ratio <= 0.94) {
      return "B";
    } else if (ratio >= 0.95 && ratio <= 1.06) {
      return "C";
    } else if (ratio >= 1.07 && ratio <= 1.18) {
      return "D";
    } else if (ratio >= 1.19) {
      return "E";
    }
  } else if (type === "Gas Carrier") {
    if (dwt >= 65000) {
      if (ratio >= 0 && ratio <= 0.81) {
        return "A";
      } else if (ratio >= 0.82 && ratio <= 0.91) {
        return "B";
      } else if (ratio >= 0.92 && ratio <= 1.12) {
        return "C";
      } else if (ratio >= 1.13 && ratio <= 1.44) {
        return "D";
      } else if (ratio >= 1.45) {
        return "E";
      }
    } else if (dwt < 65000) {
      if (ratio >= 0 && ratio <= 0.85) {
        return "A";
      } else if (ratio >= 0.86 && ratio <= 0.95) {
        return "B";
      } else if (ratio >= 0.96 && ratio <= 1.06) {
        return "C";
      } else if (ratio >= 1.07 && ratio <= 1.25) {
        return "D";
      } else if (ratio >= 1.26) {
        return "E";
      }
    }
  } else if (type === "Tanker") {
    if (ratio >= 0 && ratio <= 0.82) {
      return "A";
    } else if (ratio >= 0.83 && ratio <= 0.93) {
      return "B";
    } else if (ratio >= 0.94 && ratio <= 1.08) {
      return "C";
    } else if (ratio >= 1.09 && ratio <= 1.28) {
      return "D";
    } else if (ratio >= 1.29) {
      return "E";
    }
  } else if (type === "Container") {
    if (ratio >= 0 && ratio <= 0.83) {
      return "A";
    } else if (ratio >= 0.84 && ratio <= 0.94) {
      return "B";
    } else if (ratio >= 0.95 && ratio <= 1.07) {
      return "C";
    } else if (ratio >= 1.08 && ratio <= 1.19) {
      return "D";
    } else if (ratio >= 1.2) {
      return "E";
    }
  } else if (type === "General Cargo Ship") {
    if (ratio >= 0 && ratio <= 0.83) {
      return "A";
    } else if (ratio >= 0.84 && ratio <= 0.94) {
      return "B";
    } else if (ratio >= 0.95 && ratio <= 1.06) {
      return "C";
    } else if (ratio >= 1.07 && ratio <= 1.19) {
      return "D";
    } else if (ratio >= 1.2) {
      return "E";
    }
  } else if (type === "Refrigerated Cargo Carrier") {
    if (ratio >= 0 && ratio <= 0.78) {
      return "A";
    } else if (ratio >= 0.79 && ratio <= 0.91) {
      return "B";
    } else if (ratio >= 0.92 && ratio <= 1.07) {
      return "C";
    } else if (ratio >= 1.08 && ratio <= 1.2) {
      return "D";
    } else if (ratio >= 1.21) {
      return "E";
    }
  } else if (type === "Combination Carrier") {
    if (ratio >= 0 && ratio <= 0.78) {
      return "A";
    } else if (ratio >= 0.79 && ratio <= 0.96) {
      return "B";
    } else if (ratio >= 0.97 && ratio <= 1.06) {
      return "C";
    } else if (ratio >= 1.07 && ratio <= 1.14) {
      return "D";
    } else if (ratio >= 1.15) {
      return "E";
    }
  } else if (type === "LNG Carrier") {
    if (dwt >= 100000) {
      if (ratio >= 0 && ratio <= 0.89) {
        return "A";
      } else if (ratio >= 0.9 && ratio <= 0.98) {
        return "B";
      } else if (ratio >= 0.99 && ratio <= 1.06) {
        return "C";
      } else if (ratio >= 1.07 && ratio <= 1.13) {
        return "D";
      } else if (ratio >= 1.14) {
        return "E";
      }
    } else if (dwt < 100000) {
      if (ratio >= 0 && ratio <= 0.78) {
        return "A";
      } else if (ratio >= 0.79 && ratio <= 0.92) {
        return "B";
      } else if (ratio >= 0.93 && ratio <= 1.1) {
        return "C";
      } else if (ratio >= 1.11 && ratio <= 1.37) {
        return "D";
      } else if (ratio >= 1.38) {
        return "E";
      }
    }
  } else if (type === "Ro-Ro Cargo VC") {
    if (ratio >= 0 && ratio <= 0.86) {
      return "A";
    } else if (ratio >= 0.87 && ratio <= 0.94) {
      return "B";
    } else if (ratio >= 0.95 && ratio <= 1.06) {
      return "C";
    } else if (ratio >= 1.07 && ratio <= 1.16) {
      return "D";
    } else if (ratio >= 1.17) {
      return "E";
    }
  } else if (type === "Ro-Ro Cargo Ship") {
    if (ratio >= 0 && ratio <= 0.66) {
      return "A";
    } else if (ratio >= 0.67 && ratio <= 0.9) {
      return "B";
    } else if (ratio >= 0.91 && ratio <= 1.11) {
      return "C";
    } else if (ratio >= 1.12 && ratio <= 1.41) {
      return "D";
    } else if (ratio >= 1.42) {
      return "E";
    }
  } else if (type === "Ro-Ro Passenger Ship") {
    if (ratio >= 0 && ratio <= 0.72) {
      return "A";
    } else if (ratio >= 0.73 && ratio <= 0.9) {
      return "B";
    } else if (ratio >= 0.91 && ratio <= 1.12) {
      return "C";
    } else if (ratio >= 1.13 && ratio <= 1.41) {
      return "D";
    } else if (ratio >= 1.42) {
      return "E";
    }
  } else if (type === "Cruise Passenger Ship") {
    if (ratio >= 0 && ratio <= 0.87) {
      return "A";
    } else if (ratio >= 0.88 && ratio <= 0.95) {
      return "B";
    } else if (ratio >= 0.96 && ratio <= 1.06) {
      return "C";
    } else if (ratio >= 1.07 && ratio <= 1.16) {
      return "D";
    } else if (ratio >= 1.17) {
      return "E";
    }
  }
};

/** HTTP responses to generate errors */
export let errResponse: number[] = [400, 401, 403, 404, 500];

/** Calculate local time based on UTC & time difference */
export const calculateLocalTime = (reporting_time_utc: any, time_diff: any) => {
  if (time_diff !== null) {
    const localDate = new Date(reporting_time_utc);
    const hours = parseInt(time_diff?.toString().substring(0, 3));
    let minutes = parseInt(time_diff?.toString().substring(4, 6));
    localDate.setHours(localDate.getHours() + hours);
    if (time_diff.toString().substring(0, 1) === "-") {
      minutes = minutes * -1;
    }
    localDate.setMinutes(localDate.getMinutes() + minutes);
    if (isNaN(localDate.getTime())) {
      return null;
    }
    return `${localDate.getDate()}-${localDate.getMonth() + 1
      }-${localDate.getFullYear()} ${localDate.getHours().toString()}:${localDate
        .getMinutes()
        .toString()}`;
  }
};

export const searchPorts = async (port: any) => {
  if (port.length >= 3) {
    try {
      const response = await apiGlobal.get(
        `port_master/filter_by_port_name/?port_name=${port}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const searchCountries = async (country: any) => {
  if (country.length >= 3) {
    try {
      const response = await apiGlobal.get(
        `country_master/filter_by_country_name/?country_name=${country}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  }
};

export const calculateTimeDurationBetwnReports = (
  lastRecordDate: any,
  currRecordDate: any
) => {
  const dt1 = new Date(lastRecordDate);
  const dt2 = new Date(currRecordDate);
  return Math.floor(
    (Date.UTC(
      dt2.getFullYear(),
      dt2.getUTCMonth(),
      dt2.getUTCDate(),
      dt2.getUTCHours(),
      dt2.getUTCMinutes(),
      dt2.getUTCSeconds()
    ) -
      Date.UTC(
        dt1.getFullYear(),
        dt1.getUTCMonth(),
        dt1.getUTCDate(),
        dt1.getUTCHours(),
        dt1.getUTCMinutes(),
        dt1.getUTCSeconds()
      )) /
    (1000 * 60)
  );
};

/** Enable/disable fields based on machine-fuel applicability */
export const machineryEnabled = (
  fuelSettings: any[],
  VesselID: number,
  machine: number,
  fuel: number
) => {
  if (
    fuelSettings.filter(
      (item: any) =>
        item.vessel_machinery_fc_group === machine &&
        item.fuel_name === fuel &&
        item.vessel === VesselID
    )[0]?.selected === true
  ) {
    return false;
  }
  return true;
};

export function truncateString(str: string, maxLength: number) {
  if (str.length > maxLength) {
    return str.slice(0, maxLength - 3) + "...";
  }
  return str;
}

export function countInboxMessages(number: number) {
  if (number > 99) {
    return "99+";
  }
  return number;
}

/** Converts gram into metic tonne */
export const gmToMt = (value: number) => {
  return value / 1000000;
};

/** Converts metic tonne into gram */
export const MtToGm = (value: number) => {
  return value * 1000000;
};

/** Calculate EUA */
export const calculateEUA = (value: any) => {
  let emission = 0;
  if (value && value?.co2_emission && value?.co2_emission?.length > 0) {
    value?.co2_emission?.forEach((item: any) => {
      emission += item.co2_emission;
    });
    return (((emission * value.emission_applicable_to_eu) / 100) * 0.4)?.toFixed(
      2
    );
  } else {
    return "0";
  }
};
/**
 *  Check if the primary key is valid
 * @param primaryKey 
 * @returns 
 */
export const checkInvalidPrimaryKey = (...primaryKeys: number[]): boolean => {
  if(primaryKeys.length === 0){
    return false;
  }
  return primaryKeys.every((key) => key !== -1 && !!key);
};