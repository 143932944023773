import apiGlobal from '../../../global/api.global';
import React, { useEffect, useState } from 'react'
import { VesselMachineryList } from '../VesselConfiguration.model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';

const EnergyConsumer = () => {
    const [vesselMachineries, setVesselMachineries] = useState<Array<VesselMachineryList>>([]);
    const { VesselID } = useSelector((state: RootState) => state.Reporting);


    useEffect(() => {
        const loadOptions = async () => {
            try {
                const response = await apiGlobal.get(`vessel_machinery_ec_group/get_vessel_machinery_ec_group_by_vessel/?vessel_id=${VesselID}`);
                setVesselMachineries(response.data);
                return response.data;
            } catch (error) {
                console.error(error);
            }
        }; loadOptions();
    }, [VesselID])

    return (
        <>
            <div className="table-responsive mb-3">
                <table className="table">
                    <thead className="table-light">
                        <tr>
                            <th className='p-2 align-middle sr-no-width'>#</th>
                            <th className='p-2 align-middle'>EC Group Name</th>
                            <th className='p-2 align-middle'>Machinery List</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vesselMachineries.length > 0 ?
                            vesselMachineries.map((vesselMachinery: any, index: number) => {
                                return (
                                    <tr key={vesselMachinery.id}>
                                        <td className='p-2 align-middle text-center'>{index + 1}</td>
                                        <td className='p-2 align-middle'>{vesselMachinery.vessel_machinery_ec_group_name}</td>
                                        <td className="p-0 align-middle">
                                            <table className="table m-0">
                                                <tbody>
                                                    {vesselMachinery?.vessel_machinery_ec_names?.map((item: string) => (
                                                        <tr key={item}>
                                                            <td className="">{item}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td colSpan={4} className="text-center">No machineries configured for this vessel</td>
                            </tr>}
                    </tbody>
                </table>
                <p> - Add to the list, machinery which are energy consumers .</p>
            </div>
        </>
    )
}
export default EnergyConsumer