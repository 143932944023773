import React from 'react';
import { Modal, Row, Col, Button } from 'reactstrap';
import ReportingFileUpload from './ReportingFileUpload';
import { CTMSType } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';

const ReportingFileUploadModal = (props: any) => {
    /** State varibles */
    const { state, setState, array, setArray, modalId, title, size, save, n2, CTMSFileType, setRefreshKey, refreshKey } = props;
    /** State varibles end */
    let DeleteFileObj: any
    let fileUploadStatus: boolean
    let file_path: string
    if (CTMSFileType === CTMSType.LOADING) {
        DeleteFileObj = {
            ctms_start_file: null,
            start_file_path: null
        }

        if (n2.ctms_start_file) {
            fileUploadStatus = true
        } else {
            fileUploadStatus = false
        }
        file_path = n2.end_file_path
    } else if (CTMSFileType === CTMSType.DISCHARGING) {
        DeleteFileObj = {
            ctms_end_file: null,
            end_file_path: null
        }
        if (n2.ctms_end_file) {
            fileUploadStatus = true
        } else {
            fileUploadStatus = false
        }
        file_path = n2.start_file_path
    }
    /** Open close modal */
    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    function tog_backdrop() {
        setState(!state);
        removeBodyCss();
    }

    return (
        <Modal
            isOpen={state}
            toggle={() => {
                tog_backdrop();
            }}
            backdrop={"static"}
            id={modalId}
            size={size}
        >
            <div className="modal-header p-2">
                <h5 className="modal-title" id="staticBackdropLabel">
                    {title}
                </h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => {
                        setState(false);
                    }}
                    aria-label="Close"
                ></button>
            </div>
            <div className="modal-body p-2 form-floating">
                <ReportingFileUpload
                    setFile={setArray}
                    file={array}
                    fileUploadStatus={fileUploadStatus}
                    fileURL={file_path}
                    deleteURL={`/n2_adjustment/`}
                    invalidateQuery={queryKeyes.vessel.N2AdjustmentObjectByVessel.key}
                    deleteID={n2.id}
                    refreshKey={refreshKey}
                    setRefreshKey={setRefreshKey}
                    title={''}
                    DeleteFileObj={DeleteFileObj}
                    index={0}
                    sm={5}
                />
                <Row className='mt-2'>
                    <Col>
                        <Button className='btn justify_right' color='primary' onClick={save} >Save</Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    )
}

export default ReportingFileUploadModal