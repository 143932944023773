import React from 'react'
import { Field, FieldArrayRenderProps, FieldProps, useFormikContext } from 'formik'
import AsyncSelect from 'react-select/async'
import { Row, Col, Label, Input } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import { fieldObjectInterface } from './FirstReportForm'

interface FirstReportFormRowType {
    fieldArray: fieldObjectInterface[],
    isArray: boolean
    index?: number,
    arrayName?: string,
    push?: FieldArrayRenderProps['push'],
    remove?: FieldArrayRenderProps['remove'];
    initialValues?: { [key: string]: string | number | Date | boolean | null }[];
}

const FirstReportFormRow = ({
    fieldArray,
    isArray,
    index,
    arrayName,
    push,
    remove,
    initialValues
}: FirstReportFormRowType) => {
    const { values }: { values: { [key: string]: { [key: string]: string | number | Date | boolean }[] } } = useFormikContext();
    return (
        <React.Fragment>
            <Row>
                {fieldArray?.filter((item: fieldObjectInterface) =>
                    item.field !== 'vessel' && item.field !== 'voyage_information' && item.field !== 'vessel_reporting_information')
                    ?.map((value: fieldObjectInterface, indx: number) => {
                        return (
                            <Col sm={3} className='mb-2' key={indx}>
                                {
                                    ((value?.input === "text" && !value?.unit) || value?.input === "datetime-local") ?
                                        <React.Fragment>
                                            <Label className='mb-0 asteric'
                                                htmlFor={value?.field}>{value?.label}</Label>
                                            <Field
                                                type={value?.input}
                                                name={isArray ? `${arrayName}.${index}.${value?.field}` : value?.field}
                                                id={value?.field}
                                                className={`form-control max-width-13 ${value?.input === "datetime-local" ?
                                                    'datetimepicker text-uppercase' : ''}`
                                                }
                                            />
                                        </React.Fragment>
                                        : (value?.input === "text" && value?.unit) ?
                                            <React.Fragment>
                                                <Label className='mb-0 asteric'
                                                    htmlFor={value?.field}>{value?.label}</Label>
                                                <div className='input-group'>
                                                    <Field
                                                        type={value?.input}
                                                        name={isArray ? `${arrayName}.${index}.${value?.field}` : value?.field}
                                                        id={value?.field}
                                                        className='form-control max-width-13'
                                                    />
                                                    <div className="input-group-text">{value?.unit}</div>
                                                </div>
                                            </React.Fragment> :
                                            value?.input === "checkbox" ?
                                                <React.Fragment>
                                                    <Label className='mb-0 asteric' htmlFor={value?.field}>{value?.label}</Label>
                                                    <Field name={isArray ? `${arrayName}.${index}.${value?.field}` : value?.field}>
                                                        {({ field, form }: FieldProps) => (
                                                            <div className="square-switch sqswitch">
                                                                <Input
                                                                    type="checkbox"
                                                                    switch="none"
                                                                    name={field.name}
                                                                    id={field.name}
                                                                    onChange={(e) =>
                                                                        form.setFieldValue(field.name, e.target.checked)
                                                                    }
                                                                />
                                                                <Label
                                                                    htmlFor={value?.field}
                                                                    data-on-label="Yes"
                                                                    data-off-label="No"
                                                                />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </React.Fragment> :
                                                <React.Fragment>
                                                    <Label className='mb-0 asteric' htmlFor={value?.field}>{value?.label}</Label>
                                                    <Field name={isArray ? `${arrayName}.${index}.${value?.field}` : value?.field}>
                                                        {({ field, form }: FieldProps) => (
                                                            <AsyncSelect
                                                                name={field.name}
                                                                inputId={field.name}
                                                                key={index}
                                                                className='max-width-13'
                                                                defaultOptions
                                                                loadOptions={value?.loadOptions}
                                                                getOptionLabel={(e: { [key: string]: string | number }) => e[value?.selectLabel] as string}
                                                                getOptionValue={(e: { [key: string]: string | number }) => e[value?.selectValue] as string}
                                                                onChange={(e: { [key: string]: string | number }) =>
                                                                    form.setFieldValue(field.name, e?.id)
                                                                }
                                                                menuPortalTarget={document.body}
                                                                styles={customStyle}
                                                            />
                                                        )}
                                                    </Field>
                                                </React.Fragment>
                                }
                            </Col>
                        )
                    })}
                {arrayName === 'LNGTank' && values?.LNGTank?.length > 1 &&
                    <Col sm={1}>
                        <button type="button" className="btn justify_left mt-3">
                            <i className='dripicons-trash icon_s18'
                                onClick={() => {
                                    remove(index);
                                }}
                            />
                        </button>
                    </Col>
                }
            </Row>
            <Row>
                {arrayName === 'LNGTank' && index === (values.LNGTank.length - 1) &&
                    <Label
                        className="link_color_blue mt-2 mb-0"
                        onClick={() => {
                            push(initialValues[0])
                        }}
                    >
                        Add another LNG Tank
                    </Label>
                }
            </Row>
        </React.Fragment>
    )
}

export default FirstReportFormRow