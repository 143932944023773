import React from 'react'
import { Row, Col, Label, Input, Button } from 'reactstrap'

const ExternalVessel = () => {
    return (
        <React.Fragment>
            <Row className='mb-2'>
                <Col sm={12}>
                    <Label className='mb-0 asteric'>External vessel</Label>
                    <Input
                        name='external_vessel'
                        id='external_vessel'
                        className='form-control'
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col sm={5}>
                    <Label className='mb-0 asteric'>IMO number</Label>
                    <Input
                        name='imo_number'
                        id='imo_number'
                        className='form-control'
                    />
                </Col>
                <Col sm={7}>
                    <Label className='mb-0 asteric'>Compliance Balance</Label>
                    <Input
                        name='compliance_bal'
                        id='compliance_bal'
                        className='form-control'
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        color='primary'
                        className="btn justify_right mt-3"
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ExternalVessel