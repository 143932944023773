import ErrorTooltip from 'Components/ErrorTooltip';
import Loading from 'Components/Loading';
import { errorToast } from 'Components/Toasts';
import { commonValidationMessages } from 'Components/ValidationErrorMessages';
import env from 'environment/env';
import { Field, Formik } from 'formik';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { RootState } from 'index';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { queryClient } from 'react-query/queryClient';
import { useSelector } from 'react-redux';
import { Button, CardBody, Col, Form, Label, Row } from "reactstrap";
import { queryKeyes } from 'shared/queryKeys';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { loadVesselOtherSettingsOperation } from 'VesselMaster/vesselMaster.hooks';
import * as Yup from 'yup';

const Other = () => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [count, setCount] = useState(0);
    /** State variables end */

    /** Queries */
    /** Vessel's other setting;s object used for edit */
    const { data: OtherSettings, isLoading: OtherSettingsLoading, isError: OtherSettingsError }:
        { data: any, isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.VesselOtherSettingsObject.key, VesselID],
            async () => { return await loadVesselOtherSettingsOperation(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );
    /** End Queries */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        if (OtherSettings && OtherSettings[0]?.id > 0) {
            return OtherSettings[0];
        } else {
            return ({
                is_vse_mention: false,
                vse_value: null,
                reefer_container_name: "",
                is_wind_assistance_system_available: false,
                pwind: null,
                fwind: null,
                power_rating: null,
                is_steam_dump_valve_in_use: false,
                vessel: VesselID
            })
        }
    }

    /** Other Setting's formik object */
    const OtherSettingFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            vse_value: Yup.number().nullable().when('is_vse_mention', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }),
            pwind: Yup.number().when('is_wind_assistance_system_available', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }),
            fwind: Yup.number().when('is_wind_assistance_system_available', {
                is: true,
                then: (schema: any) => schema.required(commonValidationMessages.required),
                otherwise: (schema: any) => schema.nullable(),
            }).max(1, commonValidationMessages.max1).min(0, commonValidationMessages.min0),
        })
    }
    return (
        <CardBody className='pb-0 pt-0'>
            {OtherSettingsLoading && <Loading message='Loading required data!' />}
            {OtherSettingsError && <>{setCount(count + 1)}</>}
            {!OtherSettingsLoading &&
                <Formik
                    key={count}
                    initialValues={OtherSettingFormik.initialValues}
                    validationSchema={OtherSettingFormik.validationSchema}
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        if (!values?.is_vse_mention) {
                            values.vse_value = null; // Reset to empty string if unchecked
                        }
                        if (!values?.is_wind_assistance_system_available) {
                            values.fwind = null; // Reset to empty string if unchecked
                            values.pwind = null; // Reset to empty string if unchecked
                        }
                        if (OtherSettings && OtherSettings[0]?.id > 0) {
                            apiGlobal.put(`/other_setting/${values?.id}/`, values).then(res => {
                                if (res.status === 200) {
                                    queryClient.invalidateQueries(queryKeyes.vessel.VesselOtherSettingsObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        } else {
                            apiGlobal.post(`/other_setting/`, values).then(res => {
                                if (res.status === 201) {
                                    queryClient.invalidateQueries(queryKeyes.vessel.VesselOtherSettingsObject.key);
                                }
                            }).catch(err => {
                                if (errResponse.includes(err.response.status)) {
                                    errorToast("Internal error occured, please contact the admin");
                                }
                            });
                        }
                    }}
                >
                    {props => (
                        <Form autoComplete="off" onSubmit={props?.handleSubmit} noValidate>
                            <Row className='mb-2'>
                                <Col sm={4}>
                                    <div className="d-flex flex-wrap gap-2 mt-4">
                                        <Field
                                            name='is_vse_mention'
                                            className="form-check-input"
                                            type="checkbox"
                                            id='steamCheckbox'
                                        />
                                        <Label className='mr-1' for='steamCheckbox'>Is VSE mentioned in EEDI / EEXI?</Label>
                                    </div>
                                </Col>
                                <Col sm={4}>
                                    <Label className='mb-0 asteric' for=''>VSE value</Label>
                                    <Field
                                        type="text"
                                        name="vse_value"
                                        id="vse_value"
                                        className="form-control max-width-7 text-right mb-2"
                                        disabled={!props?.values?.is_vse_mention}
                                    />
                                    {props?.errors && env?.form_validation === true && props?.touched?.vse_value &&
                                        <ErrorTooltip
                                            target='vse_value'
                                            message={props?.errors?.vse_value}
                                            open={props?.errors?.vse_value ? true : false}
                                        />
                                    }
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col sm={4}>
                                    <div className="d-flex flex-wrap gap-2 mt-4">
                                        <Field
                                            name='is_wind_assistance_system_available'
                                            className="form-check-input"
                                            type="checkbox"
                                            id='is_wind_assistance_system_available'
                                        />
                                        <Label className='mr-1' for='is_wind_assistance_system_available'>
                                            Is wind assistance system available?
                                        </Label>
                                    </div>
                                </Col>
                                <Col sm={2}>
                                    <Label className='mb-0 asteric' for=''>Pwind</Label>
                                    <div className='input-group'>
                                        <Field
                                            type="text"
                                            name="pwind"
                                            id="pwind"
                                            className="form-control max-width-7 text-right"
                                            disabled={!props?.values?.is_wind_assistance_system_available}
                                        />
                                        <div className='input-group-text'>kW</div>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <Label className='mb-0 asteric' for=''>Fwind</Label>
                                    <div className='input-group'>
                                        <Field
                                            type="text"
                                            name="fwind"
                                            id="fwind"
                                            className="form-control max-width-7 text-right"
                                            disabled={!props?.values?.is_wind_assistance_system_available}
                                        />
                                        <div className='input-group-text'>kW</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mb-2'>
                                <Col sm={4}>
                                    <div className="d-flex flex-wrap gap-2 mt-4">
                                        <Field
                                            name='is_steam_dump_valve_in_use'
                                            className="form-check-input"
                                            type="checkbox"
                                            id='is_steam_dump_valve_in_use'
                                        />
                                        <Label className='mr-1' for='is_steam_dump_valve_in_use'>
                                            Is Steam dump valve used?
                                        </Label>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                    <Button type="submit" color="primary" className='btn_size4_5_cstm'>Save</Button>
                                    <Button type="reset" color="danger" className='btn_size4_5_cstm'>Reset</Button>
                                </div>
                            </Row>
                            <FormValuesDebug values={[props?.values, props?.errors, OtherSettingFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            }
             <p> - Set values.</p>
        </CardBody>
    )
}

export default Other