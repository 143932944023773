import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "reactstrap";
import apiGlobal from "../global/api.global";
import * as Yup from "yup";
import "../global/GlobalCSS.css";
import Layout from "../HorizontalMenu/Menu";
import Navbar from "../HorizontalMenu/Navbar";
import VesselDetailsHeader from "../Components/VesselDetailsHeader";
import { useDispatch, useSelector } from "react-redux";
import {
    VesselState,
    getVesselsAction,
    setVesselState
} from "../Store/Generic/ReportingSlice";
import { errorToast, successToast } from "../Components/Toasts";
import { PortConstant, VoyageConstant } from "shared/constants";
import {
    loadOtherPorts,
    loadVoyageObject,
} from "VesselMaster/vesselMaster.hooks";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { queryClient } from "react-query/queryClient";
import CreateVoyageStructure from "./CreateVoyageStructure";

interface CreateVoyageType {
    voyageId?: number;
    toggleTab?: (activeTab: number) => void,
    activeTab?: number
    setVoyageId?: (voyageId: number) => void
}

const CreateVoyage = ({
    voyageId,
    toggleTab,
    activeTab,
    setVoyageId
}: CreateVoyageType) => {
    /** State variables */
    const dispatch = useDispatch();
    const { VesselState, VesselID } = useSelector(
        (state: any) => state.Reporting
    );
    const [otherPort, setOtherPort] = useState(false);
    const [addOtherPort, setAddOtherPort] = useState(false);
    /** State variables end */

    /** useQueries */
    /** Voyage object used for edit */
    const {
        data: VoyageObject,
        isLoading: VoyageObjectLoading,
    } = useQuery(
        [queryKeyes.vessel.VoyageObject.key, voyageId],
        async () => {
            return await loadVoyageObject(voyageId);
        },
        { staleTime: Infinity }
    );
    /** Other Ports from master */
    const {
        data: OtherPortsObject,
        isLoading: OtherPortsLoading,
    }: { data: any; isLoading: any; isError: any } = useQuery(
        [queryKeyes.masters.OtherPortMaster.key],
        async () => {
            return await loadOtherPorts();
        },
        { staleTime: Infinity }
    );
    /** useQueries end */

    /** useEffect start */
    useEffect(() => {
        dispatch(getVesselsAction("vessel_master" as string));
    }, [dispatch]);
    /** useEffect end */

    /** Assigning initial object to voyage's formik object */
    const getInitialValues = () => {
        if (VoyageObject && VoyageObject?.id > 0) {
            return VoyageObject;
        } else {
            return {
                voyage_number: "",
                voyage_status: VoyageConstant.NEWVOYAGE,
                departure_date_time: null,
                departure_port: null,
                other_port: null as number,
                other_port_name: null as string,
                other_country: null as number,
                other_country_name: null as string,
                vessel: VesselID,
            };
        }
    };

    /**useEffect start */
    useEffect(() => {
        if (VoyageObject && VoyageObject?.id > 0) {
            if (VoyageObject?.port_precedence_id === PortConstant.OTHER) {
                setOtherPort(true);
            }
        }
    }, [VoyageObject]);
    /** useEffect end */

    /** Voyage Information formik object */
    const VoyageFormik = {
        initialValues: getInitialValues(),
        validationSchema: Yup.object({
            voyage_number: Yup.string()
                .matches(
                    /^[A-Za-z0-9-/]{1,50}$/,
                    "Please enter upto 50 alphabets/numbers only"
                )
                .required(commonValidationMessages.required),
            departure_port: Yup.string().required(commonValidationMessages.required),
            departure_date_time: Yup.string().required(
                commonValidationMessages.required
            ),
            other_port: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(
                        otherPort === true &&
                        OtherPortsObject &&
                        OtherPortsObject?.length > 0
                    )
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_port_name: Yup.string().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
            other_country: Yup.number().when(
                "$fieldAvailability",
                (field: any, schema) => {
                    return !(addOtherPort === true || !OtherPortsObject)
                        ? schema.nullable()
                        : schema.required(commonValidationMessages.required);
                }
            ),
        }),
    };

    const handleVesselState = (record: VesselState) => {
        dispatch(setVesselState(record));
    };

    /** POST request for Voyage Information */
    const postVoyage = (values: any) => {
        apiGlobal
            .post(`/voyage_information/`, values)
            .then((res) => {
                if (VesselState !== "VOYAGE_REPORTING") {
                    setVoyageId(res.data.id);
                }
                if (res.status === 201) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                    handleVesselState("VOYAGE_REPORTING");
                }
            })
            .catch((err) => {
                errorToast(err.response.data.error[0]);
            });
    };

    /** PUT request for Voyage Information */
    const putVoyage = (values: any) => {
        apiGlobal
            .put(`/voyage_information/${values?.id}/`, values)
            .then((res) => {
                if (res.status === 200) {
                    successToast("Data saved successfully!");
                    queryClient.invalidateQueries(queryKeyes.vessel.VoyageObject.key);
                    handleVesselState("VOYAGE_REPORTING");
                }
            })
            .catch((err) => {
                errorToast(err.response.data.error[0]);
            });
    };

    /** Voyage submit function */
    const voyageSubmit = (values: any) => {
        if (VoyageObject && VoyageObject?.id > 0) {
            putVoyage(values);
        } else {
            postVoyage(values);
        }
    };
    return (
        <React.Fragment>
            {(VesselState === "CREATE_VOYAGE_REPORTING" || VesselState === "VOYAGE_REPORTING_EDIT") ?
                <React.Fragment>
                    <Layout children={Navbar} />
                    <div className="page-content">
                        <Container fluid>
                            <Row>
                                <Col sm={2}>
                                    <button
                                        color="primary"
                                        className="btn btn-primary mb-3"
                                        onClick={() => {
                                            handleVesselState("VOYAGE_REPORTING");
                                        }}
                                    >
                                        <i className="bx bx-chevron-left me-1" />
                                        Back
                                    </button>
                                </Col>
                                <Col sm={10}>
                                    <VesselDetailsHeader />
                                </Col>
                            </Row>
                            <CreateVoyageStructure
                                VoyageObjectLoading={VoyageObjectLoading}
                                OtherPortsLoading={OtherPortsLoading}
                                voyageSubmit={voyageSubmit}
                                VoyageFormik={VoyageFormik}
                                otherPort={otherPort}
                                setOtherPort={setOtherPort}
                                addOtherPort={addOtherPort}
                                setAddOtherPort={setAddOtherPort}
                                OtherPortsObject={OtherPortsObject}
                                VoyageObject={VoyageObject}
                            />
                        </Container>
                    </div>
                </React.Fragment> :
                <Card className="p-2">
                    <CreateVoyageStructure
                        VoyageObjectLoading={VoyageObjectLoading}
                        OtherPortsLoading={OtherPortsLoading}
                        voyageSubmit={voyageSubmit}
                        VoyageFormik={VoyageFormik}
                        otherPort={otherPort}
                        setOtherPort={setOtherPort}
                        addOtherPort={addOtherPort}
                        setAddOtherPort={setAddOtherPort}
                        OtherPortsObject={OtherPortsObject}
                        VoyageObject={VoyageObject}
                        toggleTab={toggleTab}
                        activeTab={activeTab}
                    />
                </Card>
            }
        </React.Fragment>
    );
};

export default CreateVoyage;
