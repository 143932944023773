import { combineReducers } from "redux"

// Layout
import Layout from "./Layout/reducer"
import genericReducer from "../Store/Generic/GenericSlice"
import reportingReducer from "./Generic/ReportingSlice"

//Dashboard


const rootReducer = combineReducers({
  // public
  Layout,
  Generic: genericReducer,
  Reporting: reportingReducer
})

export default rootReducer
