import React, { useState } from 'react';
import { CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import GeneralSettings from './GeneralSettings';
import CargoDischarging from './CargoDischarging';
import CargoCooling from './CargoCooling';
import CargoHeating from './CargoHeating';
import TankCleaning from './TankCleaning';
import ReeferContainer from './ReeferContainer';
import CargoReliquification from './CargoReliquification';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { SpOpsConfigurationConstant, VesselTypeConstant } from 'shared/constants';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadSpecialOperationSettingList } from 'VesselMaster/vesselMaster.hooks';

const SpecialOperations = () => {
    /** Retrieves the VesselID and Vessels data from the Redux store. */
    const { VesselID, Vessels } = useSelector((state: RootState) => state.Reporting);

    /** State to track the currently active tab in the settings interface. */
    const [activeSettingsTab, setActiveSettingsTab] = useState('1');

    /** Finds the current vessel based on the VesselID. */
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);

    /** States to manage the visibility of special operation tabs. */
    const [cargoDischarging, setCargoDischarging] = useState(false);
    const [cargoHeating, setCargoHeating] = useState(false);
    const [cargoCooling, setCargoCooling] = useState(false);
    const [refeerContainer, setReeferContainer] = useState(false);
    const [tankCleaning, setTankCleaning] = useState(false);
    const [cargoReliquification, setCargoReliquification] = useState(false);
    const [count, setCount] = useState(0);

    /**
     * Handles the tab change for special operations. It only updates the active tab
     * if the new tab is different from the current one, also increments the count state.
     * 
     * @param {string} tab - The tab identifier for switching views.
     */
    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setCount(count + 1);
            setActiveSettingsTab(tab);
        }
    };

    /**
     * Fetches the general settings object for the vessel's special operations from the backend.
     * Uses the vessel's ID as a parameter to load the corresponding settings.
     */
    const { data: generalSettingObj }: { data: any } = useQuery(
        [queryKeyes.vessel.SpecialOperationSettingList.key],
        async () => {
            return await loadSpecialOperationSettingList(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );

    return (
        <>
            <CardBody className='pb-0 pt-0'>
                <Nav tabs className='nav-tabs-custom nav-justified'>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsGeneralSettingsId,
                            })}
                            onClick={() => {
                                toggle(SpOpsConfigurationConstant.SpOpsGeneralSettingsId);
                            }}
                        >
                            General Settings
                        </NavLink>
                    </NavItem>
                    {vessel && vessel?.vessel_type === VesselTypeConstant.TANKER &&
                        <>
                            {(cargoHeating || (generalSettingObj && generalSettingObj[0]?.cargo_heating)) &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoHeatingId,
                                        })}
                                        onClick={() => {
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoHeatingId);
                                        }}
                                    >
                                        Cargo Heating
                                    </NavLink>
                                </NavItem>}
                            {(cargoDischarging || (generalSettingObj && generalSettingObj[0]?.cargo_discharging)) &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoDischargingId,
                                        })}
                                        onClick={() => {
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoDischargingId);
                                        }}
                                    >
                                        Cargo Discharging
                                    </NavLink>
                                </NavItem>
                            }
                            {(tankCleaning || (generalSettingObj && generalSettingObj[0]?.tank_cleaning)) &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsTankCleaningId,
                                        })}
                                        onClick={() => {
                                            toggle(SpOpsConfigurationConstant.SpOpsTankCleaningId);
                                        }}
                                    >
                                        Tank Cleaning
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                    {vessel && (vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER ||
                        vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER) &&
                        <>
                            {(cargoCooling || (generalSettingObj && generalSettingObj[0]?.cargo_cooling)) &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoCoolingId,
                                        })}
                                        onClick={() => {
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoCoolingId);
                                        }}
                                    >
                                        Cargo Cooling
                                    </NavLink>
                                </NavItem>}
                            {(cargoReliquification || (generalSettingObj && generalSettingObj[0]?.cargo_reliquification)) &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsCargoReliquificationId,
                                        })}
                                        onClick={() => {
                                            toggle(SpOpsConfigurationConstant.SpOpsCargoReliquificationId);
                                        }}
                                    >
                                        Cargo Reliquification
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                    {vessel && (vessel?.vessel_type === VesselTypeConstant.CONTAINER ||
                        vessel?.vessel_type === VesselTypeConstant.GENERAL_CARGO_SHIP) &&
                        <>
                            {(refeerContainer || (generalSettingObj && generalSettingObj[0]?.reefer_container)) &&
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeSettingsTab === SpOpsConfigurationConstant.SpOpsReeferContainerId,
                                        })}
                                        onClick={() => {
                                            toggle(SpOpsConfigurationConstant.SpOpsReeferContainerId);
                                        }}
                                    >
                                        Reefer Container
                                    </NavLink>
                                </NavItem>
                            }
                        </>
                    }
                </Nav>
            </CardBody>

            <TabContent activeTab={activeSettingsTab} className="p-3 text-muted">
                <TabPane tabId= {SpOpsConfigurationConstant.SpOpsGeneralSettingsId}>
                    <GeneralSettings
                        setCargoDischarging={setCargoDischarging}
                        setCargoHeating={setCargoHeating}
                        setCargoCooling={setCargoCooling}
                        setReeferContainer={setReeferContainer}
                        setCargoReliquification={setCargoReliquification}
                        setTankCleaning={setTankCleaning}
                        count={count}
                        setCount={setCount}
                    />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoDischargingId}>
                    <CargoDischarging />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoCoolingId}>
                    <CargoCooling />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoHeatingId}>
                    <CargoHeating />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsTankCleaningId}>
                    <TankCleaning />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsReeferContainerId}>
                    <ReeferContainer />
                </TabPane>
                <TabPane tabId={SpOpsConfigurationConstant.SpOpsCargoReliquificationId}>
                    <CargoReliquification />
                </TabPane>
            </TabContent>
        </>
    )
}

export default SpecialOperations;