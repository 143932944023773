// EmailDetails.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { NotificationActiveTab, NotificationStateConstant, NotificationUserStatusConstant } from 'shared/constants';
import { queryKeyes } from 'shared/queryKeys';
import { loadCommonNotificationURL } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { HandleNotificationUserState, handleNotificationState } from './Notification';

// Define the props for the EmailDetails component
interface NotificationDetailsProps {
  notification: {
    [key: string]: any;
    title: string;
    id: number;
    message: string;
    date: string;
    category: string;
    priority: string;
  } | null;
  handleBackClick: () => void;
  selectedNotification: any;
  notificationUserStatusObject: any;
  notificationUser: any;
  activeTab: string;
  notificationStateObject: any;
  count: number;
  setCount: any;
  refetch: () => any;
}
const NotificationDetails: React.FC<NotificationDetailsProps> = ({
  notification,
  handleBackClick,
  selectedNotification,
  notificationUserStatusObject,
  notificationUser,
  activeTab,
  notificationStateObject,
  count,
  setCount,
  refetch,
}) => {
  /**
   * Fetches the notification list object for the selected notification using `useQuery`.
   *
   * @constant
   * @type {object}
   * @property {any[]} data - The notification list object, fetched based on the selected notification ID.
   * @property {boolean} isError - Indicates if there was an error in fetching the notification list.
   * @property {boolean} isLoading - Indicates if the notification list data is still loading.
   */
  const {
    data: notificationListObject,
    isError: notificationListError,
    isLoading: notificationListLoading
  } = useQuery(
    [queryKeyes.notification.Notification.key, selectedNotification?.id],
    () => loadCommonNotificationURL(selectedNotification?.id),
    { enabled: !!selectedNotification, staleTime: Infinity }
  );

  /**
  * Returns a prompt to select an email if no notification is currently selected.
  */
  if (!notification) return <div>Select an email to view details</div>;

  const handleStateChange = (stateConstant: string, isUserState: boolean = false) => {
    handleBackClick();
    if (isUserState) {
      HandleNotificationUserState(selectedNotification, stateConstant, notificationUserStatusObject, notificationUser, count, setCount);
    } else {
      handleNotificationState(selectedNotification, stateConstant, notificationStateObject, notificationListObject);
    }
  };

  /**
   * Handles state changes for notifications by calling the appropriate function based on `isUserState`.
   *
   * @function
   * @param {string} stateConstant - The new state constant to set.
   * @param {boolean} [isUserState=false] - Determines whether to update user-specific notification state.
   * @returns {void}
   */
  return (
    <React.Fragment>
      {notificationListLoading && <Loading message='Loading required data!' />}
      {notificationListError && <ErrorComponent message='Error loading data' />}
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <p>{notification.message}</p>
              <hr />
              <div className="p-0">
                {/* Back Button */}
                <Link to="#" className="btn btn-secondary mt-4 m-2" onClick={handleBackClick}>
                  <i className="mdi mdi-reply me-1"></i> Back
                </Link>

                {/* Action Buttons */}
                {activeTab === NotificationActiveTab.INBOX && (
                  <Button className="btn btn-secondary waves-effect mt-4 m-2" onClick={() => {
                    handleStateChange(NotificationUserStatusConstant.HIDDEN, true);
                    refetch();
                  }}>
                    <i className="mdi mdi-archive me-1"></i> Archive
                  </Button>
                )}

                {/* Archive Button for Draft tab & Sent tab*/}
                {(activeTab === NotificationActiveTab.DRAFT || activeTab === NotificationActiveTab.SENT) && (
                  <Button className="btn btn-secondary waves-effect mt-4 m-2" onClick={() => {
                    handleStateChange(NotificationStateConstant.HIDDEN);
                    refetch();
                  }}>
                    <i className="mdi mdi-archive me-1"></i> Archive
                  </Button>
                )}

                {/* Permanently Delete Button */}
                <Button className="btn btn-danger waves-effect mt-4 m-2" onClick={() => {
                  handleStateChange(NotificationStateConstant.DELETED, true);
                  refetch();
                }}>
                  <i className="mdi mdi-delete-forever me-1"></i> Permanently Delete
                </Button>

                {/* Revert Button for Hidden tab */}
                {activeTab === NotificationActiveTab.HIDDEN && (
                  <Button className="btn btn-warning waves-effect mt-4 m-2" onClick={() => {
                    handleStateChange(NotificationUserStatusConstant.DELETED, true);
                    refetch();
                  }}>
                    <i className="mdi mdi-replay me-1"></i> Revert
                  </Button>
                )}

                {/* Send Button for Draft tab */}
                {activeTab === NotificationActiveTab.DRAFT && (
                  <Button className="btn btn-primary waves-effect mt-4 m-2" onClick={() => {
                    handleStateChange(NotificationStateConstant.SENT);
                    refetch();
                  }}>
                    <i className="mdi mdi-send me-1"></i> Send
                  </Button>
                )}

                {/* Edit Button for Draft tab */}
                {activeTab === NotificationActiveTab.DRAFT && (
                  <Button className="btn btn-info waves-effect mt-4 m-2" onClick={() => {
                    handleStateChange(NotificationStateConstant.HIDDEN);
                    refetch();
                  }}>
                    <i className="mdi mdi-pencil me-1"></i> Edit
                  </Button>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default NotificationDetails;
