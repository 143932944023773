import React from 'react'
import { Field, FieldArray, FieldProps, useFormikContext } from 'formik'
import { Input } from 'reactstrap'

interface FourHrTableComponentType {
    vesselMachinery: any,
    lastRecord: any,
    record: any,
    tableHeader: string,
    handleChange: any,
    valueName: string,
    valueSubName: any[],
    showMachineryName: boolean,
    energyConsumedField: string,
    applicableTimePeriod: any
}

const FourHrTableComponent = ({
    vesselMachinery,
    tableHeader,
    handleChange,
    valueName,
    valueSubName,
    showMachineryName,
    energyConsumedField,
}: FourHrTableComponentType) => {
    /** State variables */
    const { values }: { values: any } = useFormikContext();
    /** State variables end */

    /** Calculate W */
    const calculateW = (form: any, values: any, name: any, e: any, index: number) => {
        if (name === 'hours_of_consumption') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    e.target.value *
                    valueSubName[index]?.v *
                    valueSubName[index]?.i *
                    valueSubName[index]?.pf *
                    0.001).toFixed(2));
        } else if (name === 'v') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    valueSubName[index]?.hours_of_consumption *
                    e.target.value *
                    valueSubName[index]?.i *
                    valueSubName[index]?.pf * 0.001).toFixed(2));
        } else if (name === 'i') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    valueSubName[index]?.hours_of_consumption *
                    valueSubName[index]?.v *
                    e.target.value *
                    valueSubName[index]?.pf *
                    0.001).toFixed(2));
        } else if (name === 'pf') {
            form.setFieldValue(`${valueName}.${index}.w`,
                (1.73205080757 *
                    valueSubName[index]?.hours_of_consumption *
                    valueSubName[index]?.v *
                    valueSubName[index]?.i *
                    e.target.value *
                    0.001).toFixed(2));
        }
    }

    /** Calculate sum of w */
    const loadwsum = (values: any[], field: string, form: any) => {
        let sum = 0;
        values.map((val: any) => {
            sum += parseFloat(val?.w ?? 0);
            return '';
        })
        form.setFieldValue(field, sum.toFixed(2));
    }
    return (
        <>
            {showMachineryName === true &&
                <h6 className='mb-3 mt-3 ps-3'>{vesselMachinery.vessel_machinery_ec_group_name}</h6>
            }
            <div className="table-responsive p-0 ">
                <table className="table mb-0">
                    <thead className="table-light">
                        <tr>
                            <th className="text-center">Period</th>
                            <th className="asteric text-center">{tableHeader}</th>
                            <th className="asteric text-center">V</th>
                            <th className="asteric text-center">I</th>
                            <th className="asteric text-center">Pf</th>
                            <th className="asteric text-center">W=kWh</th>
                        </tr>
                    </thead>
                    <FieldArray name={`${valueName}`}>
                        {() => (
                            <>
                                {valueSubName && valueSubName !== undefined && valueSubName?.filter((item: any) =>
                                    item.vessel_machinery_name === vesselMachinery?.vessel_machinery_ec_group).map((time: any, index: number) => {
                                        return (
                                            <tbody key={index}>
                                                <tr>
                                                    <td className='align-middle p-2 text-center'>{time.time_period}</td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <div className="input-group">
                                                                <Field name={`${valueName}.${valueSubName.indexOf(time)}.hours_of_consumption`}>
                                                                    {({ field, form }: FieldProps) => (
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control text-right max-width-7"
                                                                            id={time.id}
                                                                            name={field.name}
                                                                            onBlur={(e: any) => {
                                                                                handleChange(e);
                                                                                calculateW(form, values, 'hours_of_consumption', e, valueSubName.indexOf(time));
                                                                            }}
                                                                            defaultValue={valueSubName[valueSubName.indexOf(time)]?.hours_of_consumption}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <div className="input-group-text">hr</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <Field name={`${valueName}.${valueSubName.indexOf(time)}.v`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control text-right max-width-7"
                                                                        id={time.id}
                                                                        name={field.name}
                                                                        onBlur={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'v', e, valueSubName.indexOf(time));
                                                                        }}
                                                                        defaultValue={valueSubName[valueSubName.indexOf(time)].v}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <Field name={`${valueName}.${valueSubName.indexOf(time)}.i`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control text-right max-width-7"
                                                                        id={time.id}
                                                                        name={field.name}
                                                                        onBlur={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'i', e, valueSubName.indexOf(time));
                                                                        }}
                                                                        defaultValue={valueSubName[valueSubName.indexOf(time)].i}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="d-inline-block">
                                                            <Field name={`${valueName}.${valueSubName.indexOf(time)}.pf`}>
                                                                {({ field, form }: FieldProps) => (
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control text-right max-width-7"
                                                                        id={time.id}
                                                                        name={field.name}
                                                                        onChange={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'pf', e, valueSubName.indexOf(time));
                                                                            loadwsum(valueSubName, energyConsumedField, form)
                                                                        }}
                                                                        onBlur={(e: any) => {
                                                                            handleChange(e);
                                                                            calculateW(form, values, 'pf', e, valueSubName.indexOf(time));
                                                                            loadwsum(valueSubName, energyConsumedField, form)
                                                                        }}
                                                                        defaultValue={valueSubName[valueSubName.indexOf(time)].pf}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className='text-center'>
                                                        <div className="d-inline-block">
                                                            <Field
                                                                type="text"
                                                                className="form-control text-right max-width-7"
                                                                id={time.id}
                                                                name={`${valueName}.${valueSubName.indexOf(time)}.w`}
                                                                disabled
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}
                            </>
                        )}
                    </FieldArray>
                </table>
            </div>
        </>
    )
}

export default FourHrTableComponent