import React, { useEffect } from 'react'
import env from 'environment/env'
import { Formik } from 'formik'
import { RootState } from 'index'
import { useSelector } from 'react-redux'
import { Row, Form, Button, CardFooter, Card, CardHeader } from 'reactstrap'
import { loadReportingEventsMaster, loadReportingOperationsMaster } from 'VesselMaster/vesselMaster.hooks'
import FormValuesDebug from 'utils/debugTools/FormValuesDebug'
import FirstReportForm, { fieldObjectInterface } from './FirstReportForm'

interface FirstVesselReportType {
    toggleTab: (activeTab: number) => void,
    activeTab: number,
    voyageId: number,
    setReportId: (reportId: number) => void
    setReport: (report: object) => void
}

const FirstVesselReport = ({
    toggleTab,
    activeTab,
    voyageId,
    setReport
}: FirstVesselReportType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const fieldArray: fieldObjectInterface[] = [
        {
            label: "Reporting Time(UTC)",
            field: "reporting_time_utc",
            input: "datetime-local"
        },
        {
            label: "Reporting Time Difference",
            field: "reporting_time_difference",
            input: "text",
            unit: "hrs"
        },
        {
            label: "No. of Hours Retarded",
            field: "no_of_hours_retarded",
            input: "text",
            unit: "hrs"
        },
        {
            label: "Reporting event",
            field: "reporting_event",
            input: "select",
            selectLabel: "reporting_event_name",
            selectValue: "id",
            loadOptions: loadReportingEventsMaster
        },
        {
            label: "Reporting operation",
            field: "reporting_operation_mode",
            input: "select",
            selectLabel: "reporting_operation_mode_name",
            selectValue: "id",
            loadOptions: loadReportingOperationsMaster
        },
        {
            label: "Previous event other than noon",
            field: "previous_event_other_than_noon",
            input: "text"
        },
        {
            label: "Previous operation",
            field: "previous_operation",
            input: "text"
        },
        {
            label: "Voyage",
            field: "voyage_information",
            input: "text",
            value: voyageId
        },
        {
            label: "Vessel",
            field: "vessel",
            input: "text",
            value: VesselID
        },

    ]
    /** State varibales end */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        let obj: { [key: string]: string | number | boolean } = {};
        fieldArray.forEach((fieldObj: fieldObjectInterface) => {
            obj[fieldObj?.field] = fieldObj?.value ?? null;
        })
        return obj;
    }

    /** useEffect */
    useEffect(() => {
        FirstVesselReportFormik.initialValues = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getInitialValues, VesselID, voyageId])
    /** useEffect end */

    /** Firtvessel report's formik object */
    const FirstVesselReportFormik = {
        initialValues: getInitialValues()
    };

    return (
        <Card className="mb-0 p-0">
            <CardHeader className="p-2 border-0">
                <h5 className="mb-0">Vessel Reporting Information</h5>
            </CardHeader>
            <Formik
                onSubmit={(values: any, _actions: any) => {
                    setReport(values);
                }}
                initialValues={FirstVesselReportFormik.initialValues}
            >
                {(props) => (
                    <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <FirstReportForm
                                fieldArray={fieldArray}
                                isArray={false}
                            />
                        <CardFooter className="p-2 py-3">
                            <Row className="ele_row1">
                                <div className="d-flex flex-wrap gap-5">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn_size_cstm pos-end"
                                        onClick={() => {
                                            if (env?.form_validation === false) {
                                                toggleTab(activeTab + 1);
                                            }
                                        }}
                                    >
                                        Next <i className="bx bx-chevron-right ms-1" />
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        className="btn_size_cstm"
                                        onClick={() => {
                                            toggleTab(activeTab - 1);
                                        }}
                                    >
                                        <i className="bx bx-chevron-left me-1" /> Previous
                                    </Button>
                                </div>
                            </Row>
                        </CardFooter>
                        <FormValuesDebug values={[props?.values, props?.errors, FirstVesselReportFormik.initialValues]} />
                    </Form>
                )}
            </Formik>
        </Card>
    )
}

export default FirstVesselReport