import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { userAllowedRoutes } from './allRoutes';
import '../src/assets/scss/app.scss';
import '../src/assets/scss/bootstrap.scss';
import '../src/assets/scss/icons.scss';
import '../src/assets/scss/preloader.scss';
import '../src/assets/scss/theme.scss';
import Login from 'Login/Login';
import { isLoggedIn } from 'utils/auth/authUtils';
import Logout from 'Login/Logout';
import env from 'environment/env';
import { useEffect, useState } from 'react';
import PageNotFound from 'Components/PageNotFound';

function App() {
  const [showMessage, setShowMessage] = useState(true);
  const delay = 5000;

  useEffect(() => {
    const timer = setTimeout(() => setShowMessage(false), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  let location = useLocation();
  let currentRoute: any;

  if (isLoggedIn() === false && location.pathname !== '/login') {
    return <Navigate to="/login" />
  }

  currentRoute = userAllowedRoutes.find(route => {
    if (route.path === location.pathname) {
      return true
    }
    const regex = new RegExp(route.path.replace(':id', '(\\d+)'));
    return regex.test(location.pathname);
  });
  if (!currentRoute && userAllowedRoutes.length > 0 && location.pathname !== '/login' && location.pathname !== '/logout') {
    return (
      <>
        {showMessage ? (
          <PageNotFound />
        ) : (
          <Navigate to="/dashboard" />
        )}
      </>
    );
  }

  return (
    <div>
      <Routes>
        <Route path={"/login"} element={<Login />} key={'login'} />
        <Route path={"/logout"} element={<Logout />} key={'logout'} />
        <Route path={"/"} element={<Navigate to="/login" />} key={'login-default'} />
        {userAllowedRoutes && userAllowedRoutes.map((route, idx) => {
          if (route.component) {
            return (
              <Route
                path={route.path}
                element={
                  isLoggedIn() ?
                    route.component :
                    <Navigate to={env.desktop_app === true ? "/#login" : "/login"} />
                }
                key={idx}
              />
            );
          }
          return null;
        })}
      </Routes>
    </div>
  );
}

export default App;
