import { createSlice } from "@reduxjs/toolkit"
import { apiCall } from "../../utils/middlewares/ApiActions";

export interface Reporting {
    [key: string]: string | number | boolean | string[] | any,
}
export type VesselState = null |
    'VIEW' |
    'EDIT' |
    'NEW' |
    'ADDITIONAL_INFO' |
    'VESSEL_CONFIG' |
    'VOYAGE_REPORTING' |
    'VOYAGE_REPORTING_EDIT' |
    'CREATE_VOYAGE_REPORTING' |
    'VESSEL_REPORTING' |
    'CREATE_VESSEL_REPORTING' |
    'DO_REPORTING' |
    'ENGG_REPORTING';

export interface ReportingState {
    Vessels: Reporting[],
    loading: boolean,
    error: string | null,
    VesselID: number,
    VoyageID: number,
    ReportID: number,
    Reports: Reporting[],
    Voyages: Reporting[],
    VoyageParameterRow: Reporting[],
    EnergyParameterRow: Reporting[],
    SpecialOpsEnergyRow: Reporting[],
    VesselState: VesselState,
    specialOperationEnergyAction: boolean,
    EnergyMeterMachinery: Reporting[],
    EnergyMeterMachineryId: Reporting[],
    SpecialOpsEnergy: number,
    PopUpState: boolean
    fifteenDays: boolean,
    thirtyDays: boolean,
    ninetyDays: boolean,
    steamingTime: boolean,
    GeneralSettings: Reporting[],
    BatchConsumption: Reporting[],
    FuelDebunkering: Reporting[],
    FourHrTbl: Reporting[],
    GeneralSettRow: Reporting[],
    SfcoRow: Reporting[],
    EquivalentBatchConsumption: Reporting[],
    MainEngineRow: Reporting[],
    BatchROB: Reporting[],
}

const initialState: ReportingState = {
    Vessels: [],
    loading: false,
    error: null,
    VesselID: -1,
    VoyageID: -1,
    ReportID: -1,
    Reports: [],
    Voyages: [],
    VoyageParameterRow: [],
    EnergyParameterRow: [],
    SpecialOpsEnergyRow: [],
    VesselState: null,
    specialOperationEnergyAction: false,
    EnergyMeterMachinery: [],
    EnergyMeterMachineryId: [],
    SpecialOpsEnergy: -1,
    PopUpState: false,
    fifteenDays: false,
    thirtyDays: false,
    ninetyDays: false,
    steamingTime: false,
    GeneralSettings: [],
    BatchConsumption: [],
    FuelDebunkering: [],
    FourHrTbl: [],
    GeneralSettRow: [],
    SfcoRow: [],
    EquivalentBatchConsumption: [],
    MainEngineRow: [],
    BatchROB: [],
}

const ReportingSlice = createSlice({
    name: "reporting",
    initialState,
    reducers: {
        apiRequested: (state) => {
            state.loading = true;
        },
        apiRequestedFailed: (state) => {
            state.loading = false;
        },
        setVesselState: (state, action) => {
            state.VesselState = action.payload
        },
        setVesselID: (state, action) => {
            state.VesselID = action.payload
        },
        setVoyageID: (state, action) => {
            state.VoyageID = action.payload
        },
        setReportID: (state, action) => {
            state.ReportID = action.payload
        },
        getVessels: (state, action) => {
            state.Vessels = action.payload;
            state.loading = false;
        },
        getVoyages: (state, action) => {
            state.Voyages = action.payload;
            state.loading = false;
        },
        getReports: (state, action) => {
            state.Reports = action.payload;
            state.loading = false;
        },
        setPopUpState: (state, action) => {
            state.PopUpState = action.payload;
            state.loading = false;
        },
        setData: (state, action) => {
            state.Vessels.push(action.payload);
            state.loading = false;
            state.VesselState = null;
        },
        setVoyageParameterRow: (state, action) => {
            state.VoyageParameterRow.push(action.payload);
        },
        removeVoyageParameterRow: (state, action) => {
            const { machinery_name } = action.payload;
            state.VoyageParameterRow = state.VoyageParameterRow.filter((item: any) => item.machinery_name !== machinery_name);
        },
        setEnergyParameterRow: (state, action) => {
            state.EnergyParameterRow.push(action.payload);
        },
        removeEnergyParameterRow: (state, action) => {
            const { machinery_name } = action.payload;
            state.EnergyParameterRow = state.EnergyParameterRow.filter((item: any) => item.machinery_name !== machinery_name);
        },
        setSpecialOpsEnergyRow: (state, action) => {
            state.SpecialOpsEnergyRow.push(action.payload);
        },
        removeSpecialOpsEnergyRow: (state, action) => {
            state.SpecialOpsEnergyRow.splice(action.payload, 1);
        },
        clearSpecialOpsEnergyRow: (state) => {
            state.SpecialOpsEnergyRow.length = 0;
        },
        setSpecialOperationEnergyAction: (state, action) => {
            state.specialOperationEnergyAction = action.payload;
        },
        setEnergyMeterMachinery: (state, action) => {
            state.EnergyMeterMachinery.push(action.payload);
        },
        removeEnergyMeterMachinery: (state, action) => {
            state.EnergyMeterMachinery.splice(action.payload, 1);
        },
        setEnergyMeterMachineryId: (state, action) => {
            state.EnergyMeterMachineryId.push(action.payload);
        },
        removeEnergyMeterMachineryId: (state, action) => {
            state.EnergyMeterMachinery.splice(action.payload, 1);
        },
        setSpecialOpsEnergy: (state, action) => {
            state.SpecialOpsEnergy = action.payload
        },
        setFifteenDays: (state, action) => {
            state.fifteenDays = action.payload
        },
        setThirtyDays: (state, action) => {
            state.thirtyDays = action.payload
        },
        setNinetyDays: (state, action) => {
            state.ninetyDays = action.payload
        },
        setSteamingTime: (state, action) => {
            state.steamingTime = action.payload
        },
        setGeneralSettings: (state, action) => {
            state.GeneralSettings.push(action.payload);
        },
        setBatchConsumption: (state, action) => {
            state.BatchConsumption.push(action.payload);
        },
        removeBatchConsumption: (state, action) => {
            state.BatchConsumption = state.BatchConsumption.splice(action.payload, 1);
        },
        setFuelDebunkering: (state, action) => {
            state.FuelDebunkering.push(action.payload);
        },
        removeFuelDebunkering: (state, action) => {
            const { bunkering } = action.payload;
            state.FuelDebunkering = state.FuelDebunkering.filter((item: any) => item.bunkering !== bunkering);
        },
        setFourHrTbl: (state, action) => {
            state.FourHrTbl.push(action.payload);
        },
        removeFourHrTbl: (state, action) => {
            //state.FourHrTbl.splice(action.payload, 1);
            const { period, vessel_machinery_name } = action.payload;
            state.FourHrTbl = state.FourHrTbl.filter((item: any) => item.period !== period && item.vessel_machinery_name !== vessel_machinery_name);
        },
        setGeneralSettingsRow: (state, action) => {
            state.GeneralSettRow.push(action.payload);
        },
        removeGeneralSettingsRow: (state, action) => {
            if (action.payload) {
                const { vessel_machinery_name } = action.payload;
                state.GeneralSettRow = state.GeneralSettRow.filter((item: any) => item.vessel_machinery_name !== vessel_machinery_name);
            }
        },
        setSfocRow: (state, action) => {
            state.SfcoRow.push(action.payload);
        },
        removeSfocRow: (state, action) => {
            const { vessel_machinery_name } = action.payload;
            state.SfcoRow = state.SfcoRow.filter((item: any) => item.vessel_machinery_name !== vessel_machinery_name);
        },
        setEquivalentBatchConsumption: (state, action) => {
            state.EquivalentBatchConsumption.push(action.payload);
        },
        removeEquivalentBatchConsumption: (state, action) => {
            state.EquivalentBatchConsumption.splice(action.payload, 1);
        },
        clearEquivalentBatchConsumption: (state) => {
            state.EquivalentBatchConsumption.length = 0;
        },
        setMainEngineRow: (state, action) => {
            state.MainEngineRow.push(action.payload);
        },
        removeMainEngineRow: (state, action) => {
            state.MainEngineRow.splice(action.payload, 1);
        },
        setBatchROB: (state, action) => {
            state.BatchROB.push(action.payload);
        },
        removeBatchROB: (state, action) => {
            state.BatchROB.splice(action.payload, 1);
        }
    }
});

export const {
    apiRequested,
    apiRequestedFailed,
    setVesselState,
    setVesselID,
    setVoyageID,
    setReportID,
    getVessels,
    getVoyages,
    getReports,
    setVoyageParameterRow,
    removeVoyageParameterRow,
    setEnergyParameterRow,
    removeEnergyParameterRow,
    setSpecialOpsEnergyRow,
    removeSpecialOpsEnergyRow,
    clearSpecialOpsEnergyRow,
    setSpecialOperationEnergyAction,
    setEnergyMeterMachinery,
    removeEnergyMeterMachinery,
    setEnergyMeterMachineryId,
    removeEnergyMeterMachineryId,
    setPopUpState,
    setSpecialOpsEnergy,
    setFifteenDays,
    setThirtyDays,
    setNinetyDays,
    setSteamingTime,
    setGeneralSettings,
    setBatchConsumption,
    removeBatchConsumption,
    setFuelDebunkering,
    removeFuelDebunkering,
    setFourHrTbl,
    removeFourHrTbl,
    setGeneralSettingsRow,
    removeGeneralSettingsRow,
    setSfocRow,
    removeSfocRow,
    setEquivalentBatchConsumption,
    removeEquivalentBatchConsumption,
    clearEquivalentBatchConsumption,
    setMainEngineRow,
    removeMainEngineRow,
    setBatchROB,
    removeBatchROB
} = ReportingSlice.actions;
export default ReportingSlice.reducer;

// Action functions for generic forms
export const getVesselsAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getVessels.type,
        onError: apiRequestedFailed.type,
    }
)

export const getVoyagesAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getVoyages.type,
        onError: apiRequestedFailed.type,
    }
)

export const getReportsAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: getReports.type,
        onError: apiRequestedFailed.type,
    }
)

export const setVoyageParameterRowAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: setVoyageParameterRow.type,
        onError: apiRequestedFailed.type,
    }
)

export const setEnergyParameterRowAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: setEnergyParameterRow.type,
        onError: apiRequestedFailed.type,
    }
)

export const setSpecialOpsRowAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: setSpecialOpsEnergyRow.type,
        onError: apiRequestedFailed.type,
    }
)

export const setGeneralSettingsRowAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: setGeneralSettings.type,
        onError: apiRequestedFailed.type,
    }
)

export const setBatchConsumptionRowAction = (url: string) => apiCall(
    {
        url,
        onStart: apiRequested.type,
        onSuccess: setBatchConsumption.type,
        onError: apiRequestedFailed.type,
    }
)