import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    Label,
    Button,
    Form,
    FormFeedback,
    FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import apiGlobal from "../../global/api.global";
import { successToast } from "../../Components/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "index";

const VesselPreviousNames = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [data, setData] = useState({
        vessel_previous_name: "",
        date_of_change: "",
        vessel: VesselID,
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            vessel_previous_name: "",
            date_of_change: "",
            vessel: "",
        },
        validationSchema: Yup.object({
            vessel_previous_name: Yup.string()
                .matches(/^[A-Za-z ]{1,50}$/, "Please enter upto 50 alphabets only")
                .required("Please enter the vessel's previous name"),
            date_of_change: Yup.string().required("Please enter the Date of change"),
        }),
        onSubmit: () => { },
    });

    const handleChanges = (event: any, validation: any) => {
        validation.handleChange(event);
        handle(event);
    };

    function handle(e: any) {
        const newdata: any = { ...data };
        newdata[e.target.name] = e.target.value;
        setData(newdata);
    }

    const datas = {
        vessel_previous_name: data.vessel_previous_name,
        date_of_change: data.date_of_change,
        vessel: data.vessel,
    };

    function submit() {
        apiGlobal
            .post(`/vessel_particular/`, datas)
            .then(() => {
                successToast("Data saved successfully!");
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <Card>
                <CardBody>
                    <Form
                        className="needs-validation"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            submit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={4}>
                                <div className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="vessel_previous_name"
                                        name="vessel_previous_name"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={
                                            validation.values.vessel_previous_name ||
                                            data.vessel_previous_name
                                        }
                                        invalid={
                                            validation.touched.vessel_previous_name &&
                                                validation.errors.vessel_previous_name
                                                ? true
                                                : false
                                        }
                                        autoFocus
                                    />
                                    {validation.touched.vessel_previous_name &&
                                        validation.errors.vessel_previous_name ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.vessel_previous_name}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="vessel_previous_name" className="asteric">
                                        Vessel previous name(s)
                                    </Label>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <FormGroup className="mb-3">
                                    <Label className="mb-0 w_8">Date of changes</Label>
                                    <br></br>
                                    <input
                                        type="date"
                                        name="date_of_change"
                                        value={data.date_of_change}
                                        onChange={handle}
                                        className="datepicker text-uppercase"
                                    ></input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                    onClick={submit}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                >
                                    Reset
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default VesselPreviousNames;
