import React from 'react';
import { Popover, PopoverBody, Button } from 'reactstrap';

const PopOver = ({
    target,
    handleClick,
    message,
    state,
    setState
}: any) => {
    /** Open close popover */
    function handlePopoverState() {
        setState(!state)
    }

    return (
        <>
            <Popover
                placement="top"
                isOpen={state}
                target={target}
                toggle={() => {
                    handlePopoverState();
                }}
            >
                <PopoverBody>
                    {message}<br />
                    <Button type="button" color="primary" className='mr-2 mt-3 popover_btn' onClick={handleClick}
                    >Yes</Button>
                    <Button type="button" color="danger" className='mt-3' onClick={handlePopoverState}>No</Button>
                </PopoverBody>
            </Popover>
        </>
    )
}

export default PopOver