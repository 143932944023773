import React from 'react'
import Select from 'react-select'
import { Col, Input, Label, Row } from 'reactstrap'
import { customStyle } from 'shared/CommonCSS'
import { Link } from 'react-router-dom'
import { complainceBalanceTable } from './Pooling'

const CreatePool = ({
    years,
    setCreateBool
}: any) => {
    return (
        <React.Fragment>
            <Row className='mb-2'>
                <Col sm={4}>
                    <Label className='mb-0 asteric'>Pool name</Label>
                    <Input
                        name='pool_name'
                        id='pool_id'
                        className='form-control'
                    />
                </Col>
                <Col sm={{ size: 4, offset: 4 }}>
                    <Label className="mb-0">Select year</Label>
                    <Select
                        options={years}
                        getOptionLabel={(option: any) => option.year}
                        getOptionValue={(option: any) => option.id}
                        menuPortalTarget={document.body}
                        styles={customStyle}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={5} className='pos-center'>
                    <div className='border border-secondary p-2 pool-vessel-card'>
                        {complainceBalanceTable?.filter((item: any, indx: number) => indx > 2)?.map((vessel: any) => {
                            return (
                                <React.Fragment>
                                    <Label className='text-break mb-2'>
                                        {vessel.vessel}
                                    </Label>
                                    <hr className='mb-1 mt-1' />
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                </Col>
                <Col sm={1}>
                    <i className='bx bx-right-arrow-alt icon_s28 vertically-center' />
                    <i className='bx bx-left-arrow-alt icon_s28 vertically-center mt-4' />
                </Col>
                <Col sm={5} className='pos-center'>
                    <div className='border border-secondary p-2 pool-vessel-card'>
                        {complainceBalanceTable?.filter((item: any, indx: number) => indx <= 2)?.map((vessel: any) => {
                            return (
                                <React.Fragment>
                                    <Label className='text-break mb-2'>
                                        {vessel.vessel}
                                    </Label>
                                    <hr className='mb-1 mt-1' />
                                </React.Fragment>
                            )
                        })
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Link
                        to="/pooling_vessels"
                        className="btn btn-primary justify_right mt-3"
                        onClick={() => {
                            setCreateBool(false);
                        }}
                    >
                        Next
                    </Link>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default CreatePool