import React, { useEffect, useState } from "react";
import { VesselState, setVesselState } from "../Store/Generic/ReportingSlice";
import {
  Col,
  Container,
  Row,
  Input,
  Label,
  Button,
  Form,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import Layout from "../HorizontalMenu/Menu";
import Navbar from "../HorizontalMenu/Navbar";
import VesselDetailsHeader from "../Components/VesselDetailsHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "..";
import { Field, FieldProps, Formik } from "formik";
import * as Yup from "yup";
import ToolTip from "Components/ToolTip";
import { TooltipMsg } from "Components/ToolTipMessage";
import Cleave from "cleave.js/react";
import {
  loadAdditionalEvents,
  loadCurrentYear,
  loadLoadConditionOptions,
  loadStraitCanalOptions,
  loadVesselReportObject,
  nextPossibleEvents,
  nextPossibleOperations,
} from "VesselMaster/vesselMaster.hooks";
import { customStyle } from "shared/CommonCSS";
import { useQuery } from "react-query";
import { queryKeyes } from "shared/queryKeys";
import {
  EventPrecedence,
  LoadingDischargingOps,
  ManeouveringTimeLimit,
  ReportingOpConstant,
  ReportingTypeConstant,
  VesselTypeConstant,
} from "shared/constants";
import apiGlobal from "global/api.global";
import { errorToast, successToast } from "Components/Toasts";
import {
  calculateLocalTime,
  calculateTimeDurationBetwnReports,
  checkInvalidPrimaryKey,
  errResponse,
  filterUniqueByFields,
} from "GenericForms/Helper";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import Loading from "Components/Loading";
import ErrorComponent from "Components/ErrorComponent";
import { queryClient } from "react-query/queryClient";
import ErrorTooltip from "Components/ErrorTooltip";
import env from "environment/env";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import ThreeStateSwitch from "Components/ThreeStateSwitch";

interface CreateVesselReportType {
  reportId?: number,
}

const CreateVesselReport = ({
  reportId,
}: CreateVesselReportType) => {
  /** State variables */
  const dispatch = useDispatch();
  const { VesselState, Voyages, VoyageID, VesselID, Vessels } = useSelector(
    (state: RootState) => state.Reporting
  );
  const voyage = Voyages.find((rec: any) => rec.id === VoyageID);
  const [reportingType, setReportingType] = useState("");
  let [lastRecord, setLastRecord] = useState<any>({});
  const vessel = Vessels.find((rec: any) => rec.id === VesselID);
  const [localTime, setLocalTime] = useState("");
  const [count, setCount] = useState(0);
  /** State variables end */

  /** useEffect start */
  useEffect(() => {
    const loadLastRecord = async () => {
      if (VesselState === "CREATE_VESSEL_REPORTING" && checkInvalidPrimaryKey(VesselID)) {
        let url: string;
        if (checkInvalidPrimaryKey(reportId)) {
          url = `/vessel_reporting_information/get_previous_by_id/?id=${reportId}&vessel_id=${VesselID}`;
        } else {
          url = `/vessel_reporting_information/get_last_record_by_vessel/?vessel_id=${VesselID}`;
        }
        await apiGlobal
          .get(url)
          .then((res) => {
            setLastRecord(res.data);
            return res.data;
          })
          .catch((err) => {
            if (errResponse.includes(err.response.status)) {
              errorToast("Internal error occured, please contact the admin");
            }
          });
      }
    };
    loadLastRecord();
  }, [VesselID, reportId, VesselState]);
  useEffect(() => {
    setCount(count + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VesselID, lastRecord])
  /** useEffect end */

  /** Queries start */
  /** Vessel report Object used for edit */
  const {
    data: VesselReportObject,
    isLoading: VesselReportObjectLoading,
    isError: VesselReportObjectError,
  }: { data: any; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.VesselReportingObject.key, checkInvalidPrimaryKey(reportId) && reportId],
    async () => {
      return await loadVesselReportObject(checkInvalidPrimaryKey(reportId) && reportId);
    },
    { staleTime: Infinity }
  );
  /** Load next possible events */
  const useNextPossibleEventQuery = (eventId: number | undefined) => {
    if (VesselReportObject && VesselReportObject?.id > 0) {
      eventId = VesselReportObject.previous_event_other_than_noon;
    }
    return useQuery(
      [queryKeyes.vessel.nextReportingEvent, eventId],
      async () => {
        if (eventId) {
          setCount(count + 1);
          return await nextPossibleEvents(eventId);
        }
        return { id: 0, event: "No Records found" };
      },
      {
        staleTime: Infinity,
      }
    );
  };
  const knowLastEvent = () => {
    return lastRecord.event_precedence_id === EventPrecedence.NOON ||
      lastRecord.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT
      ? lastRecord?.previous_event_other_than_noon
      : lastRecord?.reporting_event;
  };

  const NextAdditionalEvents = (eventId: number) => {
    if (VesselReportObject && VesselReportObject?.id > 0) {
      eventId = VesselReportObject.previous_event_other_than_noon;
    }
    return useQuery(
      [queryKeyes.vessel.CIIAdjustmentAdditionalEvents, eventId],
      async () => {
        if (eventId) {
          return await loadAdditionalEvents(eventId);
        }
        return { id: 0, event: "No Records found" };
      },
      {
        staleTime: Infinity,
      }
    );
  };

  const {
    data: additionalEvents,
    isLoading: additionalEventsIsLoading,
    isError: additionalEventsIsError,
  } = NextAdditionalEvents(knowLastEvent());

  const {
    data: reportingEvent,
    isLoading: reportingEventIsLoading,
    isError: reportingEventIsError,
  } = useNextPossibleEventQuery(knowLastEvent());

  /** Load current year object from master */
  const { data: currentYear, isLoading: currentYearLoading, isError: currentYearError }:
    { data: any, isLoading: any, isError: any } = useQuery(
      [queryKeyes.vessel.CurrentYear.key],
      async () => {
        return await loadCurrentYear();
      },
      { staleTime: Infinity }
    );
  /** Queries end */

  /** Assign initial values to the formik object */
  const getInitialVessselReportData = () => {
    if (VesselReportObject !== undefined && VesselReportObject.id > 0) {
      return VesselReportObject;
    } else {
      return {
        reporting_time_utc: "",
        time_duration_since_last_report: "",
        time_difference_prefix: "-",
        reporting_time_difference: null,
        reporting_type: "",
        reporting_event: null,
        year: currentYear && currentYear?.id,
        reporting_operation_mode: 0,
        reporting_operation_mode_name: "",
        is_drifting_inolved: false,
        position_lat_deg: 0.0,
        position_lat_min: 0.0,
        position_lat_sec: 0.0,
        position_long_deg: 0.0,
        position_long_min: 0.0,
        position_long_sec: 0.0,
        lat_direction: "south",
        long_direction: "west",
        strait_canal_transit: null,
        no_of_hours_retarded: null,
        remaining_distance_to_the_next_port: 0,
        load_condition: null,
        is_noon_report: false,
        is_cii_adjustment_report: false,
        only_noon_or_combine_with_other_event: "none",
        cii_combine_with_other_event: false,
        cii_noon_combine_with_other_event: false,
        additional_events: false,
        is_strait_canal_transit: false,
        previous_event_other_than_noon: 0,
        previous_operation: null,
        is_this_cargo_loading_discharging: null,
        is_sts_considered: false,
        vessel: VesselID,
        voyage_information: VoyageID,
      };
    }
  };

  /** Load reports based on vessel & voyage */
  const loadReports = async () => {
    try {
      const response = await apiGlobal.get(
        `/vessel_reporting_information/get_reporting_info_same_vessel_and_voyage/?vessel_name_id=${VesselID}&voyage_information_id=${VoyageID}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  /** Set vessel globally */
  const handleVesselState = (record: VesselState) => {
    dispatch(setVesselState(record));
  };

  /** Load applicable additional events */
  const loadNextAdditionalEvents = async () => {
    try {
      if (additionalEvents) {
        let uniqueAdditionalEvents: any[] = [];
        uniqueAdditionalEvents =
          additionalEvents[0].next_possible_events.next_possible_events.filter(
            (item: any) =>
              item.event_precedence_id !== EventPrecedence.NOON &&
              item.event_precedence_id !== EventPrecedence.CII_ADJUSTMENT_EVENT
          );
        reportingEvent[0].next_possible_events.next_possible_events
          .filter(
            (item: any) =>
              item.event_precedence_id !== EventPrecedence.NOON &&
              item.event_precedence_id !== EventPrecedence.CII_ADJUSTMENT_EVENT
          )
          .map((events: any) => {
            uniqueAdditionalEvents.push(events);
            return "";
          });
        return filterUniqueByFields(uniqueAdditionalEvents, ['id']);
      }
      return [];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  /** Load next possible events without CII adjustment event */
  const loadReportEventOptionsWithoutCII = async () => {
    try {
      if (reportingEvent) {
        return reportingEvent[0].next_possible_events.next_possible_events.filter(
          (item: any) =>
            item.event_precedence_id !== EventPrecedence.NOON &&
            item.event_precedence_id !== EventPrecedence.CII_ADJUSTMENT_EVENT
        );
      }
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  /** Calculate steaming time */
  const handleReportingDateTimeChanges = (e: any, form: any, values: any) => {
    setLocalTime(
      calculateLocalTime(
        values?.reporting_time_utc,
        values?.reporting_time_difference
      )
    );
    form.setFieldValue("reporting_time_utc", e.target.value);
    const dt1 = new Date(lastRecord?.reporting_time_utc);
    const dt2 = new Date(`${e.target.value}:00Z`);
    let steamingTime = Math.floor(
      (Date.UTC(
        dt2.getFullYear(),
        dt2.getUTCMonth(),
        dt2.getUTCDate(),
        dt2.getUTCHours(),
        dt2.getUTCMinutes(),
        dt2.getUTCSeconds()
      ) -
        Date.UTC(
          dt1.getFullYear(),
          dt1.getUTCMonth(),
          dt1.getUTCDate(),
          dt1.getUTCHours(),
          dt1.getUTCMinutes(),
          dt1.getUTCSeconds()
        )) /
      (1000 * 60)
    );
    steamingTime = !isNaN(steamingTime) ? steamingTime : 0;
    form.setFieldValue("time_duration_since_last_report", steamingTime);
  };

  /** Set current operation & previous operation  */
  const loadNextPossibleOperations = async (
    currEvent: any,
    form: any,
    values: any
  ) => {
    try {
      if (lastRecord.event_precedence_id === EventPrecedence.NOON) {
        form.setFieldValue(
          "reporting_operation_mode",
          lastRecord.reporting_operation_mode
        );
        form.setFieldValue(
          "reporting_operation_mode_name",
          lastRecord.reporting_operation_mode_name
        );
        form.setFieldValue(
          "previous_operation",
          lastRecord.reporting_operation_mode
        );
      } else {
        const nextPossibleOperation = await nextPossibleOperations(
          currEvent,
          lastRecord.reporting_event
        );
        form.setFieldValue(
          "reporting_operation_mode",
          nextPossibleOperation[0]?.possible_operations_slr
        );
        form.setFieldValue(
          "reporting_operation_mode_name",
          nextPossibleOperation[0]?.possible_operations_slr_name
        );
        if (
          values.only_noon_or_combine_with_other_event ===
          "combine_with_other_report" ||
          values.is_noon_report === false
        ) {
          form.setFieldValue(
            "previous_operation",
            nextPossibleOperation[0].possible_operations_slr
          );
        } else {
          form.setFieldValue(
            "previous_operation",
            lastRecord.reporting_operation_mode
          );
        }

        return nextPossibleOperation[0];
      }
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /** Changes based on whether the report is noon, not noon or combined */
  const handleIsNoonReportSwitchChanges = (
    e: any,
    fieldName: any,
    form: any,
    values: any
  ) => {
    form.setFieldValue(fieldName, e.target.checked);
    form.setFieldValue(
      "only_noon_or_combine_with_other_event",
      e.target.checked === true ? "only_noon_report" : "none"
    );
    if (
      (e.target.checked === true &&
        values?.is_cii_adjustment_report === true &&
        values?.cii_noon_combine_with_other_event === false) ||
      (e.target.checked === true && values?.is_cii_adjustment_report === false)
    ) {
      form.setFieldValue(
        "reporting_event",
        reportingEvent[0]?.next_possible_events?.next_possible_events.filter(
          (item: any) => item?.event_precedence_id === EventPrecedence.NOON
        )[0]?.id
      );
    } else if (
      e.target.checked === false &&
      values?.is_cii_adjustment_report === true &&
      values?.cii_combine_with_other_event === false
    ) {
      form.setFieldValue(
        "reporting_event",
        reportingEvent[0]?.next_possible_events?.next_possible_events.filter(
          (item: any) =>
            item?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT
        )[0].id
      );
    }
    setReportingType(
      reportingEvent[0]?.next_possible_events?.next_possible_events.filter(
        (item: any) => item?.event_precedence_id === EventPrecedence.NOON
      )[0]?.reporting_type_name
    );
    if (
      lastRecord.event_precedence_id === EventPrecedence.NOON &&
      e.target.checked === true
    ) {
      form.setFieldValue(
        "reporting_operation_mode",
        lastRecord?.reporting_operation_mode
      );
      form.setFieldValue(
        "reporting_operation_mode_name",
        lastRecord?.reporting_operation_mode_name
      );
      form.setFieldValue(
        "previous_operation",
        lastRecord?.reporting_operation_mode
      );
    } else {
      loadNextPossibleOperations(
        reportingEvent[0]?.next_possible_events?.next_possible_events.filter(
          (item: any) => item?.event_precedence_id === EventPrecedence.NOON
        )[0]?.id,
        form,
        values
      );
    }
    if (
      lastRecord?.event_precedence_id === EventPrecedence.NOON ||
      lastRecord?.event_precedence_id === EventPrecedence.CII_ADJUSTMENT_EVENT
    ) {
      form.setFieldValue(
        "previous_event_other_than_noon",
        lastRecord?.previous_event_other_than_noon
      );
    } else {
      form.setFieldValue(
        "previous_event_other_than_noon",
        lastRecord?.reporting_event
      );
    }
  };

  /** Changes based on which event is select */
  const handleReportingEventDropdownChanges = (
    e: any,
    form: any,
    values: any
  ) => {
    if (e?.last_operation_name && e?.last_operation_id) {
      form.setFieldValue("reporting_operation_mode", e?.last_operation_id);
      form.setFieldValue(
        "reporting_operation_mode_name",
        e?.last_operation_name
      );
    }
    form.setFieldValue("reporting_event", e.id);
    loadNextPossibleOperations(e.id, form, values);
    if (values.additional_events === false) {
      let indx =
        reportingEvent[0].next_possible_events.next_possible_events.indexOf(e);
      setReportingType(
        reportingEvent[0].next_possible_events.next_possible_events[indx]
          .reporting_type_name
      );
    } else {
      let indx =
        additionalEvents[0].next_possible_events.next_possible_events.indexOf(
          e
        );
      setReportingType(
        additionalEvents[0].next_possible_events.next_possible_events[indx]
          .reporting_type_name
      );
    }
    form.setFieldValue("reporting_event", e.id);
    if (lastRecord.event_precedence_id === EventPrecedence.NOON) {
      form.setFieldValue(
        "previous_event_other_than_noon",
        lastRecord.previous_event_other_than_noon
      );
    } else {
      form.setFieldValue(
        "previous_event_other_than_noon",
        lastRecord.reporting_event
      );
    }
  };

  /** Calculate number of hours retarded/advanced */
  const handleTimeDifferenceTextboxChanges = (
    e: any,
    form: any,
    values: any
  ) => {
    setLocalTime(
      calculateLocalTime(
        values?.reporting_time_utc,
        values?.reporting_time_difference
      )
    );
    if (values.time_difference_prefix === undefined) {
      form.setFieldValue("time_difference_prefix", "-");
    }
    form.setFieldValue(
      "reporting_time_difference",
      `${values.time_difference_prefix}${e.target.value}`
    );

    /** Calculation of number of hours retarded/advanced */
    let lastReportingTimeDifference: any;
    let currentReportingTimeDifference: any;
    if (
      lastRecord.reporting_time_difference === "-00:00" ||
      lastRecord.reporting_time_difference === "+00.00"
    ) {
      lastReportingTimeDifference = 0;
    } else {
      if (lastRecord) {
        lastReportingTimeDifference =
          parseInt(
            lastRecord?.reporting_time_difference?.toString().substring(1, 3)
          ) +
          parseInt(
            lastRecord?.reporting_time_difference?.toString().substring(4, 6)
          ) /
          60;
      }
    }
    currentReportingTimeDifference =
      parseInt(e.target.value.toString().substring(0, 2)) +
      parseInt(e.target.value.toString().substring(3, 5)) / 60;
    let timeDifference =
      parseFloat(
        `${VesselReportObject && VesselReportObject?.id > 0
          ? VesselReportObject?.reporting_time_difference
            ?.toString()
            .substring(0, 1)
          : values?.time_difference_prefix
        }${currentReportingTimeDifference}`
      ) -
      parseFloat(
        `${lastRecord?.reporting_time_difference
          ?.toString()
          .substring(0, 1)}${lastReportingTimeDifference}`
      );
    if (!Number.isNaN(timeDifference)) {
      if (timeDifference > 0) {
        form.setFieldValue(
          "no_of_hours_retarded",
          parseFloat(`+${timeDifference.toFixed(2)}`)
        );
      } else if (timeDifference < 0) {
        form.setFieldValue("no_of_hours_retarded", timeDifference.toFixed(2));
      } else {
        form.setFieldValue("no_of_hours_retarded", parseInt(`+${0}`));
      }
    }
  };

  /** Handle time difference(retarding/advancing) */
  const handleTimePrefixSwitchChanges = (e: any, form: any, values: any) => {
    setLocalTime(
      calculateLocalTime(
        values?.reporting_time_utc,
        values?.reporting_time_difference
      )
    );
    let sign = e.target.checked === true ? "+" : "-";
    form.setFieldValue("time_difference_prefix", sign);
    if (values.reporting_time_difference !== "") {
      form.setFieldValue(
        "reporting_time_difference",
        `${sign}${values?.reporting_time_difference?.toString()?.substring(1, 5)}`
      );
    }
  };

  /** Changes based on whether this report is the beginning of CII adjustment event  */
  const handleIsCIIReportSwitchChanges = (
    e: any,
    form: any,
    values: any,
    field: any
  ) => {
    form.setFieldValue(field.name, e.target.checked);
    if (
      e.target.checked === true &&
      (values.cii_combine_with_other_event === false ||
        values.cii_noon_combine_with_other_event === false) &&
      values.additional_events === false
    ) {
      form.setFieldValue(
        "reporting_operation_mode",
        lastRecord.reporting_operation_mode
      );
      form.setFieldValue(
        "reporting_operation_mode_name",
        lastRecord.reporting_operation_mode_name
      );
      setReportingType(lastRecord.reporting_type);
    }
  };

  const CreateVesselReportFormik = {
    initialvalues: getInitialVessselReportData(),
    validationSchema: Yup.object().shape({
      reporting_time_utc: Yup.string().required(
        commonValidationMessages.required
      ),
      time_duration_since_last_report: Yup.string(),
      reporting_time_difference: Yup.string().required(
        commonValidationMessages.required
      ),
      reporting_event: Yup.number().required(commonValidationMessages.required),
      reporting_operation_mode: Yup.number().required(
        commonValidationMessages.required
      ),
      position_lat_deg: Yup.number()
        .min(0, commonValidationMessages.minLatDegree)
        .max(360, commonValidationMessages.maxLatDegree)
        .required(commonValidationMessages.required),
      position_lat_min: Yup.number()
        .min(0, commonValidationMessages.minMinutes)
        .max(60, commonValidationMessages.maxMinutes)
        .required(commonValidationMessages.required),
      position_lat_sec: Yup.number()
        .min(0, commonValidationMessages.minSecond)
        .max(60, commonValidationMessages.maxSecond)
        .required(commonValidationMessages.required),
      position_long_deg: Yup.number()
        .min(0, commonValidationMessages.minLogDegree)
        .max(360, commonValidationMessages.maxLogDegree)
        .required(commonValidationMessages.required),
      position_long_min: Yup.number()
        .min(0, commonValidationMessages.minMinutes)
        .max(60, commonValidationMessages.maxMinutes)
        .required(commonValidationMessages.required),
      position_long_sec: Yup.number()
        .min(0, commonValidationMessages.minSecond)
        .max(60, commonValidationMessages.maxSecond)
        .required(commonValidationMessages.required),
      lat_direction: Yup.string(),
      long_direction: Yup.string(),
      strait_canal_transit: Yup.number().nullable(),
      no_of_hours_retarded: Yup.string().required(commonValidationMessages.required),
      remaining_distance_to_the_next_port: Yup.number(),
      load_condition: Yup.number().required(commonValidationMessages.required),
      is_noon_report: Yup.boolean(),
      is_this_cargo_loading_discharging: Yup.string().when("reporting_operation_mode", {
        is: (reporting_operation_mode: any) =>
        (((vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER ||
          vessel?.vessel_type === VesselTypeConstant.TANKER ||
          vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER) &&
          LoadingDischargingOps?.includes(reporting_operation_mode)) ||
          (!(vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER ||
            vessel?.vessel_type === VesselTypeConstant.TANKER ||
            vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER) &&
            reportingType === ReportingTypeConstant.INPORT)),
        then: (schema: any) =>
          schema.required(commonValidationMessages.selectYesOrNo),
        otherwise: (schema: any) => schema.nullable(),
      }),
      only_noon_or_combine_with_other_event: Yup.string(),
      is_strait_canal_transit: Yup.boolean(),
      previous_event_other_than_noon: Yup.number(),
      previous_operation: Yup.number(),
      vessel: Yup.number(),
      voyage_information: Yup.number(),
    }),
  };

  return (
    <React.Fragment>
      {(VesselState === "CREATE_VESSEL_REPORTING" ||
        VesselState === "EDIT") && (
          <>
            <Layout children={Navbar} />
            <div className="page-content">
              <Container fluid>
                <Card>
                  <CardHeader className="pt-0 pb-0">
                    <Row>
                      <Col sm={2} className="d-flex align-items-center">
                        <button
                          color="primary"
                          className="btn btn-primary"
                          onClick={() => {
                            handleVesselState("VESSEL_REPORTING");
                          }}
                        >
                          <i className="bx bx-chevron-left me-1" />
                          Back
                        </button>
                      </Col>
                      <Col sm={10}>
                        <VesselDetailsHeader />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col lg={4}>
                        <h4 className="mb-4">Vessel Reporting</h4>
                      </Col>
                      {localTime !== "" && localTime !== null && (
                        <Col
                          lg={{ size: 3, offset: 5 }}
                          className="d-flex justify-content-end"
                        >
                          <Label>Local time - </Label>
                          <strong>{localTime}</strong>
                        </Col>
                      )}
                    </Row>
                    {(reportingEventIsLoading ||
                      VesselReportObjectLoading ||
                      additionalEventsIsLoading ||
                      currentYearLoading) && (
                        <Loading message="Loading required data!" />
                      )}
                    {(reportingEventIsError ||
                      VesselReportObjectError ||
                      additionalEventsIsError ||
                      currentYearError) && (
                        <ErrorComponent message="Unable to load required data!" />
                      )}
                    {!(
                      reportingEventIsLoading ||
                      VesselReportObjectLoading ||
                      additionalEventsIsLoading
                    ) &&
                      !(
                        reportingEventIsError ||
                        VesselReportObjectError ||
                        additionalEventsIsLoading
                      ) && (
                        <Formik
                          initialValues={CreateVesselReportFormik.initialvalues}
                          validationSchema={
                            CreateVesselReportFormik.validationSchema
                          }
                          onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            if (
                              VesselReportObject &&
                              VesselReportObject.id > 0 &&
                              reportId
                            ) {
                              apiGlobal
                                .put(
                                  `/vessel_reporting_information/${reportId}/`,
                                  values
                                )
                                .then(() => {
                                  queryClient.invalidateQueries(
                                    queryKeyes.vessel.VesselReportingObject.key
                                  );
                                  handleVesselState("VESSEL_REPORTING");
                                  loadReports();
                                  successToast("Vessel report updated!");
                                })
                                .catch((err) => {
                                  if (errResponse.includes(err.response.status)) {
                                    errorToast(
                                      "Internal error occured, please contact the admin"
                                    );
                                  }
                                });
                            } else {
                              try {
                                apiGlobal
                                  .post(`/vessel_reporting_information/`, values)
                                  .then(() => {
                                    queryClient.invalidateQueries(
                                      queryKeyes.vessel.VesselReportingObject.key
                                    );
                                    handleVesselState("VESSEL_REPORTING");
                                    loadReports();
                                    successToast("New vessel report created!");
                                  })
                                  .catch((err) => {
                                    if (
                                      errResponse.includes(err?.response?.status)
                                    ) {
                                      errorToast(
                                        "Internal error occured, please contact the admin"
                                      );
                                    }
                                  });
                              } catch (err) {
                                console.error(err);
                                return null;
                              }
                            }
                          }}
                        >
                          {({
                            values,
                            errors,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            touched,
                            resetForm,
                          }) => (
                            <Form
                              autoComplete="off"
                              onSubmit={handleSubmit}
                              noValidate
                            >
                              <Row>
                                <Col lg={4}>
                                  <FormGroup className="form-floating mb-3">
                                    <div className="mb-3">
                                      <Label className="asteric">
                                        Voyage number
                                        <i
                                          className="bx bx-info-circle ml-2p"
                                          id="voyage_number_msg"
                                        ></i>
                                      </Label>
                                      <br></br>
                                      <ToolTip
                                        target="voyage_number_msg"
                                        message={`${TooltipMsg.CreateReport.filter(
                                          (item: any) =>
                                            item.target === "voyage_number_msg"
                                        ).map((tool: any) => {
                                          return tool.message;
                                        })}`}
                                      />
                                      <h5>{voyage && voyage.voyage_number}</h5>
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <Label
                                    className="mb-0 asteric"
                                    for="position_lat_deg"
                                  >
                                    Vessel position (Latitude)
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="position_lat_deg_lbl"
                                    ></i>
                                  </Label>
                                  <ToolTip
                                    target="position_lat_deg_lbl"
                                    message={`${TooltipMsg.CreateReport.filter(
                                      (item: any) =>
                                        item.target === "position_lat_deg_lbl"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <div className="d-flex gap-1">
                                    <div className="mb-3">
                                      <Field
                                        type="text"
                                        className="form-control text-right max-width-4"
                                        id="position_lat_deg"
                                        name="position_lat_deg"
                                        placeholder="degrees"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      {errors.position_lat_deg &&
                                        touched.position_lat_deg &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`position_lat_deg`}
                                            message={errors?.position_lat_deg}
                                            open={
                                              errors?.position_lat_deg
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                    <div className="mb-3">
                                      <Field
                                        type="text"
                                        className="form-control text-right max-width-4"
                                        id="position_lat_min"
                                        name="position_lat_min"
                                        placeholder="minutes"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      {errors.position_lat_min &&
                                        touched.position_lat_min &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={"position_lat_min"}
                                            message={errors.position_lat_min}
                                            open={
                                              errors.position_lat_min
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                    <div className="mb-3">
                                      <Field
                                        type="text"
                                        className="form-control text-right max-width-4"
                                        id="position_lat_sec"
                                        name="position_lat_sec"
                                        placeholder="seconds"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      {errors.position_lat_sec &&
                                        touched.position_lat_sec &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={"position_lat_sec"}
                                            message={errors.position_lat_sec}
                                            open={
                                              errors.position_lat_sec
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                    <Field name={`lat_direction`}>
                                      {({ field, form }: FieldProps) => (
                                        <div className="square-switch sqswitch mt-1">
                                          <Input
                                            type="checkbox"
                                            switch="none"
                                            name={field.name}
                                            id="north_south"
                                            defaultChecked={
                                              values?.lat_direction === "north"
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              form.setFieldValue(
                                                field.name,
                                                e.target.checked === true
                                                  ? "north"
                                                  : "south"
                                              )
                                            }
                                          />
                                          <Label
                                            htmlFor="north_south"
                                            data-on-label="N"
                                            data-off-label="S"
                                            className="mt-1"
                                          ></Label>
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </Col>
                                <Col>
                                  <Label
                                    className="mb-0 asteric"
                                    for="position_long_deg"
                                  >
                                    Vessel position (Longitude)
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="position_long_deg_lbl"
                                    ></i>
                                  </Label>
                                  <ToolTip
                                    target="position_long_deg_lbl"
                                    message={`${TooltipMsg.CreateReport.filter(
                                      (item: any) =>
                                        item.target === "position_long_deg_lbl"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <div className="d-flex gap-1">
                                    <div className="mb-3">
                                      <Field
                                        type="text"
                                        className="form-control text-right max-width-4"
                                        id="position_long_deg"
                                        name="position_long_deg"
                                        placeholder="degrees"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      {errors?.position_long_deg &&
                                        touched?.position_long_deg &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={"position_long_deg"}
                                            message={errors.position_long_deg}
                                            open={
                                              errors.position_long_deg
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                    <div className="mb-3">
                                      <Field
                                        type="text"
                                        className="form-control text-right max-width-4"
                                        id="position_long_min"
                                        name="position_long_min"
                                        placeholder="minutes"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      {errors.position_long_min &&
                                        touched.position_long_min &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={"position_long_min"}
                                            message={errors.position_long_min}
                                            open={
                                              errors.position_long_min
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                    <div className="mb-3">
                                      <Field
                                        type="text"
                                        className="form-control text-right max-width-4"
                                        id="position_long_sec"
                                        name="position_long_sec"
                                        placeholder="seconds"
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      {errors.position_long_sec &&
                                        touched.position_long_sec &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={"position_long_sec"}
                                            message={errors.position_long_sec}
                                            open={
                                              errors.position_long_sec
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                    <Field name={`long_direction`}>
                                      {({ field, form }: FieldProps) => (
                                        <div className="square-switch sqswitch mt-1">
                                          <Input
                                            type="checkbox"
                                            switch="none"
                                            name={field.name}
                                            id="east_west"
                                            defaultChecked={
                                              values?.long_direction === "east"
                                                ? true
                                                : false
                                            }
                                            onChange={(e: any) =>
                                              form.setFieldValue(
                                                field.name,
                                                e.target.checked === true
                                                  ? "east"
                                                  : "west"
                                              )
                                            }
                                          />
                                          <Label
                                            htmlFor="east_west"
                                            data-on-label="E"
                                            data-off-label="W"
                                            className="mt-1"
                                          ></Label>
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <div className="form-group mb-3">
                                    <Label
                                      className="asteric mb-0"
                                      for="reporting_time_utc"
                                    >
                                      Reporting date & time UTC
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="reporting_time_utc_msg"
                                      />
                                    </Label>
                                    <br />
                                    <ToolTip
                                      target="reporting_time_utc_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target === "reporting_time_utc_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <Field name={`reporting_time_utc`}>
                                      {({ field, form }: FieldProps) => (
                                        <Input
                                          type="datetime-local"
                                          id="reporting_time_utc"
                                          name={field.name}
                                          className="datetimepicker text-uppercase"
                                          min={
                                            lastRecord &&
                                            lastRecord?.reporting_time_utc
                                              ?.toString()
                                              .substring(0, 16)
                                          }
                                          max="2050-12-31T00:00"
                                          defaultValue={values?.reporting_time_utc
                                            ?.toString()
                                            .substring(0, 16)}
                                          onChange={(e: any) =>
                                            handleReportingDateTimeChanges(
                                              e,
                                              form,
                                              values
                                            )
                                          }
                                          onBlur={(e: any) => {
                                            handleChange(e);
                                            handleReportingDateTimeChanges(
                                              e,
                                              form,
                                              values
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                    {errors.reporting_time_utc &&
                                      touched.reporting_time_utc &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={"reporting_time_utc"}
                                          message={errors.reporting_time_utc}
                                          open={
                                            errors.reporting_time_utc
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <label
                                      htmlFor="reporting_time_difference"
                                      className="form-label mb-0 asteric"
                                    >
                                      Difference between local time & UTC
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="time_difference_msg"
                                      />
                                    </label>
                                    <ToolTip
                                      target="time_difference_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target === "time_difference_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <div className="ele_row1">
                                      <Field name={`time_difference_prefix`}>
                                        {({ field, form }: FieldProps) => (
                                          <div className="square-switch sqswitch ms-0 ml-1 mt-1">
                                            <Input
                                              type="checkbox"
                                              switch="none"
                                              name={field.name}
                                              id="time_difference_prefix"
                                              defaultChecked={
                                                values?.time_difference_prefix ===
                                                  "-" ||
                                                  values?.reporting_time_difference
                                                    ?.toString()
                                                    .substring(0, 1) === "-"
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e: any) => {
                                                handleChange(e);
                                                handleTimePrefixSwitchChanges(
                                                  e,
                                                  form,
                                                  values
                                                );
                                              }}
                                              onBlur={handleBlur}
                                            />
                                            <Label
                                              htmlFor="time_difference_prefix"
                                              data-on-label="+"
                                              data-off-label="-"
                                              className="mt-1"
                                            ></Label>
                                            {errors?.time_difference_prefix &&
                                              touched?.time_difference_prefix &&
                                              env?.form_validation === true && (
                                                <ErrorTooltip
                                                  target={
                                                    "time_difference_prefix"
                                                  }
                                                  message={
                                                    errors.time_difference_prefix
                                                  }
                                                  open={
                                                    errors.time_difference_prefix
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              )}
                                          </div>
                                        )}
                                      </Field>
                                      <Field name={`reporting_time_difference`}>
                                        {({ field, form }: FieldProps) => (
                                          <Cleave
                                            id="reporting_time_difference"
                                            placeholder="HH:MM"
                                            options={{
                                              time: true,
                                              timePattern: ["h", "m"],
                                            }}
                                            className="form-control max-width-5 text-center p-2 max-h-2"
                                            name={field.name}
                                            onChange={(e: any) =>
                                              handleTimeDifferenceTextboxChanges(
                                                e,
                                                form,
                                                values
                                              )
                                            }
                                            onBlur={(e: any) =>
                                              handleTimeDifferenceTextboxChanges(
                                                e,
                                                form,
                                                values
                                              )
                                            }
                                            value={
                                              values?.reporting_time_difference
                                            }
                                          />
                                        )}
                                      </Field>
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="asteric mb-0"
                                      for="no_of_hours_retarded"
                                    >
                                      Hours retarded/advanced since last report?
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="no_of_hours_retarded_msg"
                                      />
                                    </Label>
                                    <ToolTip
                                      target="no_of_hours_retarded_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target ===
                                          "no_of_hours_retarded_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <div className="input-group">
                                      <Field
                                        type="text"
                                        className="form-control max-width-7 text-right"
                                        id="no_of_hours_retarded"
                                        name="no_of_hours_retarded"
                                        value={
                                          values?.no_of_hours_retarded < 0
                                            ? values?.no_of_hours_retarded
                                              .toString()
                                              .substring(1)
                                            : values?.no_of_hours_retarded
                                        }
                                        onBlur={handleBlur}
                                        onChange={(e: any) => handleChange(e)}
                                      />
                                      <div className="input-group-text round_border">
                                        {values?.no_of_hours_retarded !== null &&
                                          values?.time_difference_prefix !== null &&
                                          (values?.no_of_hours_retarded
                                            ?.toString()
                                            .substring(0, 1) === "-" ||
                                            values?.time_difference_prefix?.toString() ===
                                            "+")
                                          ? "retarded"
                                          : "advanced"}
                                      </div>
                                      {errors?.no_of_hours_retarded &&
                                        touched?.no_of_hours_retarded &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={"no_of_hours_retarded"}
                                            message={errors.no_of_hours_retarded}
                                            open={
                                              errors.no_of_hours_retarded
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <div className="d-flex flex-wrap mb-2">
                                    <Label
                                      className="mt-1 min-width-12"
                                      for="noon_report"
                                    >
                                      Is this noon report?
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="noon_report_msg"
                                      />
                                    </Label>
                                    <ToolTip
                                      target="noon_report_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target === "noon_report_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <Field name={`is_noon_report`}>
                                      {({ field, form }: FieldProps) => (
                                        <div className="square-switch sqswitch mt-1">
                                          <Input
                                            type="checkbox"
                                            switch="none"
                                            name={field.name}
                                            id="noon_report"
                                            onChange={(e: any) =>
                                              handleIsNoonReportSwitchChanges(
                                                e,
                                                field.name,
                                                form,
                                                values
                                              )
                                            }
                                            defaultChecked={
                                              values?.is_noon_report
                                            }
                                          />
                                          <Label
                                            htmlFor="noon_report"
                                            data-on-label="Yes"
                                            data-off-label="No"
                                            className="mt-1"
                                          ></Label>
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                  <div className="d-flex flex-wrap mb-2">
                                    <Label
                                      className="mt-1 min-width-12"
                                      for="is_cii_adjustment_report"
                                    >
                                      Is this a beginning of CII<br /> adjustment event?
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="cii_adjustment_report_msg"
                                      />
                                    </Label>
                                    <ToolTip
                                      target="cii_adjustment_report_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target ===
                                          "cii_adjustment_report_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <Field name={`is_cii_adjustment_report`}>
                                      {({ field, form }: FieldProps) => (
                                        <div className="square-switch sqswitch">
                                          <Input
                                            type="checkbox"
                                            switch="none"
                                            name={field.name}
                                            id="is_cii_adjustment_report"
                                            onChange={(e: any) =>
                                              handleIsCIIReportSwitchChanges(
                                                e,
                                                form,
                                                values,
                                                field
                                              )
                                            }
                                            defaultChecked={
                                              values?.is_cii_adjustment_report
                                            }
                                            disabled={
                                              values?.only_noon_or_combine_with_other_event ===
                                                "only_noon_report"
                                                ? true
                                                : false
                                            }
                                          />
                                          <Label
                                            htmlFor="is_cii_adjustment_report"
                                            data-on-label="Yes"
                                            data-off-label="No"
                                            className="mt-1"
                                          ></Label>
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </Col>
                                <Col>
                                  {values?.is_noon_report === true &&
                                    values?.is_cii_adjustment_report === true ? (
                                    <>
                                      <Field
                                        name={`cii_noon_combine_with_other_event`}
                                      >
                                        {({ field, form }: FieldProps) => (
                                          <Input
                                            className="form-check-input mr-1"
                                            type="checkbox"
                                            name={field.name}
                                            id="cii_combine_with_other_event"
                                            onChange={(e: any) =>
                                              form.setFieldValue(
                                                field.name,
                                                e.target.checked
                                              )
                                            }
                                            defaultValue={
                                              values?.cii_noon_combine_with_other_event
                                            }
                                          />
                                        )}
                                      </Field>
                                      <Label for="cii_combine_with_other_event">
                                        Is combined with other event?
                                      </Label>
                                      <br />
                                    </>
                                  ) : values?.is_noon_report === true &&
                                    values?.is_cii_adjustment_report === false ? (
                                    <div className="ele_row1">
                                      <div className="form-check mb-3">
                                        <Field
                                          name={`only_noon_or_combine_with_other_event`}
                                        >
                                          {({ field, form }: FieldProps) => (
                                            <Input
                                              className="form-check-input mt-3"
                                              type="radio"
                                              name="noonReport"
                                              id="only_noon"
                                              defaultChecked={
                                                values?.only_noon_or_combine_with_other_event ===
                                                  "combine_with_other_report"
                                                  ? false
                                                  : true
                                              }
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  e.target.checked === true
                                                    ? "only_noon_report"
                                                    : "combine_with_other_report"
                                                );
                                                form.setFieldValue(
                                                  "reporting_event",
                                                  reportingEvent[0]?.next_possible_events?.next_possible_events.filter(
                                                    (item: any) =>
                                                      item?.event_precedence_id ===
                                                      EventPrecedence.NOON
                                                  )[0]?.id
                                                );
                                              }}
                                            />
                                          )}
                                        </Field>
                                        <Label
                                          className="form-check-label mt-3 mr-2"
                                          htmlFor="only_noon"
                                        >
                                          Only noon report
                                        </Label>
                                      </div>
                                      <div className="form-check">
                                        <Field
                                          name={`only_noon_or_combine_with_other_event`}
                                        >
                                          {({ field, form }: FieldProps) => (
                                            <Input
                                              className="form-check-input mt-3"
                                              type="radio"
                                              name="noonReport"
                                              id="combined_with_other_event"
                                              onChange={(e: any) => {
                                                form.setFieldValue(
                                                  field.name,
                                                  e.target.checked === true
                                                    ? "combine_with_other_report"
                                                    : "only_noon_report"
                                                );
                                              }}
                                              defaultChecked={
                                                values?.only_noon_or_combine_with_other_event ===
                                                  "combine_with_other_report"
                                                  ? true
                                                  : false
                                              }
                                            />
                                          )}
                                        </Field>
                                        <Label
                                          className="form-check-label mt-3 "
                                          htmlFor="combined_with_other_event"
                                        >
                                          Combined with other event
                                        </Label>
                                      </div>
                                    </div>
                                  ) : values?.is_noon_report === false &&
                                    values?.is_cii_adjustment_report === true ? (
                                    <>
                                      <Field
                                        name={`cii_combine_with_other_event`}
                                      >
                                        {({ field }: FieldProps) => (
                                          <Input
                                            className="form-check-input mr-1"
                                            type="checkbox"
                                            id="combined_with_other_event"
                                            name={field.name}
                                            onChange={(e: any) => handleChange(e)}
                                            defaultChecked={
                                              values?.cii_combine_with_other_event
                                            }
                                          />
                                        )}
                                      </Field>
                                      <Label for="combined_with_other_event">
                                        Is combined with other event?
                                      </Label>
                                    </>
                                  ) : null}
                                </Col>
                                <Col lg={4}>
                                  <FormGroup className="form-floating mb-3">
                                    <div className="mb-3">
                                      <Label className="asteric mb-0">
                                        Reporting type
                                        <i
                                          className="bx bx-info-circle ml-2p mb-0"
                                          id="reporting_type_msg"
                                        ></i>
                                      </Label>
                                      <br></br>
                                      <ToolTip
                                        target="reporting_type_msg"
                                        message={`${TooltipMsg.CreateReport.filter(
                                          (item: any) =>
                                            item.target === "reporting_type_msg"
                                        ).map((tool: any) => {
                                          return tool.message;
                                        })}`}
                                      />
                                      <h5 className="mb-3">
                                        {VesselReportObject !== undefined &&
                                          VesselReportObject.id > 0 &&
                                          reportingType === ""
                                          ? values?.reporting_type
                                          : reportingType}
                                      </h5>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <Label>Last reporting event name - </Label>
                                  <h5 className="mb-3">
                                    {lastRecord.reporting_event_name}
                                  </h5>
                                </Col>
                                <Col lg={4}>
                                  <Label>Last reporting operation name - </Label>
                                  <h5 className="mb-3">
                                    {lastRecord.reporting_operation_mode_name}
                                  </h5>
                                </Col>
                                <Col lg={4}>
                                  <Label>Last reporting engine state - </Label>
                                  <h5 className="mb-3">
                                    {lastRecord.status_name}
                                  </h5>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <FormGroup className="form-floating mb-3">
                                    <div className="mb-3">
                                      <Label
                                        className="asteric mb-0"
                                        for="reporting_event"
                                      >
                                        Reporting event
                                        <i
                                          className="bx bx-info-circle ml-2p"
                                          id="reporting_event_msg"
                                        ></i>
                                      </Label>
                                      <ToolTip
                                        target="reporting_event_msg"
                                        message={`${TooltipMsg.CreateReport.filter(
                                          (item: any) =>
                                            item.target === "reporting_event_msg"
                                        ).map((tool: any) => {
                                          return tool.message;
                                        })}`}
                                      />
                                      {(values?.only_noon_or_combine_with_other_event ===
                                        "only_noon_report" &&
                                        values?.is_cii_adjustment_report ===
                                        true &&
                                        values?.cii_noon_combine_with_other_event ===
                                        false) ||
                                        (values?.only_noon_or_combine_with_other_event ===
                                          "only_noon_report" &&
                                          values?.is_cii_adjustment_report ===
                                          false &&
                                          values?.cii_noon_combine_with_other_event ===
                                          false) ? (
                                        <>
                                          <Field
                                            type="text"
                                            className="form-control"
                                            id="reporting_event"
                                            name="reporting_event"
                                            value={
                                              reportingEvent &&
                                              reportingEvent[0]?.next_possible_events?.next_possible_events.filter(
                                                (item: any) =>
                                                  item?.event_precedence_id ===
                                                  EventPrecedence.NOON
                                              )[0]?.event
                                            }
                                            onBlur={handleBlur}
                                            onChange={(e: any) => handleChange(e)}
                                          />
                                        </>
                                      ) : (values?.is_noon_report === false &&
                                        values?.is_cii_adjustment_report ===
                                        true &&
                                        values?.cii_combine_with_other_event ===
                                        false) ||
                                        (values?.is_noon_report === true &&
                                          values?.is_cii_adjustment_report ===
                                          true &&
                                          values?.cii_noon_combine_with_other_event ===
                                          false) ? (
                                        <Field
                                          type="text"
                                          className="form-control"
                                          id="reporting_event"
                                          name="reporting_event"
                                          value={
                                            reportingEvent[0]?.next_possible_events.next_possible_events.filter(
                                              (item: any) =>
                                                item.event_precedence_id ===
                                                EventPrecedence.CII_ADJUSTMENT_EVENT
                                            )[0].event
                                          }
                                          onBlur={handleBlur}
                                          onChange={(e: any) => handleChange(e)}
                                        />
                                      ) : (values?.is_noon_report === true &&
                                        values?.is_cii_adjustment_report ===
                                        true &&
                                        values?.cii_noon_combine_with_other_event ===
                                        true) ||
                                        (values?.only_noon_or_combine_with_other_event ===
                                          "combine_with_other_report" &&
                                          values?.is_cii_adjustment_report ===
                                          false &&
                                          values?.cii_noon_combine_with_other_event ===
                                          false) ||
                                        (values?.is_noon_report === false &&
                                          values?.is_cii_adjustment_report ===
                                          true &&
                                          values?.cii_combine_with_other_event ===
                                          true) ||
                                        (values?.is_noon_report === false &&
                                          values?.is_cii_adjustment_report ===
                                          false) ? (
                                        <>
                                          <Field name={`reporting_event`}>
                                            {({ form }: FieldProps) => (
                                              <AsyncSelect
                                                name="reporting_event"
                                                inputId="reporting_event"
                                                key={count}
                                                defaultOptions
                                                loadOptions={
                                                  values?.additional_events ===
                                                    false
                                                    ? loadReportEventOptionsWithoutCII
                                                    : loadNextAdditionalEvents
                                                }
                                                getOptionLabel={(e: any) =>
                                                  e?.event
                                                }
                                                getOptionValue={(e: any) => e?.id}
                                                defaultValue={
                                                  VesselReportObject &&
                                                    VesselReportObject.id > 0
                                                    ? {
                                                      id: VesselReportObject.reporting_event,
                                                      event:
                                                        VesselReportObject.reporting_event_name,
                                                    }
                                                    : reportingEvent && {
                                                      id: reportingEvent[0]?.next_possible_events.next_possible_events.filter(
                                                        (item: any) =>
                                                          item.id ===
                                                          values?.reporting_event
                                                      )[0]?.id,
                                                      event:
                                                        reportingEvent[0]?.next_possible_events.next_possible_events.filter(
                                                          (item: any) =>
                                                            item.id ===
                                                            values?.reporting_event
                                                        )[0]?.event,
                                                    }
                                                }
                                                onChange={(e: any) => {
                                                  handleReportingEventDropdownChanges(
                                                    e,
                                                    form,
                                                    values
                                                  );
                                                }}
                                                menuPortalTarget={document.body}
                                                styles={customStyle}
                                                className="mb-0"
                                                onBlur={handleBlur}
                                              />
                                            )}
                                          </Field>
                                          <br />
                                          {errors?.reporting_event &&
                                            touched?.reporting_event &&
                                            env?.form_validation === true && (
                                              <ErrorTooltip
                                                target={"reporting_event"}
                                                message={errors.reporting_event}
                                                open={
                                                  errors.reporting_event
                                                    ? true
                                                    : false
                                                }
                                              />
                                            )}
                                          <Label className="mt-0 font-smaller mb-0">
                                            Select this if you need to choose
                                            additional events other than listed
                                            above
                                          </Label>
                                          <Field name={`additional_events`}>
                                            {({ field }: FieldProps) => (
                                              <Input
                                                className="form-check-input mr-1 justify_right"
                                                type="checkbox"
                                                name={field.name}
                                                id="additional_events"
                                                onChange={(e: any) => {
                                                  handleChange(e);
                                                  setCount(count + 1);
                                                }}
                                                defaultChecked={
                                                  values?.additional_events
                                                }
                                              />
                                            )}
                                          </Field>
                                          <Label for="additional_events">
                                            Additional events
                                          </Label>
                                        </>
                                      ) : null}
                                    </div>
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="asteric mb-0"
                                      for="reporting_operation_mode_name"
                                    >
                                      Reporting operation since last report
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="reporting_operation_mode_msg"
                                      ></i>
                                    </Label>
                                    <ToolTip
                                      target="reporting_operation_mode_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target ===
                                          "reporting_operation_mode_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <h5 className="mt-2">
                                      {lastRecord &&
                                        lastRecord?.event_precedence_id ===
                                        EventPrecedence.NOON &&
                                        values?.only_noon_or_combine_with_other_event ===
                                        "only_noon_report"
                                        ? lastRecord?.reporting_operation_mode_name
                                        : values?.reporting_operation_mode_name}
                                    </h5>
                                  </div>
                                </Col>
                                <Col>
                                  {values &&
                                    values?.reporting_operation_mode ===
                                    ReportingOpConstant.MANEUVERING && (
                                      <div className="d-flex flex-wrap gap-2 mb-3">
                                        <Label for="is_strait_canal_transit">
                                          Is this strait-canal transit?
                                          <i
                                            className="bx bx-info-circle ml-2p"
                                            id="is_strait_canal_transit_lbl"
                                          />
                                        </Label>
                                        <ToolTip
                                          target="is_strait_canal_transit_lbl"
                                          message={`${TooltipMsg.CreateReport.filter(
                                            (item: any) =>
                                              item.target ===
                                              "is_strait_canal_transit_lbl"
                                          ).map((tool: any) => {
                                            return tool.message;
                                          })}`}
                                        />
                                        <div className="square-switch sqswitch">
                                          <Field
                                            type="checkbox"
                                            switch="none"
                                            name="is_strait_canal_transit"
                                            id="is_strait_canal_transit"
                                            defaultChecked={
                                              values?.is_strait_canal_transit
                                            }
                                          />
                                          <Label
                                            htmlFor="is_strait_canal_transit"
                                            data-on-label="Yes"
                                            data-off-label="No"
                                          />
                                        </div>
                                      </div>
                                    )}
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={4}>
                                  <FormGroup className="form-floating mb-3">
                                    <div className="mb-3">
                                      <Label
                                        className="asteric mb-0"
                                        for="load_condition"
                                      >
                                        Vessel load condition
                                        <i
                                          className="bx bx-info-circle ml-2p"
                                          id="load_condition_msg"
                                        />
                                      </Label>
                                      <br></br>
                                      <ToolTip
                                        target="load_condition_msg"
                                        message={`${TooltipMsg.CreateReport.filter(
                                          (item: any) =>
                                            item.target === "load_condition_msg"
                                        ).map((tool: any) => {
                                          return tool.message;
                                        })}`}
                                      />
                                      <Field name={`load_condition`}>
                                        {({ field, form }: FieldProps) => (
                                          <AsyncSelect
                                            name={field.name}
                                            inputId={field.name}
                                            cacheOptions
                                            defaultOptions
                                            loadOptions={loadLoadConditionOptions}
                                            getOptionLabel={(e: any) =>
                                              e?.load_condition_name
                                            }
                                            getOptionValue={(e: any) => e?.id}
                                            onChange={(selectedOption: any) => {
                                              form.setFieldValue(
                                                field.name,
                                                selectedOption?.id
                                              );
                                            }}
                                            defaultValue={
                                              VesselReportObject !== undefined &&
                                                VesselReportObject.id > 0
                                                ? {
                                                  id: values?.load_condition,
                                                  load_condition_name:
                                                    values?.load_condition_name,
                                                }
                                                : null
                                            }
                                            menuPortalTarget={document.body}
                                            styles={customStyle}
                                            onBlur={handleBlur}
                                          />
                                        )}
                                      </Field>
                                    </div>
                                    {errors?.load_condition &&
                                      touched?.load_condition &&
                                      env?.form_validation === true && (
                                        <ErrorTooltip
                                          target={"load_condition"}
                                          message={errors.load_condition}
                                          open={
                                            errors.load_condition ? true : false
                                          }
                                        />
                                      )}
                                  </FormGroup>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      for="remaining_distance_to_the_next_port"
                                      className="mb-0"
                                    >
                                      Remaining distance to the next port
                                      <i
                                        className="bx bx-info-circle ml-2p"
                                        id="remaining_distance_msg"
                                      />
                                    </Label>
                                    <ToolTip
                                      target="remaining_distance_msg"
                                      message={`${TooltipMsg.CreateReport.filter(
                                        (item: any) =>
                                          item.target === "remaining_distance_msg"
                                      ).map((tool: any) => {
                                        return tool.message;
                                      })}`}
                                    />
                                    <div className="input-group">
                                      <Field
                                        type="text"
                                        className="form-control max-width-7 text-right"
                                        id="remaining_distance_to_the_next_port"
                                        name="remaining_distance_to_the_next_port"
                                        disabled={
                                          reportingType ===
                                            ReportingTypeConstant.INPORT
                                            ? true
                                            : false
                                        }
                                      />
                                      <div className="input-group-text round_border">
                                        nm
                                      </div>
                                      {errors.remaining_distance_to_the_next_port &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={
                                              "remaining_distance_to_the_next_port"
                                            }
                                            message={
                                              errors.remaining_distance_to_the_next_port
                                            }
                                            open={
                                              errors.remaining_distance_to_the_next_port
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </div>
                                  </div>
                                </Col>
                                <Col>
                                  {values &&
                                    values?.is_strait_canal_transit === true && (
                                      <FormGroup className="form-floating mb-3">
                                        <div className="mb-3">
                                          <Label
                                            className="mb-0"
                                            for="strait_canal_transit"
                                          >
                                            Strait-Canal transit
                                            <i
                                              className="bx bx-info-circle ml-2p"
                                              id="strait_canal"
                                            />
                                          </Label>
                                          <br></br>
                                          <ToolTip
                                            target="strait_canal"
                                            message={`${TooltipMsg.CreateReport.filter(
                                              (item: any) =>
                                                item.target === "strait_canal"
                                            ).map((tool: any) => {
                                              return tool.message;
                                            })}`}
                                          />
                                          <Field name={`strait_canal_transit`}>
                                            {({ field, form }: FieldProps) => (
                                              <AsyncSelect
                                                name={field.name}
                                                inputId={field.name}
                                                cacheOptions
                                                defaultOptions
                                                loadOptions={
                                                  loadStraitCanalOptions
                                                }
                                                getOptionLabel={(e) =>
                                                  e.strait_canal_transit
                                                }
                                                getOptionValue={(e) => e.id}
                                                onChange={(
                                                  selectedOption: any
                                                ) => {
                                                  form.setFieldValue(
                                                    field.name,
                                                    selectedOption.id
                                                  );
                                                }}
                                                defaultValue={
                                                  VesselReportObject !==
                                                    undefined &&
                                                    VesselReportObject.id > 0
                                                    ? {
                                                      id: values?.strait_canal_transit,
                                                      strait_canal_transit:
                                                        values?.strait_canal_transit_name,
                                                    }
                                                    : null
                                                }
                                                menuPortalTarget={document.body}
                                                styles={customStyle}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                      </FormGroup>
                                    )}
                                </Col>
                              </Row>
                              <Row>
                                {(((vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER ||
                                  vessel?.vessel_type === VesselTypeConstant.TANKER ||
                                  vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER) &&
                                  LoadingDischargingOps?.includes(values?.reporting_operation_mode)) ||
                                  (!(vessel?.vessel_type === VesselTypeConstant.LNG_CARRIER ||
                                    vessel?.vessel_type === VesselTypeConstant.TANKER ||
                                    vessel?.vessel_type === VesselTypeConstant.GAS_CARRIER) &&
                                    (values?.reporting_type === ReportingTypeConstant.INPORT ||
                                      reportingType === ReportingTypeConstant.INPORT))) &&
                                  <Col lg={4}>
                                    <ThreeStateSwitch
                                      error={errors.is_this_cargo_loading_discharging}
                                      touched={touched.is_this_cargo_loading_discharging}
                                      errorMessage={errors?.is_this_cargo_loading_discharging}
                                      switchLabel={`Is loading/discharging operation carried out?`}
                                      switchLabelId='is_this_cargo_loading_discharging'
                                      switchFieldName='is_this_cargo_loading_discharging'
                                      state1Label='Yes'
                                      state1Id='yes'
                                      state1Value={'yes'}
                                      state1DefaultValue={values?.is_this_cargo_loading_discharging === 'yes' ? true : false}
                                      state2Label=''
                                      state2Id='na'
                                      state2Value={null}
                                      state2DefaultValue={values?.is_this_cargo_loading_discharging === null ? true : false}
                                      state3Label='No'
                                      state3Id='no'
                                      state3Value={'no'}
                                      state3DefaultValue={values?.is_this_cargo_loading_discharging === 'no' ? true : false}
                                    />
                                  </Col>
                                }
                                <Col lg={4}>
                                  {VesselReportObject !== undefined &&
                                    (vessel?.vessel_type ===
                                      VesselTypeConstant.TANKER ||
                                      vessel?.vessel_type ===
                                      VesselTypeConstant.LNG_CARRIER) &&
                                    (VesselReportObject.id > 0 &&
                                      reportingType === ""
                                      ? values?.reporting_type
                                      : reportingType) ===
                                    ReportingTypeConstant.INPORT && (
                                      <div className="d-flex flex-wrap gap-2 mb-3">
                                        <Label
                                          className="mt-2"
                                          for="is_sts_considered"
                                        >
                                          Is this the beginning of an STS
                                          operation?
                                          <i
                                            className="bx bx-info-circle ml-2p"
                                            id="is_sts_considered_msg"
                                          />
                                        </Label>
                                        <ToolTip
                                          target="is_sts_considered_msg"
                                          message={`${TooltipMsg.CreateReport.filter(
                                            (item: any) =>
                                              item.target ===
                                              "is_sts_considered_msg"
                                          ).map((tool: any) => {
                                            return tool.message;
                                          })}`}
                                        />
                                        <Field name={`is_sts_considered`}>
                                          {({ field, form }: FieldProps) => (
                                            <div className="square-switch sqswitch mt-1">
                                              <Input
                                                type="checkbox"
                                                switch="none"
                                                name={field.name}
                                                id="is_sts_considered"
                                                onChange={(e: any) =>
                                                  form.setFieldValue(
                                                    field.name,
                                                    e.target.checked
                                                  )
                                                }
                                                defaultChecked={
                                                  values?.is_sts_considered
                                                }
                                              />
                                              <Label
                                                htmlFor="is_sts_considered"
                                                data-on-label="Yes"
                                                data-off-label="No"
                                                className="mt-1"
                                              ></Label>
                                            </div>
                                          )}
                                        </Field>
                                      </div>
                                    )}
                                </Col>
                              </Row>
                              {values?.reporting_operation_mode === ReportingOpConstant.MANEUVERING &&
                                calculateTimeDurationBetwnReports(lastRecord?.reporting_time_utc, values?.reporting_time_utc) >= ManeouveringTimeLimit &&
                                <div className="d-flex flex-wrap gap-2 mb-3">
                                  <Label
                                    className="mt-2"
                                    for="is_drifting_inolved"
                                  >
                                    Is drifting involved since last report?
                                    <i
                                      className="bx bx-info-circle ml-2p"
                                      id="is_drifting_inolved_msg"
                                    />
                                  </Label>
                                  <ToolTip
                                    target="is_drifting_inolved_msg"
                                    message={`${TooltipMsg.CreateReport.filter(
                                      (item: any) =>
                                        item.target ===
                                        "is_drifting_inolved_msg"
                                    ).map((tool: any) => {
                                      return tool.message;
                                    })}`}
                                  />
                                  <Field name={`is_drifting_inolved`}>
                                    {({ field, form }: FieldProps) => (
                                      <div className="square-switch sqswitch mt-1">
                                        <Input
                                          type="checkbox"
                                          switch="none"
                                          name={field.name}
                                          id="is_drifting_inolved"
                                          onChange={(e: any) =>
                                            form.setFieldValue(
                                              field.name,
                                              e.target.checked
                                            )
                                          }
                                          defaultChecked={
                                            values?.is_drifting_inolved
                                          }
                                        />
                                        <Label
                                          htmlFor="is_drifting_inolved"
                                          data-on-label="Yes"
                                          data-off-label="No"
                                          className="mt-1"
                                        ></Label>
                                      </div>
                                    )}
                                  </Field>
                                </div>
                              }
                              <Row>
                                <div className="d-flex flex-wrap gap-5 grp_justify_right btn_Margin">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                  >
                                    Save
                                  </Button>
                                  <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                    onClick={() => {
                                      resetForm();
                                      setCount(count + 1);
                                    }}
                                  >
                                    Reset
                                  </Button>
                                </div>
                              </Row>
                              <FormValuesDebug
                                values={[
                                  values,
                                  errors,
                                  touched,
                                  CreateVesselReportFormik.initialvalues,
                                ]}
                              />
                            </Form>
                          )}
                        </Formik>
                      )}
                  </CardBody>
                </Card>
              </Container>
            </div>
          </>
        )}
    </React.Fragment>
  );
};

export default CreateVesselReport;
