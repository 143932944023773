import React, { useState } from 'react';
import { CardBody, NavItem, TabContent, TabPane, NavLink, Nav, Row, Card, Label, Col } from "reactstrap";
import classnames from "classnames";
import '../global/GlobalCSS.css'
import VesselCard from 'Components/VesselCard';
import { loadFleetVessels, loadFleets, loadVesselCardDetails } from 'VesselMaster/vesselMaster.hooks';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { RootState } from 'index';
import { useSelector } from 'react-redux';

const DashboardFleet = () => {
    /** State variables */
    const [activeSettingsTab, setActiveSettingsTab] = useState('0');
    const { Vessels } = useSelector((state: RootState) => state.Reporting);
    const ApplicableVessels: any = Vessels.filter((vessel: any) => vessel.display === true);
    /** State varibles end */

    /** Funtion to change tab */
    const toggle = (tab: any) => {
        if (activeSettingsTab !== tab) {
            setActiveSettingsTab(tab);
        }
    }

    /** Queries */
    /** Load fleets from fleet master */
    const { data: Fleets, isLoading: FleetsLoading, isError: FleetsError } = useQuery(
        [queryKeyes.masters.Fleets.key],
        async () => {
            return await loadFleets()
        },
        {
            enabled: true,
        }
    );
    /** Load fleet's vessels */
    const { data: FleetVessels, isLoading: FleetVesselsLoading, isError: FleetVesselsError } = useQuery(
        [queryKeyes.masters.FleetVessels.key],
        async () => {
            return await loadFleetVessels();
        },
        {
            enabled: true,
        }
    );
    /** Vessel card details */
    const { data: CardDetails, isLoading: CardDetailsLoading, isError: CardDetailsError } = useQuery(
        [queryKeyes.vessel.VesselCardDetails.key],
        async () => {
            return await loadVesselCardDetails()
        },
        {
            enabled: true,
        }
    );
    /** Queries end */

    return (
        <React.Fragment>
            {(FleetsLoading || FleetVesselsLoading || CardDetailsLoading) &&
                <Row>
                    <Col lg={4}>
                        <Loading message='Loading required data!' />
                    </Col>
                </Row>
            }
            {(FleetsError || FleetVesselsError || CardDetailsError) &&
                <Row>
                    <Col lg={4}>
                        <ErrorComponent message='Unable to load required data!' />
                    </Col>
                </Row>
            }
            {!(FleetsLoading || FleetVesselsLoading) && !(FleetsError || FleetVesselsError) &&
                <Card className='mb-0'>
                    <CardBody className='pb-0 pt-2'>
                        <Nav tabs className="nav-tabs-custom nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: 'pointer' }}
                                    className={classnames({
                                        active: activeSettingsTab === '0',
                                    })}
                                    onClick={() => {
                                        toggle('0');
                                    }}
                                >
                                    <span className="d-block d-sm-none">
                                        <i className="mdi mdi-sail-boat"></i>
                                    </span>
                                    <h6 className="d-none d-sm-block">All</h6>
                                </NavLink>
                            </NavItem>
                            {Fleets.filter((item: any) => item.display === true).length > 0 &&
                                Fleets.filter((item: any) => item.display === true)
                                    .map((fleet: any, index: number) => {
                                        return (
                                            <NavItem key={index}>
                                                <NavLink
                                                    style={{ cursor: 'pointer' }}
                                                    className={classnames({
                                                        active: activeSettingsTab === `${index + 1}`,
                                                    })}
                                                    onClick={() => {
                                                        toggle(`${index + 1}`);
                                                    }}
                                                >
                                                    <span className="d-block d-sm-none">
                                                        <i className="mdi mdi-sail-boat"></i>
                                                    </span>
                                                    <h6 className="d-none d-sm-block">{fleet.fleet_name}</h6>
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })}
                        </Nav>
                        <TabContent activeTab={activeSettingsTab} className="p-3 pb-0 text-muted">
                            <TabPane tabId='0'>
                                <VesselCard Vessels={CardDetails?.filter((item: any) => {
                                    return ApplicableVessels?.some((applicable: any) => applicable.id === item.vessel_id);
                                })} />
                            </TabPane>
                            {Fleets.filter((item: any) => item.display === true)
                                .map((fleet: any, index: number) => {
                                    let ValidVessels: any[] = FleetVessels.filter((item: any) => item.fleet_id === fleet.id && item.vessel_checkbox === true);
                                    return (
                                        <TabPane key={index} tabId={`${index + 1}`}>
                                            {CardDetails?.filter((item: any) => {
                                                return ValidVessels?.some((valid: any) => valid.vessel_id === item.vessel_id);
                                            }).length === 0 ? <div className='text-center'><Label className='mb-0'>No vessel are added in this fleet</Label></div> :
                                                <VesselCard Vessels={CardDetails?.filter((item: any) => {
                                                    return ValidVessels?.some((valid: any) => valid.vessel_id === item.vessel_id);
                                                })} />
                                            }
                                        </TabPane>
                                    )
                                })}
                        </TabContent>
                    </CardBody>
                </Card>
            }
        </React.Fragment>
    )
}

export default DashboardFleet