import { Field, FieldProps } from 'formik';
import React from 'react'
import { Label } from 'reactstrap';
import ToolTip from './ToolTip';
import { TooltipMsg } from './ToolTipMessage';
import ErrorTooltip from './ErrorTooltip';
import env from 'environment/env';

const ThreeStateSwitch = ({
    error,
    touched,
    errorMessage,
    switchLabel,
    switchLabelId,
    switchFieldName,
    state1Label,
    state1Id,
    state1Value,
    state1DefaultValue,
    state2Label,
    state2Id,
    state2Value,
    state2DefaultValue,
    state3Label,
    state3Id,
    state3Value,
    state3DefaultValue,
}: any) => {
    return (<div className="ele_row1">
        <Label
            className="asteric mt-2 max-width-10"
            for={switchLabelId}
        >
            {switchLabel}
            <i
                className="bx bx-info-circle"
                id={`${switchLabelId}_msg`}
            />
        </Label>
        <ToolTip
            target={`${switchLabelId}_msg`}
            message={`${TooltipMsg.CreateReport.filter(
                (item: any) =>
                    item.target ===
                    `${switchLabelId}_msg`
            ).map((tool: any) => {
                return tool.message;
            })}`}
        />
        <div className="d-flex align-items-center ms-4">
            <div className="three-state-switch d-flex align-items-center">
                <div className='ele_row1'>
                    <div className="switch-3 switch-candy ms-1">
                        <div className='ele_row1 min-width-2'>
                            <Field name={switchFieldName}>
                                {({ field, form }: FieldProps) => (
                                    <input
                                        className="form-check-input three-state-switch-toggle"
                                        type="radio"
                                        name={field.name}
                                        id={state1Id}
                                        onChange={() => form.setFieldValue(field.name, state1Value)}
                                        defaultChecked={state1DefaultValue}
                                    />
                                )}
                            </Field>
                            <Label for={state1Id} className="mb-0 text-center w-100">{state1Label}</Label>
                        </div>
                    </div>
                    <div className="switch-3 switch-candy">
                        <div className='ele_row1 min-width-2 ms-1 me-1'>
                            <Field name={switchFieldName}>
                                {({ field, form }: FieldProps) => (
                                    <input
                                        className="form-check-input three-state-switch-toggle"
                                        type="radio"
                                        name={field.name}
                                        id={state2Id}
                                        defaultChecked={state2DefaultValue}
                                        onChange={() => form.setFieldValue(field.name, state2Value)}
                                    />
                                )}
                            </Field>
                            <Label for={state2Id} className="mdi mdi-arrow-left-right mb-0 text-center w-100">{state2Label}</Label>
                        </div>
                    </div>
                    <div className="switch-3 me-1">
                        <div className='ele_row1 min-width-2'>
                            <Field name={switchFieldName}>
                                {({ field, form }: FieldProps) => (
                                    <input
                                        className="form-check-input three-state-switch-toggle"
                                        type="radio"
                                        name={field.name}
                                        id={state3Id}
                                        defaultChecked={state3DefaultValue}
                                        onChange={() => form.setFieldValue(field.name, state3Value)}
                                    />
                                )}
                            </Field>
                            <Label for={state3Id} className="mb-0 text-center w-100">{state3Label}</Label>
                        </div>
                    </div>
                    {error && touched && env?.form_validation === true && (<ErrorTooltip
                        target={state2Id}
                        message={errorMessage}
                        open={error ? true : false}
                    />)}
                </div>
            </div>
        </div>
    </div>
    )
}

export default ThreeStateSwitch