import { useSelector } from 'react-redux';
import { RootState } from '../';
import { useQuery } from 'react-query';
import { vesselReportingInfo } from '../VesselMaster/vesselMaster.hooks';
import { queryKeyes } from '../shared/queryKeys';
import React from 'react';

const ReportDetailsHeader = () => {
    /** State variables */
    const { ReportID } = useSelector((state: RootState) => state.Reporting);
    /** State variables end */

    /** useQueries */
    const { data } = useQuery([queryKeyes.vessel.reporting.key, ReportID], async () => {
        return await vesselReportingInfo(ReportID);
    }, { staleTime: Infinity })
    /** useQueries end */
    if (!data) return <div />;
    return (
        <div className='mb-2'>
            <h5 className="pos-end">{data.reporting_event_name} Report</h5><br />
            <p className="text-muted mb-0 font-size-12 pos-end">
                {data && data?.reporting_time_utc?.slice(0, 10)} {data && data?.reporting_time_utc?.slice(11, 16)}
            </p>
        </div>
    )
}

export default ReportDetailsHeader