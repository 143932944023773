import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { loadFuelSubTypesByType, loadFuelTypes, loadVesselFuel, loadVesselFuelTypes } from '../../vesselMaster.hooks';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import { CardBody, Row, Col, Form, CardFooter, Button } from 'reactstrap';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { errorToast, successToast } from 'Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import apiGlobal from 'global/api.global';
import { AxiosResponse } from 'axios';
import { queryClient } from 'react-query/queryClient';
import VesselFuelTable from './VesselFuelTable';

const VesselFuelConfiguration = () => {
    /** State variables start */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [refreshKey, setRefreshKey] = useState(0);
    /** State variables end */

    /** useQueries */
    /** Load distinct fuel types configured on vessel */
    const {
        data: VesselFuelTypes,
        isLoading: VesselFuelTypesLoading,
    } = useQuery(
        [queryKeyes.vessel.vesselFuelType.key, VesselID],
        async () => {
            return await loadVesselFuelTypes(VesselID);
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** Load fuel types */
    const {
        data: FuelTypes,
        isLoading: FuelTypesLoading,
        isError: FuelTypesError,
    } = useQuery(
        [queryKeyes.masters.fuelTypeMaster.key],
        async () => {
            return await loadFuelTypes();
        },
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    /** useQueries end */

    /** Assign initial values to fuel consumption Formik object */
    const getInitialValues = () => {
        let arr: { [key: string]: string | number | boolean | Date | null }[] = [];
        if (VesselFuelTypes && VesselFuelTypes?.length > 0) {
            if (arr?.length < FuelTypes?.length) {
                arr = VesselFuelTypes?.map((fuelType: { [key: string]: string | number | boolean | Date | null }) => ({
                    ...fuelType,
                    selected: true
                }))
                FuelTypes?.filter((item: { [key: string]: string | number | boolean | Date | null }) =>
                    !VesselFuelTypes.some((fuel: { [key: string]: string | number | boolean | Date | null }) => item?.id === fuel?.fuel_type)
                )?.forEach((fuelType: { [key: string]: string | number | boolean | Date | null }) => {
                    arr?.push({
                        fuel_name: '',
                        fuel_type_name: fuelType?.fuel_type_name,
                        fuel_type: fuelType?.id,
                        fuel_sub_type: 0,
                        selected: false,
                        vessel: VesselID,
                    })
                })
            }
        } else {
            FuelTypes?.forEach((fuelType: { [key: string]: string | number | boolean | Date | null }) => {
                arr.push({
                    fuel_name: '',
                    fuel_type_name: fuelType?.fuel_type_name,
                    fuel_type: fuelType?.id,
                    fuel_sub_type: 0,
                    selected: false,
                    vessel: VesselID,
                })
            })
        }
        return arr;
    }

    /** useEffect */
    useEffect(() => {
        VesselFuelFormik.initialValues.vesselFuels = getInitialValues()
        setRefreshKey(refreshKey + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselFuelTypes, FuelTypes, VesselID])
    /** useEffect end */

    /** Vessel fuel configuration's formik object */
    const VesselFuelFormik = {
        initialValues:
        {
            vesselFuels: getInitialValues()
        }
    }

    /** Post form submit actions */
    const handleResponse = async (
        res: AxiosResponse<{ [key: string]: string | number | boolean | Date }>,
        actions: { setSubmitting: (arg: boolean) => void }) => {
        if (res.status === 204 || res.status === 201) {
            successToast("Data saved successfully!");
            queryClient.invalidateQueries(queryKeyes.vessel.vesselFuelType.key);
            setRefreshKey(refreshKey + 1);
            actions?.setSubmitting(false);
        }
    }

    return (
        <Formik
            onSubmit={
                (values: { [key: string]: { [key: string]: string | number | boolean | Date | null }[] },
                    actions: { setSubmitting: (arg: boolean) => void }) => {
                    actions?.setSubmitting(true);
                    values?.vesselFuels?.forEach(async (value: { [key: string]: string | number | boolean | Date | null }) => {
                        if (value?.id as number > 0) {
                            if (value?.selected === false) {
                                const fuels = await loadVesselFuel(VesselID);
                                fuels?.filter((fuel: { [key: string]: string | number | boolean | Date | null }) =>
                                    fuel?.fuel_sub_type === value?.fuel_sub_type)?.forEach(
                                        (subType: { [key: string]: string | number | boolean | Date | null }) => {
                                            apiGlobal.delete(`/vessel_fuel_information/${subType?.id as number}/`).then(res => {
                                                handleResponse(res, actions);
                                            }).catch(err => {
                                                if (errResponse.includes(err.response.status)) {
                                                    errorToast("Internal error occured, please contact the admin")
                                                }
                                            })
                                        })
                            }
                        } else {
                            if (value?.selected === true) {
                                const fuelSubTypes = await loadFuelSubTypesByType(value?.fuel_type as number);
                                fuelSubTypes.forEach((subType: { [key: string]: string | number | boolean | Date | null }) => {
                                    value.fuel_sub_type = subType.id;
                                    apiGlobal.post(`/vessel_fuel_information/`, value).then(res => {
                                        handleResponse(res, actions);
                                    }).catch(err => {
                                        if (errResponse.includes(err.response.status)) {
                                            errorToast("Internal error occured, please contact the admin")
                                        }
                                    })
                                })
                            }
                        }
                    })
                }}
            initialValues={VesselFuelFormik.initialValues}
            key={refreshKey}
        >
            {(props) => (
                <Form onSubmit={props.handleSubmit} noValidate autoComplete="off">
                    <CardBody className="p-0">
                        {(FuelTypesLoading || VesselFuelTypesLoading) && (
                            <Loading message="Loading required data!" />
                        )}
                        {(FuelTypesError) && (
                            <ErrorComponent message="Error loading required data!" />
                        )}
                        {!(FuelTypesLoading || VesselFuelTypesLoading) &&
                            !(FuelTypesError) &&
                            <Row>
                                <Col md={4} className="px-0">
                                    <VesselFuelTable
                                        minIndex={0}
                                        maxIndex={7}
                                    />
                                </Col>
                                <Col md={4} className="px-0">
                                    <VesselFuelTable
                                        minIndex={7}
                                        maxIndex={14}
                                    />
                                </Col>
                                <Col md={4} className="px-0">
                                    <VesselFuelTable
                                        minIndex={14}
                                        maxIndex={(props?.values?.vesselFuels?.length % 7) < 7 ? props?.values?.vesselFuels?.length : 21}
                                    />
                                </Col>
                            </Row>
                        }
                    </CardBody>
                    <CardFooter className='p-2 py-3 mb-3'>
                        <Row>
                            <Col className='pb-3'>
                                <Button type="submit" color='primary' className='pos-end'>Save</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                    <FormValuesDebug values={[props.values, props.errors, VesselFuelFormik.initialValues]} />
                </Form>
            )}
        </Formik>
    )
}

export default VesselFuelConfiguration