import React from 'react'
import { Roles } from 'shared/constants'
import { hasRole } from 'utils/auth/authUtils'

const STSAjustmentTableHeadComponent = () => {
    return (
        <thead className="table-light">
            <tr>
                <th className='p-2 align-center sr-no-width'>#</th>
                <th className='p-2 align-center' style={{ width: '25%' }}>Duration</th>
                <th className='p-2 align-center'>STS Operation</th>
                {!(hasRole(Roles.CHIEFENGINEER)) &&
                    <th className='p-2 align-center text-center' style={{ width: '15%' }}>Actions</th>
                }
            </tr>
        </thead>
    )
}

export default STSAjustmentTableHeadComponent