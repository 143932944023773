import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import "../global/GlobalCSS.css";
import Layout from "HorizontalMenu/Menu";
import Navbar from "HorizontalMenu/Navbar";

const SingleReportView = () => {
  return (
    <React.Fragment>
      <Layout children={Navbar} />
      <div className="page-content">
        <Container fluid>
          <CardHeader>
            <div className="invoice-title">
              <div className="pos-center">
                <div className="mb-4">
                  <h4 className="text-center">Noon Report - 10/01/2023</h4>
                </div>
              </div>
            </div>
          </CardHeader>
          <Card className="mb-2">
            <CardHeader className="p-2">
              <div className="text-center">
                <h5 className="primary-label mb-0">Vessel Information</h5>
              </div>
            </CardHeader>
            <CardBody className="p-2">
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Vessel name:</p>
                    <strong className="text-center ms-2">Vessel 1</strong>
                  </div>
                  <div className="ele_row1">
                    <p className="text-center mb-0">IMO number:</p>
                    <strong className="text-center ms-2">1111111</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Vessel type:</p>
                    <strong className="text-center ms-2">Tanker</strong>
                  </div>
                  <div className="ele_row1">
                    <p className="text-center mb-0">Flag:</p>
                    <strong className="text-center ms-2">India</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Deadweight(mt):</p>
                    <strong className="text-center ms-2">1000000</strong>
                  </div>
                  <div className="ele_row1">
                    <p className="text-center mb-0">Year of built:</p>
                    <strong className="text-center ms-2">2024</strong>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-2">
            <CardHeader className="p-2">
              <div className="text-center">
                <h5 className="primary-label mb-0">Reporting Information</h5>
              </div>
            </CardHeader>
            <CardBody className="p-2">
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Voyage number:</p>
                    <strong className="text-center ms-2">0101-2024</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Position(Lat):</p>
                    <strong className="text-center ms-2">
                      10<sup>o</sup> 52' 64" N
                    </strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Position(Long):</p>
                    <strong className="text-center ms-2">
                      10<sup>o</sup> 52' 64" E
                    </strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Reporting time(UTC):</p>
                    <strong className="text-center ms-2">
                      Jan 01, 2024, 12:00
                    </strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Difference between local time & UTC:
                    </p>
                    <strong className="text-center ms-2">- 01:00</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Retarded/Advanced:</p>
                    <strong className="text-center ms-2">0</strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Is this noon report?:</p>
                    <strong className="text-center ms-2">Yes</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Is this a beginning of CII adjustment event:
                    </p>
                    <strong className="text-center ms-2">Yes</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Reporting Type:</p>
                    <strong className="text-center ms-2">At Sea</strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Last reporting event:</p>
                    <strong className="text-center ms-2">Noon</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Last reporting operation:
                    </p>
                    <strong className="text-center ms-2">Sea Passage</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Last reporting engine state:
                    </p>
                    <strong className="text-center ms-2">Ring full away</strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Reporting event:</p>
                    <strong className="text-center ms-2">Noon</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Operation since last report:
                    </p>
                    <strong className="text-center ms-2">Sea Passage</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Is strait-canal transit:</p>
                    <strong className="text-center ms-2">No</strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Strait canal transit:</p>
                    <strong className="text-center ms-2">-</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Vessel load condition:</p>
                    <strong className="text-center ms-2">Ballast</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Remaining distance to the next port:
                    </p>
                    <strong className="text-center ms-2">125</strong>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-2">
            <CardHeader className="p-2">
              <div className="text-center">
                <h5 className="primary-label mb-0">Weather Data</h5>
              </div>
            </CardHeader>
            <CardBody className="p-2">
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Distance travelled:</p>
                    <strong className="text-center ms-2">18</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Wind direction:</p>
                    <strong className="text-center ms-2">225</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Wind speed:</p>
                    <strong className="text-center ms-2">4</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Beaufort scale:</p>
                    <strong className="text-center ms-2">2</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Swell direction:</p>
                    <strong className="text-center ms-2">West</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Swell height:</p>
                    <strong className="text-center ms-2">0.8</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Ocean current's speed:</p>
                    <strong className="text-center ms-2">0.2</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Ocean wave height:</p>
                    <strong className="text-center ms-2">0.8</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Sea force:</p>
                    <strong className="text-center ms-2">3</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">
                      Ocean current's direction:
                    </p>
                    <strong className="text-center ms-2">North</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Period in bad weather:</p>
                    <strong className="text-center ms-2">0</strong>
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Air temperature:</p>
                    <strong className="text-center ms-2">8</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Sea temperature:</p>
                    <strong className="text-center ms-2">12</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Gyro course:</p>
                    <strong className="text-center ms-2">240</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p className="text-center mb-2">Barometric pressure:</p>
                    <strong className="text-center ms-2">0</strong>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col lg={6}>
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">
                      CII Adjustment Events
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={5}>
                        <h5 className="font-size-15 text-center mb-0">
                          Current CII Adjustment Event(s)
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">Start Time</h5>
                      </Col>
                      <Col lg={3}>
                        <h5 className="font-size-15 text-center mb-0">End Time</h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={5}>
                      <p className="text-center">Safe Unsafe Navigation</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">2023-01-18 12:00</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">2023-01-18 12:00</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={5}>
                      <p className="text-center">Search and Rescue</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">2023-01-18 12:00</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">-</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} className="ps-0">
              <Card className="mb-2">
                <CardHeader className="ptb10">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">STS Operation</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={6}>
                        <h5 className="font-size-15 text-center mb-0">Operation</h5>
                      </Col>
                      <Col lg={3}>
                        <h5 className="font-size-15 text-center mb-0">Start time (UTC)</h5>
                      </Col>
                      <Col lg={3}>
                        <h5 className="font-size-15 text-center mb-0">End time (UTC)</h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={6}>
                      <p className="text-center">STS Loading</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">01-01-2023</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">01-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="text-center">STS Discharging</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">01-01-2023</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">01-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="text-center">Port Discharging after STS Loading</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">01-01-2023</p>
                    </Col>
                    <Col lg={3}>
                      <p className="text-center">01-01-2023</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">CP Warranties</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row className="p-2">
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="mb-2">Ordered speed:</p>
                        <strong className="ms-2">12.5 knots</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="mb-2">
                          Is there any change since last report:
                        </p>
                        <strong className="ms-2">No</strong>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <div className="ele_row1">
                        <p className="mb-2">Date & time of change(UTC):</p>
                        <strong className="ms-2">20-12-2000</strong>
                      </div>
                    </Col>
                  </Row>
                  <div className="bg-light-subtle ptb10 mt-0">
                    <Row>
                      <Col lg={6}>
                        <h5 className="font-size-15 text-center mb-0">
                          Fuel type
                        </h5>
                      </Col>
                      <Col lg={6}>
                        <h5 className="font-size-15 text-center mb-0">
                          Ordered fuel consumed
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={6}>
                      <p className="text-center">Fuel Oil</p>
                    </Col>
                    <Col lg={6}>
                      <p className="text-center">24 mt/day</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="text-center">Diesel Oil</p>
                    </Col>
                    <Col lg={6}>
                      <p className="text-center">0.2 mt/day</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4} className="ps-0">
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">
                      Cargo Tank Parameters
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Tank Name
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Temperature
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Pressure
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">tank 1</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0 °C</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0 mbar</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">tank 2</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0 °C</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0 mbar</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">tank 3</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0 °C</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0 mbar</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Draft & Displacement</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  <Row>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Fwd draft:</p>
                        <strong className="text-center ms-2">13.5</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Mid draft:</p>
                        <strong className="text-center ms-2">13.5</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Aft draft:</p>
                        <strong className="text-center ms-2">13.5</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Trim:</p>
                        <strong className="text-center ms-2">-</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Displacement:</p>
                        <strong className="text-center ms-2">21000</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={7} className="ps-0">
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Cargo Details</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  <Row>
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Cargo details:</p>
                        <strong className="text-center ms-2">Molases</strong>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Cargo loaded:</p>
                        <strong className="text-center ms-2">3000</strong>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Cargo discharged:</p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="mb-2">Cargo onboard:</p>
                        <strong className="text-center ms-2">44000</strong>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="text-wrap mb-2">
                          Reason for cargo adjustment:
                        </p>
                        <strong className="text-center ms-2">-</strong>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="mb-2">Cargo adjusted:</p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="ele_row1">
                        <p className="mb-2">Cargo used:</p>
                        <strong className="text-right ms-2">0</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Bill of Lading</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  <Row>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">BL figures:</p>
                        <strong className="text-center ms-2">44000</strong>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="mb-2">Ship figures(BL figures):</p>
                        <strong className="text-center ms-2">44000</strong>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Cargo survey:</p>
                        <strong className="text-center ms-2">No</strong>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="mb-2">Surveyor figures(BL figures):</p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} className="ps-0">
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Reefer Containers</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  <Row>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="mb-2">No. of reefer containers loaded:</p>
                        <strong className="text-center ms-2">-</strong>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="mb-2">
                          No. of reefer containers discharged:
                        </p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">
                          Total reefer containers onboard:
                        </p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Ballast Details</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  <Row>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Permanent ballast:</p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Additional ballast:</p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Total ballast</p>
                        <strong className="text-center ms-2">0</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} className="ps-0">
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Ballast Operations</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-2">
                  <Row>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Loaded:</p>
                        <strong className="text-center ms-2">44000</strong>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="ele_row1">
                        <p className="text-center mb-2">Discharged:</p>
                        <strong className="text-center ms-2">44000</strong>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card className="border-bottom mb-0">
            <CardHeader className="p-2">
              <div className="text-center">
                <h5 className="primary-label mb-0">
                  Expected Time of Arrival/Departure
                </h5>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <Row>
                <Col lg={6}>
                  <Card className="sub-card mb-2">
                    <CardHeader className="p-2">
                      <div className="text-center">
                        <h6 className="primary-label mb-0">Arrival</h6>
                      </div>
                    </CardHeader>
                    <CardBody className="p-2">
                      <Row>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Arrival port name:
                            </p>
                            <strong className="text-center ms-2">
                              Casablanca
                            </strong>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Master's ETA at pilot station (UTC):
                            </p>
                            <strong className="text-center ms-2">
                              09-01-2023
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="mb-2">
                              Is arrival port in ECA and vessel is yet to enter
                              the region:
                            </p>
                            <strong className="text-center ms-2">No</strong>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Expected time of arrival at ECA (UTC):
                            </p>
                            <strong className="text-center ms-2">-</strong>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} className="ps-0">
                  <Card className="sub-card mb-2" >
                    <CardHeader className="p-2">
                      <div className="text-center">
                        <h6 className="primary-label mb-0">Departure</h6>
                      </div>
                    </CardHeader>
                    <CardBody className="p-2">
                      <Row>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Departure port name:
                            </p>
                            <strong className="text-center ms-2">
                              Casablanca
                            </strong>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Master's ETD from present port (UTC):
                            </p>
                            <strong className="text-center ms-2">
                              13-01-2023
                            </strong>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="mb-2">
                              Is departure port in ECA and vessel is yet to exit
                              the region:
                            </p>
                            <strong className="text-center ms-2">No</strong>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Expected time of departure from ECA (UTC):
                            </p>
                            <strong className="text-center ms-2">-</strong>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card className="sub-card mb-0">
                    <CardBody className="p-2">
                      <Row>
                        <Col lg={12}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Will vessel transit ECA region:
                            </p>
                            <strong className="text-center ms-2">No</strong>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={7}>
                          <div className="ele_row1">
                            <p className="mb-2">
                              Date & time of arrival at ECA in case vessel is
                              yet to enter the region (UTC):
                            </p>
                            <strong className="text-center ms-2">
                              01-01-2024 12:00
                            </strong>
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">
                              Date & time of departure from ECA (UTC):
                            </p>
                            <strong className="text-center ms-2">-</strong>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col>
              <Card className="border-bottom mt-2 mb-0">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Lfonds- Additional Information</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row>
                    <Col lg={7}>
                      <Card className="mb-2">
                        <CardHeader className="p-2">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">Pilot Details</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <Row>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p className="mb-2">Pilot onboard (UTC):</p>
                                <strong className="ms-2">dd-mm-yyyy</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p className="mb-2">Pilot disembarked (UTC):</p>
                                <strong className="ms-2">dd-mm-yyyy</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p className="mb-2">ETB (UTC):</p>
                                <strong className="ms-2">dd-mm-yyyy</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p className="mb-2">First line ashore (UTC):</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p className="mb-2">All made fast (UTC):</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p className="mb-2">NOR (UTC):</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="ele_row1">
                                <p className="mb-2">Is this a departure from port report?:</p>
                                <strong className="ms-2">Yes</strong>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="mb-2">Water density:</p>
                                <strong className="ms-2">0 gm/ml</strong>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="ele_row1">
                                <p className="mb-2">GM:</p>
                                <strong className="ms-2">0 m</strong>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="ele_row1">
                                <p className="mb-2">Shearing force:</p>
                                <strong className="ms-2">0 %</strong>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="ele_row1">
                                <p className="mb-2">@Frame:</p>
                                <strong className="ms-2">0</strong>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="mb-2">Bending moment:</p>
                                <strong className="ms-2">0 %</strong>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div className="ele_row1">
                                <p className="mb-2">@Frame:</p>
                                <strong className="ms-2">0</strong>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="ele_row1">
                                <p className="mb-2">Torsional moment</p>
                                <strong className="ms-2">0 %</strong>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="ele_row1">
                                <p className="mb-2">All cast off:</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={5} className="ps-0">
                      <Card className="mb-2">
                        <CardHeader className="p-2">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">Anchorage Details</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <Row>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="mb-2">Anchored:</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="mb-2">Anchor aweigh:</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="mb-2">Let go anchor:</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={5}>
                              <div className="ele_row1">
                                <p className="mb-2">Anchorage number:</p>
                                <strong className="ms-2">-</strong>
                              </div>
                            </Col>
                            <Col lg={7}>
                              <div className="ele_row1">
                                <p className="mb-2">Commence heaving anchor:</p>
                                <strong className="ms-2">--:--</strong>
                              </div>
                            </Col>
                            <Col lg={12}>
                              <div className="ele_row1">
                                <p className="mb-2">Reason for anchoring:</p>
                                <Col>
                                  <p className="ms-2">[Content of reason for anchoring]</p>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
                <Row>
                  <Col lg={8}>
                    <Card className="mb-2">
                      <CardHeader className="p-2">
                        <div className="text-center">
                          <h5 className="primary-label mb-0">Other Details</h5>
                        </div>
                      </CardHeader>
                      <CardBody className="p-2">
                        <Row>
                          <Row>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="mb-2">Remaining time to next port:</p>
                                <strong className="ms-2">0 hr</strong>
                              </div>
                            </Col>
                            <Col lg={5}>
                              <div className="ele_row1">
                                <p className="mb-2">Distance to closest storm centre:</p>
                                <strong className="ms-2">0 nm</strong>
                              </div>
                            </Col>
                            <Col lg={3}>
                              <div className="ele_row1">
                                <p className="mb-2">Time of CPA:</p>
                                <strong className="ms-2">0 hr</strong>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="ele_row1">
                                <Col lg={6}>
                                  <p className="mb-2">Is cargo damaged/contaminated since last departure?:</p>
                                </Col>
                                <Col sm={1}>
                                  <strong className="ms-2">Yes</strong>
                                </Col>
                                <Col>
                                  <div className="ele_row1">
                                    <p className="mb-2">[Reason for cargo damage/contamination]</p>
                                  </div>
                                </Col>
                              </div>
                            </Col>
                          </Row>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg={4} className="ps-0">
                    <Card className="mb-2 pb-0">
                      <CardHeader className="p-2">
                        <div className="text-center">
                          <h5 className="primary-label mb-0">Tugs Usage</h5>
                        </div>
                      </CardHeader>
                      <CardBody className="p-2">
                        <Row>
                          <Col>
                            <div className="ele_row1">
                              <p className="mb-2">Number of tugs used:</p>
                              <strong className="ms-2">0</strong>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="ele_row1">
                              <p className="mb-2">Remarks:</p>
                              <p className="ms-2 mb-0">[Remarks]</p>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Card className="mb-0 p-0">
                  <CardHeader className="p-2">
                    <div className="text-center">
                      <h5 className="primary-label mb-0">Port Activities</h5>
                    </div>
                  </CardHeader>
                  <CardBody className="p-2">
                    <Row>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Spares received</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Spares received]</p>
                          </Col>
                        </div>
                      </Col>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Stores/Lubes received</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Stores/Lubes received]</p>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Mail received</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Mail received]</p>
                          </Col>
                        </div>
                      </Col>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Recond items received</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Recond items received]</p>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Items landed for recond</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Items landed for recond]</p>
                          </Col>
                        </div>
                      </Col>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Class surveys carried out</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Class surveys carried out]</p>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Mail sent to office</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Mail sent to office]</p>
                          </Col>
                        </div>
                      </Col>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Crew changes carried out</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Crew changes carried out]</p>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">PSC inspection carried out</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of PSC inspection carried out]</p>
                          </Col>
                        </div>
                      </Col>
                      <Col>
                        <div className="ele_row1">
                          <Col>
                            <p className="mb-2">Expenses paid</p>
                          </Col>
                          <Col sm={1}>
                            <strong>Yes</strong>
                          </Col>
                          <Col>
                            <p className="ms-2">[content of Expenses paid]</p>
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="border-bottom mt-2 mb-0">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">
                      Fuel Consumption & ROB
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Card className="mb-2">
                    <CardBody className="p-2">
                      <Row>
                        <Col lg={4}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">Is EGCS in use?</p>
                            <strong className="text-center ms-2">NO</strong>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">Is SCR in use?</p>
                            <strong className="text-center ms-2">NO</strong>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="ele_row1">
                            <p className="text-center mb-2">Is EGR in use?</p>
                            <strong className="text-center ms-2">NO</strong>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Row>
                    <Col lg={7} className="pe-0">
                      <Card className="mb-2">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Fuel Consumption
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={4}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Machinery name
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Fuel oil
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Diesel oil
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  LNG Bunker
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  LNG Cargo
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Main Engine</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">1.5</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">2.8</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0.8</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Auxiliary Engine</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0.8</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0.8</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Boiler</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">1.8</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0.8</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Inert Gas Generator</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0.8</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">HPP 1 and HPP 2</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0.8</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={5}>
                      <Col lg={12}>
                        <Card className="mb-2">
                          <CardHeader className="p-2">
                            <div className="text-center">
                              <h5 className="primary-label mb-0">
                                Shore Supply
                              </h5>
                            </div>
                          </CardHeader>
                          <CardBody className="p-2">
                            <Row>
                              <Col lg={6}>
                                <div className="ele_row1">
                                  <p className="mb-2">
                                    Is shore supply available?
                                  </p>
                                  <strong className="ms-2">Yes</strong>
                                </div>
                              </Col>
                              <Col lg={6}>
                                <div className="ele_row1">
                                  <p className="mb-2">Is shore supply used?</p>
                                  <strong className="ms-2">Yes</strong>
                                </div>
                              </Col>
                              <Col lg={5}>
                                <div className="ele_row1">
                                  <p className="mb-2">Time on shore supply:</p>
                                  <strong className="ms-2">0 hr</strong>
                                </div>
                              </Col>
                              <Col lg={7}>
                                <div className="ele_row1">
                                  <p className="mb-2">
                                    Quantity of shore supply used:
                                  </p>
                                  <strong className="ms-2">0 kWh</strong>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={8}>
                        <Card>
                          <CardHeader className="p-2">
                            <div className="text-center">
                              <h5 className="primary-label mb-0">
                                Steam Dump Details
                              </h5>
                            </div>
                          </CardHeader>
                          <CardBody className="p-2">
                            <div className="ele_row1">
                              <p className="text-center mb-2">
                                Steam dump valve:
                              </p>
                              <strong className="text-center ms-2">50%</strong>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <Card className="mb-0">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Fuel Consumption- Batch
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Fuel name
                                </h5>
                              </Col>
                              <Col lg={4}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Fuel batches
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  ROB
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Consumption
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Adjustment
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={2}>
                              <p className="text-center">Fuel oil</p>
                            </Col>
                            <Col lg={4}>
                              <p className="text-center">
                                VLSFO/Amsterdam/201/1/2023-01-08
                              </p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">750 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                            <Col lg={4}>
                              <p className="text-center">
                                VLSFO/Las Palmas/176/1/2022-12-31
                              </p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">68.3 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={2}>
                              <p className="text-center">Diesel oil</p>
                            </Col>
                            <Col lg={4}>
                              <p className="text-center">
                                ULSGO/Amsterdam/201/2/2023-01-08
                              </p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">240 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">5.4 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col></Col>
                            <Col lg={4}>
                              <p className="text-center">
                                ULSGO/Amsterdam/176/1/2022-12-31
                              </p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">155 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={4} className="ps-0">
                      <Card className="mb-0 text-center">
                        <CardHeader className="ptb10">
                          <div>
                            <h5 className="primary-label mb-0">Fuel Oil ROB</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={8}>
                                <h5 className="font-size-15 mb-0">Fuel oil</h5>
                              </Col>
                              <Col lg={4}>
                                <h5 className="font-size-15 mb-0">Fuel oil ROB</h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={8}>
                              <p>Main engine system oil</p>
                            </Col>
                            <Col lg={4}>
                              <p>18530</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={8}>
                              <p>Cylinder oil</p>
                            </Col>
                            <Col lg={4}>
                              <p>23430</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={8}>
                              <p>Auxiliary engine sytem oil</p>
                            </Col>
                            <Col lg={4}>
                              <p>9480</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={8}>
                              <p>Other lubricants</p>
                            </Col>
                            <Col lg={4}>
                              <p>12950</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="text-center">
            <Col lg={8} className="pe-0">
              <Card className="mt-2 mb-0">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Voyage Parameter</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={3}>
                        <h5 className="font-size-15 mb-0">Machinery Name</h5>
                      </Col>
                      <Col lg={2}>
                        <h5 className="font-size-15 mb-0">Set Propeller RPM</h5>
                      </Col>
                      <Col lg={2}>
                        <h5 className="font-size-15 mb-0">
                          Average Propeller RPM
                        </h5>
                      </Col>
                      <Col lg={2}>
                        <h5 className="font-size-15 mb-0">Total Revolutions</h5>
                      </Col>
                      <Col lg={2}>
                        <h5 className="font-size-15 mb-0">Propeller Pitch</h5>
                      </Col>
                      <Col lg={1}>
                        <h5 className="font-size-15 mb-0">Slip %</h5>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col lg={3}>
                        <p>Main Engine</p>
                      </Col>
                      <Col lg={2}>
                        <p>0</p>
                      </Col>
                      <Col lg={2}>
                        <p>0</p>
                      </Col>
                      <Col lg={2}>
                        <p>0</p>
                      </Col>
                      <Col lg={2}>
                        <p>0</p>
                      </Col>
                      <Col lg={1}>
                        <p>0</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card className="mt-2 mb-0">
                <CardHeader className="p-2">
                  <div>
                    <h5 className="primary-label mb-0">
                      Machinery Running Hours
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={6}>
                        <h5 className="font-size-15 mb-0">Machinery Name</h5>
                      </Col>
                      <Col lg={6}>
                        <h5 className="font-size-15 mb-0">Running Hours</h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={6}>
                      <p className="mb-2">Main Engine</p>
                    </Col>
                    <Col lg={6}>
                      <p className="ms-2">0 hr</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="mb-2">Auxiliary Engine</p>
                    </Col>
                    <Col lg={6}>
                      <p className="ms-2">0 hr</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="mb-2">Auxiliary Engine 2</p>
                    </Col>
                    <Col lg={6}>
                      <p className="ms-2">0 hr</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p className="mb-2">Auxiliary Engine 3</p>
                    </Col>
                    <Col lg={6}>
                      <p className="ms-2">0 hr</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="text-center">
            <Col lg={7}>
              <Card className="mt-2">
                <CardHeader className="p-2">
                  <div>
                    <h5 className="primary-label mb-0">Energy Parameter</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={3}>
                        <h5 className="font-size-15 mb-0">Machinery Name</h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 ms-0">
                          Energy Developed/Consumed
                        </h5>
                      </Col>
                      <Col lg={3}>
                        <h5 className="font-size-15 mb-0">Running Hours</h5>
                      </Col>
                      <Col lg={2}>
                        <h5 className="font-size-15 mb-0">Average Power</h5>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Col lg={3}>
                        <p>Main Engine</p>
                      </Col>
                      <Col lg={4}>
                        <p>0</p>
                      </Col>
                      <Col lg={3}>
                        <p>0</p>
                      </Col>
                      <Col lg={2}>
                        <p>0</p>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={5} className="ps-0">
              <Card className="mt-2 mb-0">
                <CardHeader className="ptb10">
                  <div>
                    <h5 className="primary-label mb-0">Lube Oil ROB</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={6}>
                        <h5 className="font-size-15 mb-0">Lube oil</h5>
                      </Col>
                      <Col lg={3}>
                        <h5 className="font-size-15 mb-0">
                          Lube oil consumption
                        </h5>
                      </Col>
                      <Col lg={3}>
                        <h5 className="font-size-15 mb-0">Lube oil ROB</h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={6}>
                      <p>Main engine system oil</p>
                    </Col>
                    <Col lg={3}>
                      <p>0</p>
                    </Col>
                    <Col lg={3}>
                      <p>18530</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p>Cylinder oil</p>
                    </Col>
                    <Col lg={3}>
                      <p>0</p>
                    </Col>
                    <Col lg={3}>
                      <p>23430</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p>Auxiliary engine sytem oil</p>
                    </Col>
                    <Col lg={3}>
                      <p>0</p>
                    </Col>
                    <Col lg={3}>
                      <p>9480</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <p>Other lubricants</p>
                    </Col>
                    <Col lg={3}>
                      <p>0</p>
                    </Col>
                    <Col lg={3}>
                      <p>12950</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card className="mt-2 mb-2">
            <CardHeader className="p-2">
              <div className="text-center">
                <h5 className="primary-label mb-0">Bunkering</h5>
              </div>
            </CardHeader>
            <CardBody className="p-2">
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Port of bunkering:</p>
                    <strong className="text-center ms-2">Amsterdam</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Bunkered fuel:</p>
                    <strong className="text-center ms-2">
                      Very low sulphur fuel oil
                    </strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Quantity bunkered:</p>
                    <strong className="text-center ms-2">255 mt</strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Fuel density:</p>
                    <strong className="text-center ms-2">
                      0 kg/m<sup>3</sup>@15<sup>0</sup>C
                    </strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Sulphur percentage:</p>
                    <strong className="text-center ms-2">0 %m/m</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Viscosity:</p>
                    <strong className="text-center ms-2">
                      0 cst@50<sup>0</sup>C
                    </strong>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Bunkering fuel type:</p>
                    <strong className="text-center ms-2">Fuel oil</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>Date of bunkering:</p>
                    <strong className="text-center ms-2">09-01-2023</strong>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="ele_row1">
                    <p>BDN identification number:</p>
                    <strong className="text-center ms-2">12345</strong>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="mb-2">
            <CardHeader className="ptb10">
              <div className="text-center">
                <h5 className="primary-label mb-0">Fuel Debunkering</h5>
              </div>
            </CardHeader>
            <CardBody className="p-0">
              <Col lg={5}>
                <div className="ele_row1">
                  <h5 className="font-size-15 text-center mr-1">
                    Port of debunkering:
                  </h5>
                  <p className="text-center">Amsterdam</p>
                </div>
              </Col>
              <div className="bg-light-subtle ptb10">
                <Row>
                  <Col lg={2}>
                    <h5 className="font-size-15 text-center mb-0">Fuel name</h5>
                  </Col>
                  <Col lg={4}>
                    <h5 className="font-size-15 text-center mb-0">
                      Fuel batches
                    </h5>
                  </Col>
                  <Col lg={2}>
                    <h5 className="font-size-15 text-center mb-0">
                      Quantity debunkered
                    </h5>
                  </Col>
                  <Col lg={2}>
                    <h5 className="font-size-15 text-center mb-0">ROB</h5>
                  </Col>
                  <Col lg={2}>
                    <h5 className="font-size-15 text-center mb-0">
                      Date of debunkering
                    </h5>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={2}>
                  <p className="text-center">Fuel oil</p>
                </Col>
                <Col lg={4}>
                  <p className="text-center">
                    VLSFO/Amsterdam/201/1/2023-01-08
                  </p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">50</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">750</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">09-01-2023</p>
                </Col>
              </Row>
              <Row>
                <Col lg={2}></Col>
                <Col lg={4}>
                  <p className="text-center">
                    VLSFO/Las Palmas/176/1/2022-12-31
                  </p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">0</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">68.3</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">DD-01-2023</p>
                </Col>
              </Row>
              <Row>
                <Col lg={2}>
                  <p className="text-center">Diesel oil</p>
                </Col>
                <Col lg={4}>
                  <p className="text-center">
                    ULSGO/Amsterdam/201/2/2023-01-08
                  </p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">40</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">240</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">09-01-2023</p>
                </Col>
              </Row>
              <Row>
                <Col lg={2}></Col>
                <Col lg={4}>
                  <p className="text-center">
                    ULSGO/Amsterdam/176/1/2022-12-31
                  </p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">0</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">155.3</p>
                </Col>
                <Col lg={2}>
                  <p className="text-center">DD-01-2023</p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col lg={6}>
              <Card className="mb-0">
                <CardHeader className="ptb10">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Lube Oil Bunkering</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Col lg={5}>
                    <div className="ele_row1">
                      <h5 className="font-size-15 text-center mr-1">
                        Port of bunkering:
                      </h5>
                      <p className="text-center">Amsterdam</p>
                    </div>
                  </Col>
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Lube oil name
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Quantity bunkered
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Date of bunkering
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Main engine system oil</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Cylinder oil</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Auxiliary engine sytem oil</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Other lubricants</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6} className="ps-0">
              <Card className="mb-2">
                <CardHeader className="ptb10">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Lube Oil Debunkering</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Col lg={5}>
                    <div className="ele_row1">
                      <h5 className="font-size-15 text-center mr-1">
                        Port of debunkering:
                      </h5>
                      <p className="text-center">-</p>
                    </div>
                  </Col>
                  <div className="bg-light-subtle ptb10">
                    <Row>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Lube oil name
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Quantity bunkered
                        </h5>
                      </Col>
                      <Col lg={4}>
                        <h5 className="font-size-15 text-center mb-0">
                          Date of bunkering
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Main engine system oil</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Cylinder oil</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Auxiliary engine sytem oil</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4}>
                      <p className="text-center">Other lubricants</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">0</p>
                    </Col>
                    <Col lg={4}>
                      <p className="text-center">DD-01-2023</p>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="mb-0">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">
                      Special Operations- Cargo Cooling
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6} className="pe-0">
                      <Card className="mb-0">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Fuel Consumption
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={4}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Machinery name
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Fuel Oil
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Diesel Oil
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  LNG Bunker
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  LNG Cargo
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Boiler</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 mt</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card className="mb-0">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Electrical energy consumption without kWh meter
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Period
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Hours of cargo cooling
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  V
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  I
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Pf
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">
                                  W=kWh
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={2}>
                              <p className="text-center">1300-1600</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0 hr</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Col lg={12}>
                    <Card className="mt-2 border-bottom mb-0">
                      <CardHeader className="p-2">
                        <div className="text-center">
                          <h5 className="primary-label mb-0">
                            Electrical energy consumption with kWh meter
                          </h5>
                        </div>
                      </CardHeader>
                      <CardBody className="p-0">
                        <Row>
                          <Col lg={6}>
                            <Card className="mb-0">
                              <CardHeader className="p-2">
                                <div className="text-center">
                                  <h5 className="primary-label mb-0">
                                    Functional
                                  </h5>
                                </div>
                              </CardHeader>
                              <CardBody className="p-0">
                                <div className="bg-light-subtle ptb10">
                                  <Row>
                                    <Col lg={6}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        Machinery Name
                                      </h5>
                                    </Col>
                                    <Col lg={3}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        Energy Consumed
                                      </h5>
                                    </Col>
                                    <Col lg={3}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        Equivalent Fuel Consumption
                                      </h5>
                                    </Col>
                                  </Row>
                                </div>
                                <div>
                                  <Row>
                                    <Col lg={6}>
                                      <p className="text-center">Power panel for cargo cooling plant</p>
                                    </Col>
                                    <Col lg={3}>
                                      <p className="text-center">0</p>
                                    </Col>
                                    <Col lg={3}>
                                      <p className="text-center">0.0000</p>
                                    </Col>
                                  </Row>
                                </div>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6} className="ps-0">
                            <Card className="mb-0">
                              <CardHeader className="ptb10">
                                <div className="text-center">
                                  <h5 className="primary-label mb-0">
                                    Non-Functional
                                  </h5>
                                </div>
                              </CardHeader>
                              <CardBody className="p-0">
                                <div className="bg-light-subtle ptb10">
                                  <Row>
                                    <Col lg={2}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        Period
                                      </h5>
                                    </Col>
                                    <Col lg={2}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        Hours of cargo cooling
                                      </h5>
                                    </Col>
                                    <Col lg={2}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        V
                                      </h5>
                                    </Col>
                                    <Col lg={2}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        I
                                      </h5>
                                    </Col>
                                    <Col lg={2}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        Pf
                                      </h5>
                                    </Col>
                                    <Col lg={2}>
                                      <h5 className="font-size-15 text-center mb-0">
                                        W=kWh
                                      </h5>
                                    </Col>
                                  </Row>
                                </div>
                                <Row>
                                  <Col lg={2}>
                                    <p className="text-center">-</p>
                                  </Col>
                                  <Col lg={2}>
                                    <p className="text-center">0 hr</p>
                                  </Col>
                                  <Col lg={2}>
                                    <p className="text-center">0</p>
                                  </Col>
                                  <Col lg={2}>
                                    <p className="text-center">0</p>
                                  </Col>
                                  <Col lg={2}>
                                    <p className="text-center">0</p>
                                  </Col>
                                  <Col lg={2}>
                                    <p className="text-center">0</p>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="mb-0 mt-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">
                      Special Operations- Reefer Container
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6}>
                      <Card className="mb-2">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Fuel Consumption
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={6}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Machinery Name
                                </h5>
                              </Col>
                              <Col lg={3}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Fuel Oil
                                </h5>
                              </Col>
                              <Col lg={3}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Diesel Oil
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={6}>
                              <p className="text-center">HPP 1 and 2</p>
                            </Col>
                            <Col lg={3}>
                              <p className="text-center">0 mt</p>
                            </Col>
                            <Col lg={3}>
                              <p className="text-center">0 mt</p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6} className="ps-0">
                      <Card className="mb-2">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Electrical energy consumption without kWh meter
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <Row>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p>Reefer days at sea:</p>
                                <strong className="text-center ms-2">No</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p>Reefer days in port:</p>
                                <strong className="text-center ms-2">No</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p>Equivalent fuel consumption:</p>
                                <strong className="text-center ms-2">No</strong>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="ele_row1">
                                <p>FCelectrical_reefer:</p>
                                <strong className="text-center ms-2">No</strong>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col lg={12}>
                      <Card className="border-bottom mb-0">
                        <CardHeader className="p-2">
                          <div>
                            <h5 className="primary-label mb-0">
                              Electrical energy consumption with kWh meter
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <Row>
                            <Col lg={6}>
                              <Card className="mb-0">
                                <CardHeader className="p-2">
                                  <div>
                                    <h5 className="primary-label mb-0">
                                      Functional
                                    </h5>
                                  </div>
                                </CardHeader>
                                <CardBody className="p-0">
                                  <div className="bg-light-subtle ptb10">
                                    <Row>
                                      <Col lg={6}>
                                        <h5 className="font-size-15 mb-0">
                                          Machinery Name
                                        </h5>
                                      </Col>
                                      <Col lg={3}>
                                        <h5 className="font-size-15 text-center mb-0">
                                          Energy Consumed
                                        </h5>
                                      </Col>
                                      <Col lg={3}>
                                        <h5 className="font-size-15 text-center mb-0">
                                          Equivalent Fuel Consumption
                                        </h5>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div>
                                    <Row>
                                      <Col lg={6}>
                                        <p>Hydraulic Power Pack 4</p>
                                      </Col>
                                      <Col lg={3}>
                                        <p>0 kWh</p>
                                      </Col>
                                      <Col lg={3}>
                                        <p>0.0000</p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={6}>
                                        <p>Hydraulic Power Pack 3</p>
                                      </Col>
                                      <Col lg={3}>
                                        <p>0 kWh</p>
                                      </Col>
                                      <Col lg={3}>
                                        <p>0.0000</p>
                                      </Col>
                                    </Row>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={6} className="ps-0">
                              <Card className="mb-0">
                                <CardHeader className="p-2">
                                  <div>
                                    <h5 className="primary-label mb-0">
                                      Non-Functional
                                    </h5>
                                  </div>
                                </CardHeader>
                                <CardBody className="p-2">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="ele_row1">
                                        <p>Reefer days at sea:</p>
                                        <strong className="text-center ms-2">
                                          No
                                        </strong>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="ele_row1">
                                        <p>Reefer days in port:</p>
                                        <strong className="text-center ms-2">
                                          No
                                        </strong>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="ele_row1">
                                        <p>Equivalent fuel consumption:</p>
                                        <strong className="text-center ms-2">
                                          No
                                        </strong>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="ele_row1">
                                        <p>FCelectrical_reefer:</p>
                                        <strong className="text-center ms-2">
                                          No
                                        </strong>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="text-center">
            <Col lg={12}>
              <Card className="mt-2 mb-2">
                <CardHeader className="p-2">
                  <div>
                    <h5 className="primary-label mb-0">
                      Other Special Operations
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6}>
                      <Card className="mb-0">
                        <CardHeader className="p-2">
                          <div>
                            <h5 className="primary-label mb-0">
                              Fuel Consumption
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={4}>
                                <h5 className="font-size-15 mb-0">
                                  Machinery Name
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 mb-0">Fuel Oil</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 mb-0">
                                  Diesel Oil
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 mb-0">
                                  LNG Bunker
                                </h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 mb-0">LNG Cargo</h5>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row>
                              <Col lg={4}>
                                <p>Main Engine</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={4}>
                                <p>Auxiliary Engine</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                              <Col lg={2}>
                                <p>0</p>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6} className="ps-0">
                      <Card className="mb-0">
                        <CardHeader className="p-2">
                          <div>
                            <h5 className="primary-label mb-0">
                              Electrical energy consumption
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={5}>
                                <h5 className="font-size-15 mb-0">
                                  Machinery Name
                                </h5>
                              </Col>
                              <Col lg={3}>
                                <h5 className="font-size-15 mb-0">
                                  Energy Consumed
                                </h5>
                              </Col>
                              <Col lg={4}>
                                <h5 className="font-size-15 mb-0">
                                  Equivalent Electrical energy consumption
                                </h5>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row>
                              <Col lg={5}>
                                <p>Hydraulic Power Pack 3</p>
                              </Col>
                              <Col lg={3}>
                                <p>0</p>
                              </Col>
                              <Col lg={4}>
                                <p>0.0000</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={5}>
                                <p>Hydraulic Power Pack 4</p>
                              </Col>
                              <Col lg={3}>
                                <p>0</p>
                              </Col>
                              <Col lg={4}>
                                <p>0.0000</p>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Col lg={12}>
            <Card className="mb-2">
              <CardHeader className="p-2">
                <div className="text-center">
                  <h5 className="primary-label mb-0">Fresh Water</h5>
                </div>
              </CardHeader>
              <CardBody className="p-2">
                <Row>
                  <Col lg={4}>
                    <div className="ele_row1">
                      <p>Amount of fresh water produced:</p>
                      <strong className="text-center ms-2">0</strong>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="ele_row1">
                      <p>Amount of fresh water received:</p>
                      <strong className="text-center ms-2">0</strong>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="ele_row1">
                      <p>Amount of domestic water consumed:</p>
                      <strong className="text-center ms-2">1</strong>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <div className="ele_row1">
                      <p>Amount of distilled water consumed:</p>
                      <strong className="text-center ms-2">0</strong>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="ele_row1">
                      <p>Fresh water ROB:</p>
                      <strong className="text-center ms-2">149</strong>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Row>
            <Col lg={12}>
              <Card className="mb-2">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">
                      Proactive- Additional Information
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row>
                    <Col lg={6}>
                      <Card className="mb-0">
                        <CardHeader className="p-2">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Other Details
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-2">
                          <Row>
                            <Col lg={8}>
                              <div className="ele_row1">
                                <p className="text-center mb-2">
                                  Last under water hull cleaning done:
                                </p>
                                <strong className="text-center ms-2">
                                  01-01-2024
                                </strong>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="text-center mb-2">FOE factor:</p>
                                <strong className="text-center ms-2">5</strong>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={8}>
                              <div className="ele_row1">
                                <p className="text-center mb-2">
                                  Avg. torque :
                                </p>
                                <strong className="text-center ms-2">-</strong>
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="ele_row1">
                                <p className="text-center mb-2">LNG FOE:</p>
                                <strong className="text-center ms-2">0</strong>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg={6} className="ps-0">
                      <Card className="mb-0">
                        <CardHeader className="p-2">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">
                              Average Load Details
                            </h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle ptb10">
                            <Row>
                              <Col lg={6}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Machinery Name
                                </h5>
                              </Col>
                              <Col lg={6}>
                                <h5 className="font-size-15 text-center mb-0">
                                  Average Load Percentage
                                </h5>
                              </Col>
                            </Row>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="border-bottom mb-0">
                <CardHeader className="p-2">
                  <div className="text-center">
                    <h5 className="primary-label mb-0">Lfonds- Additional Information</h5>
                  </div>
                </CardHeader>
                <CardBody className="p-0">
                  <Row>
                    <Col lg={12}>
                      <Card className="border-bottom mb-0">
                        <CardHeader className="p-2">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">Engine Parameters</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <Row>
                            <Col lg={8}>
                              <Card className="mb-2">
                                <CardHeader className="p-2">
                                  <div className="text-center">
                                    <h5 className="primary-label mb-0">Main Engine</h5>
                                  </div>
                                </CardHeader>
                                <CardBody className="p-2">
                                  <Row>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Load indicator:</p>
                                        <strong className="ms-2">0</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Engine RPM:</p>
                                        <strong className="ms-2">105.58</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Slip percentage:</p>
                                        <strong className="ms-2">21.24</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Propeller pitch:</p>
                                        <strong className="ms-2">4.2542</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Output:</p>
                                        <strong className="ms-2">kW</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Percentage MCR:</p>
                                        <strong className="ms-2">0 %</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">M/E exh. temp. max:</p>
                                        <strong className="ms-2">0 °C</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">M/E exh. temp. min:</p>
                                        <strong className="ms-2">0 °C</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">T/C 1 inlet exh. temp.:</p>
                                        <strong className="ms-2">0 °C</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">T/C 1 outlet exh. temp.:</p>
                                        <strong className="ms-2">0 °C</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">T/C 2 inlet exh. temp.:</p>
                                        <strong className="ms-2">0 °C</strong>
                                      </div>
                                    </Col>
                                    <Col lg={4}>
                                      <div className="ele_row1">
                                        <p className="mb-2">T/C 2 outlet exh. temp.:</p>
                                        <strong className="ms-2">0 °C</strong>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={4} className="ps-0">
                              <Card className="mp-2">
                                <CardHeader className="p-2">
                                  <div className="text-center">
                                    <h5 className="primary-label mb-0">AE 1</h5>
                                  </div>
                                </CardHeader>
                                <CardBody className="p-2">
                                  <Row>
                                    <Col lg={6}>
                                      <div className="ele_row1">
                                        <p className="mb-2">Alternator load:</p>
                                        <strong className="ms-2">0</strong>
                                      </div>
                                    </Col>
                                    <Col lg={6}>
                                      <div className="ele_row1">
                                        <p className="mb-2">A/E scavenge pressure:</p>
                                        <strong className="ms-2">0</strong>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="ele_row1">
                                    <p className="mb-2">A/E avg exhaust temperature:</p>
                                    <strong className="ms-2">0</strong>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Card className="mb-2">
                    <CardHeader className="p-2">
                      <div className="text-center">
                        <h5 className="primary-label mb-0">Thermal Oil Heaters</h5>
                      </div>
                    </CardHeader>
                    <CardBody className="p-2">
                      <Row>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Name:</p>
                            <strong className="ms-2">-</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Outlet temperature:</p>
                            <strong className="ms-2">0 °C</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Inlet temperature:</p>
                            <strong className="ms-2">0 °C</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Exhaust temperature:</p>
                            <strong className="ms-2">0 °C</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Outlet press:</p>
                            <strong className="ms-2">0</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Inlet press:</p>
                            <strong className="ms-2">0</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">Temperature set point:</p>
                            <strong className="ms-2">0 °C</strong>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="ele_row1">
                            <p className="mb-2">TH expansion tank level:</p>
                            <strong className="ms-2">0 m</strong>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="border-bottom mb-0">
                    <CardHeader className="ptb10">
                      <div className="text-center">
                        <h5 className="primary-label mb-0">Sludge & Bilge Water Details</h5>
                      </div>
                    </CardHeader>
                    <CardBody className="p-0">
                      <Card className="mb-0">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">Sludge Water</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-light-subtle bg-light-subtle-light ptb10">
                            <Row>
                              <Col lg={4}>
                                <h5 className="font-size-15 text-center mb-0">Tank name/number</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Daily production</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Total Quantity</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Current filling ratio</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Remarks</h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Sludge Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Bilge Sludge Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">FO Drain Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">LO Drain Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">SCV Box Drain Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Stuff Box Drain Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">No1 WO Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">No2 WO Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mt-2 mb-0">
                        <CardHeader className="ptb10">
                          <div className="text-center">
                            <h5 className="primary-label mb-0">Bilge Water</h5>
                          </div>
                        </CardHeader>
                        <CardBody className="p-0">
                          <div className="bg-body-tertiary ptb10">
                            <Row>
                              <Col lg={4}>
                                <h5 className="font-size-15 text-center mb-0">Tank name/number</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Daily production</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Total Quantity</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Current filling ratio</h5>
                              </Col>
                              <Col lg={2}>
                                <h5 className="font-size-15 text-center mb-0">Remarks</h5>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Col lg={4}>
                              <p className="text-center">Bilge Tank</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center">0</p>
                            </Col>
                            <Col lg={2}>
                              <p className="text-center"></p>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container >
      </div >
    </React.Fragment >
  );
};
export default SingleReportView;
