import ErrorComponent from "Components/ErrorComponent";
import Loading from "Components/Loading";
import ReportDetailsHeader from "Components/ReportDetailsHeader";
import { errorToast, successToast } from "Components/Toasts";
import { errResponse } from "GenericForms/Helper";
import {
    loadAuxEngineMaxSFOC,
    loadMachineryEnergyGroup,
    loadMachineryFuelGroup,
    loadOtherSpOperationEnergy,
    loadOtherSpOperationFuel,
    vesselFuel,
} from "VesselMaster/vesselMaster.hooks";
import env from "environment/env";
import { Field, FieldArray, FieldProps, Formik } from "formik";
import apiGlobal from "global/api.global";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { queryClient } from "react-query/queryClient";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Input,
    Label,
    Form,
    Button,
} from "reactstrap";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";

interface OtherSpecialOperationType {
    ReportID: number;
    VesselID: number;
    VoyageID: number;
    activeTab: number;
    toggleTab: any;
    tabsIdList: any[];
    record: any;
    lastRecord: any;
    toggleDynamicTabs: any;
    previousTabIndex: number;
    tabsList: any[];
}

const OtherSpecialOperationComponent = ({
    ReportID,
    VesselID,
    VoyageID,
    toggleTab,
    tabsIdList,
    record,
    toggleDynamicTabs,
    previousTabIndex,
    tabsList,
}: OtherSpecialOperationType) => {
    /** State variables */
    const [visibleFuelMachineries] = useState<Array<any>>([]);
    const [visibleEnergyMachineries] = useState<Array<any>>([]);
    /** State variables end */

    /** Queries */
    /** Fetch Vessel distinct fuel  */
    const {
        data: fuelTypes,
        isLoading: fuelTypesLoading,
        isError: fuelTypesError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.fuel.key, VesselID],
        async () => {
            return await vesselFuel(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Machinary fuel Group */
    const {
        data: machineryFuelGroup,
        isLoading: machineryFuelGroupLoading,
        isError: machineryFuelGroupError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryFuelGroup.key, VesselID],
        async () => {
            return await loadMachineryFuelGroup(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Machinary energy Group */
    const {
        data: machineryEnergyGroup,
        isLoading: machineryEnergyGroupLoading,
        isError: machineryEnergyGroupError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.vessel.MachinaryEnergyGroup.key, VesselID],
        async () => {
            return await loadMachineryEnergyGroup(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Max SFOC of vessel's auxiliary engines */
    const {
        data: AuxEngineMaxSFOC,
        isLoading: AuxEngineMaxSFOCLoading,
        isError: AuxEngineMaxSFOCError,
    } = useQuery(
        [queryKeyes.vessel.AuxEngineMaxSFOC.key, VesselID],
        async () => {
            return await loadAuxEngineMaxSFOC(VesselID);
        },
        { staleTime: Infinity }
    );
    /** Fuel consumption data object used for edit */
    const {
        data: OtherOperationFuelObject,
        isLoading: OtherOperationFuelObjectLoading,
        isError: OtherOperationFuelObjectError,
    } = useQuery(
        [queryKeyes.vessel.OtherSpOperationFuelObject.key, VesselID],
        async () => {
            return await loadOtherSpOperationFuel(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Energy consumption data object used for edit */
    const {
        data: OtherOperationEnergyObject,
        isLoading: OtherOperationEnergyObjectLoading,
        isError: OtherOperationEnergyObjectError,
    } = useQuery(
        [queryKeyes.vessel.OtherSpOperationEnergyObject.key, VesselID],
        async () => {
            return await loadOtherSpOperationEnergy(VesselID, ReportID);
        },
        { staleTime: Infinity }
    );
    /** Queries end */

    /** Assign values to formik's fuel consumption initial object */
    const getInitialFuelConsumptionValue = () => {
        let obj: any[] = [];
        if (
            OtherOperationFuelObject?.length > 0 &&
            OtherOperationFuelObject.id > 0
        ) {
            return OtherOperationFuelObject;
        } else if (
            obj !== undefined &&
            machineryFuelGroup !== undefined &&
            fuelTypes !== undefined &&
            obj.length <= machineryFuelGroup.length * fuelTypes.length
        ) {
            machineryFuelGroup.flatMap((machine: any) => {
                fuelTypes.map((fuel: any) => {
                    obj.push({
                        vessel_machinery_fc_group_name:
                            machine.vessel_machinery_fc_group_name,
                        fuel_consumption_value: 0,
                        vessel: VesselID,
                        voyage_information: VoyageID,
                        vessel_reporting_information: ReportID,
                        vessel_machinery_fc_group: machine.id,
                        fuel_name: fuel.fuel_type,
                        visible: false,
                    });
                    return fuel;
                });
                return machine;
            });
            return obj;
        } else {
            return null;
        }
    };

    /** Assign values to formik's energy consumption initial object */
    const getInitialEnergyConsumptionValue = () => {
        let obj: any[] = [];
        if (
            OtherOperationEnergyObject?.length > 0 &&
            OtherOperationEnergyObject.id > 0
        ) {
            return OtherOperationEnergyObject;
        } else if (
            obj !== undefined &&
            machineryEnergyGroup !== undefined &&
            obj.length <= machineryEnergyGroup.length
        ) {
            machineryEnergyGroup.map((machine: any) => {
                obj.push({
                    vessel_machinery_ec_group_name:
                        machine.vessel_machinery_ec_group_name,
                    energy_consumption_value: 0,
                    equivalent_fuel_consumption: 0,
                    vessel: VesselID,
                    voyage_information: VoyageID,
                    vessel_reporting_information: ReportID,
                    vessel_machinery_ec_group: machine.id,
                    visible: false,
                });
                return machine;
            });
            return obj;
        } else {
            return null;
        }
    };

    /** useEffect */
    useEffect(() => {
        OtherSpecialOperationFormik.initialValues.otherOperation[0].fuelConsumption =
            getInitialFuelConsumptionValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineryFuelGroup, fuelTypes]);
    useEffect(() => {
        machineryFuelGroup?.map((fuel: any) => {
            visibleFuelMachineries.push({
                id: fuel.id,
                machine: fuel.vessel_machinery_fc_group_name,
                visible: false,
            });
            return fuel;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineryFuelGroup]);
    useEffect(() => {
        machineryEnergyGroup?.map((energy: any) => {
            visibleEnergyMachineries.push({
                id: energy.id,
                machine: energy.vessel_machinery_ec_group_name,
                visible: false,
            });
            return energy;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [machineryEnergyGroup]);
    useEffect(() => {
        OtherSpecialOperationFormik.initialValues.otherOperation[0].fuelConsumption =
            getInitialFuelConsumptionValue();
        OtherSpecialOperationFormik.initialValues.otherOperation[0].energyConsumption =
            getInitialEnergyConsumptionValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        OtherOperationFuelObject,
        OtherOperationEnergyObject,
        machineryFuelGroup,
        fuelTypes,
        machineryEnergyGroup,
    ]);
    /** useEffect end */

    /** Other Special Operation's formik object */
    const OtherSpecialOperationFormik = {
        initialValues: {
            otherOperation: [
                {
                    fuelConsumption: getInitialFuelConsumptionValue(),
                    energyConsumption: getInitialEnergyConsumptionValue(),
                },
            ],
        },
    };

    return (
        <Card className="p-0 mb-0 border-0">
            <CardHeader className="p-2">
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">
                                Other Special Operations
                            </h4>
                            <p className="card-title-desc pos-start">
                                All readings since last report
                            </p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            {(machineryFuelGroupLoading ||
                machineryEnergyGroupLoading ||
                fuelTypesLoading ||
                AuxEngineMaxSFOCLoading ||
                OtherOperationFuelObjectLoading ||
                OtherOperationEnergyObjectLoading) && (
                    <Loading message="Loading required data!" />
                )}
            {(machineryFuelGroupError ||
                machineryEnergyGroupError ||
                fuelTypesError ||
                AuxEngineMaxSFOCError) && (
                    <ErrorComponent message="Unable to load required data!" />
                )}
            {OtherOperationFuelObjectError && getInitialFuelConsumptionValue()}
            {OtherOperationEnergyObjectError && getInitialEnergyConsumptionValue()}
            {!(
                machineryFuelGroupLoading ||
                machineryEnergyGroupLoading ||
                fuelTypesLoading ||
                AuxEngineMaxSFOCLoading
            ) &&
                !(
                    machineryFuelGroupError ||
                    machineryEnergyGroupError ||
                    fuelTypesError ||
                    AuxEngineMaxSFOCError
                ) && (
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false);
                            if (
                                OtherOperationFuelObject?.length > 0 &&
                                OtherOperationFuelObject.id > 0
                            ) {
                                values.otherOperation.map((fuel: any) => {
                                    fuel.fuelConsumption.map((cons: any) => {
                                        apiGlobal
                                            .put(`/other_special_operation_fuel/${cons.id}/`, cons)
                                            .then((res) => {
                                                if (res.status === 201 || res.status === 200) {
                                                    successToast("Data saved successfully!");
                                                    queryClient.invalidateQueries(
                                                        queryKeyes.vessel.OtherSpOperationFuelObject.key
                                                    );
                                                    if (env?.form_validation === true) {
                                                        toggleDynamicTabs(previousTabIndex + 1);
                                                    }
                                                }
                                            })
                                            .catch((err) => {
                                                if (errResponse.includes(err.response.status)) {
                                                    errorToast(
                                                        "Internal error occured, please contact the admin"
                                                    );
                                                }
                                            });
                                        return "";
                                    });
                                    return "";
                                });
                            } else {
                                values.otherOperation.map((fuel: any) => {
                                    apiGlobal
                                        .post(
                                            `/other_special_operation_fuel/`,
                                            fuel.fuelConsumption
                                        )
                                        .then((res) => {
                                            if (res.status === 201 || res.status === 200) {
                                                successToast("Data saved successfully!");
                                                queryClient.invalidateQueries(
                                                    queryKeyes.vessel.OtherSpOperationFuelObject.key
                                                );
                                                if (env?.form_validation === true) {
                                                    toggleDynamicTabs(previousTabIndex + 1);
                                                }
                                            }
                                        })
                                        .catch((err) => {
                                            if (errResponse.includes(err.response.status)) {
                                                errorToast(
                                                    "Internal error occured, please contact the admin"
                                                );
                                            }
                                        });
                                    return "";
                                });
                            }
                            if (
                                OtherOperationEnergyObject?.length > 0 &&
                                OtherOperationEnergyObject[0].id > 0
                            ) {
                                values.otherOperation.map((energy: any) => {
                                    energy.energyConsumption.map((cons: any) => {
                                        apiGlobal
                                            .put(`/other_special_operation_energy/${cons.id}/`, cons)
                                            .then((res) => {
                                                if (res.status === 201 || res.status === 200) {
                                                    successToast("Data saved successfully!");
                                                    queryClient.invalidateQueries(
                                                        queryKeyes.vessel.OtherSpOperationFuelObject.key
                                                    );
                                                }
                                            })
                                            .catch((err) => {
                                                if (errResponse.includes(err.response.status)) {
                                                    errorToast(
                                                        "Internal error occured, please contact the admin"
                                                    );
                                                }
                                            });
                                        return "";
                                    });
                                    return "";
                                });
                            } else {
                                values.otherOperation.map((energy: any) => {
                                    apiGlobal
                                        .post(
                                            `/other_special_operation_energy/`,
                                            energy.energyConsumption
                                        )
                                        .then((res) => {
                                            if (res.status === 201 || res.status === 200) {
                                                successToast("Data saved successfully!");
                                                queryClient.invalidateQueries(
                                                    queryKeyes.vessel.OtherSpOperationFuelObject.key
                                                );
                                            }
                                        })
                                        .catch((err) => {
                                            if (errResponse.includes(err.response.status)) {
                                                errorToast(
                                                    "Internal error occured, please contact the admin"
                                                );
                                            }
                                        });
                                    return "";
                                });
                            }
                        }}
                        initialValues={OtherSpecialOperationFormik.initialValues}
                    >
                        {(props) => (
                            <Form autoComplete="off" onSubmit={props.handleSubmit} noValidate>
                                <CardBody className="p-0">
                                    <FieldArray name="otherOperation">
                                        {({ push }) => (
                                            <React.Fragment>
                                                {props?.values &&
                                                    props?.values?.otherOperation.map(
                                                        (other: any, index: number) => {
                                                            return (
                                                                <>
                                                                    <Row className="m-0">
                                                                        <Card className="mb-0 border-0">
                                                                            <CardHeader className="p-2">
                                                                                <div className="text-center">
                                                                                    <Row>
                                                                                        <Col className="ps-0">
                                                                                            <h4 className="page_title pos-start mb-0">
                                                                                                Fuel Consumption
                                                                                            </h4>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </CardHeader>
                                                                            <CardBody className="p-0 mt-2">
                                                                                <Row>
                                                                                    {machineryFuelGroup?.map(
                                                                                        (fuel: any, indx: number) => {
                                                                                            return (
                                                                                                <Col lg={3}>
                                                                                                    <div className="ele_row1">
                                                                                                        <div className="form-check mb-2">
                                                                                                            <Input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id={`machinery_${indx}`}
                                                                                                                name={`otherOperation.${index}.fuelConsumption.applicable.${indx}.is_used`}
                                                                                                                onChange={(e: any) => {
                                                                                                                    props.handleChange(e);
                                                                                                                    if (
                                                                                                                        e.target.checked ===
                                                                                                                        true
                                                                                                                    ) {
                                                                                                                        other?.fuelConsumption
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.vessel_machinery_fc_group ===
                                                                                                                                    fuel.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (fuel: any) => {
                                                                                                                                    fuel.visible =
                                                                                                                                        true;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                        visibleFuelMachineries
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.id ===
                                                                                                                                    fuel.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (fuel: any) => {
                                                                                                                                    fuel.visible =
                                                                                                                                        true;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                    } else {
                                                                                                                        other?.fuelConsumption
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.vessel_machinery_fc_group ===
                                                                                                                                    fuel.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (fuel: any) => {
                                                                                                                                    fuel.visible =
                                                                                                                                        false;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                        visibleFuelMachineries
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.id ===
                                                                                                                                    fuel.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (fuel: any) => {
                                                                                                                                    fuel.visible =
                                                                                                                                        false;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <Label
                                                                                                            for={`machinery_${indx}`}
                                                                                                        >
                                                                                                            {fuel.machinery_name}
                                                                                                        </Label>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Row>
                                                                                <div className="table-responsive">
                                                                                    <table className="table mb-0">
                                                                                        <thead className="table-light">
                                                                                            <tr>
                                                                                                <th className="p-2 align-middle sr-no-width ">
                                                                                                    #
                                                                                                </th>
                                                                                                <th
                                                                                                    className="p-2 align-middle"
                                                                                                    style={{ width: "20%" }}
                                                                                                >
                                                                                                    Machinery name
                                                                                                </th>
                                                                                                {fuelTypes &&
                                                                                                    fuelTypes?.map(
                                                                                                        (fuelType: any) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    <th
                                                                                                                        className="p-2 align-middle asteric text-center"
                                                                                                                        style={{
                                                                                                                            width: "15%",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {
                                                                                                                            fuelType.fuel_type_name
                                                                                                                        }
                                                                                                                    </th>
                                                                                                                </>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <React.Fragment>
                                                                                                {visibleFuelMachineries?.filter(
                                                                                                    (item: any) =>
                                                                                                        item.visible === true
                                                                                                ).length === 0 && (
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                colSpan={4}
                                                                                                                className="text-center"
                                                                                                            >
                                                                                                                Please select the
                                                                                                                applicable machinery from
                                                                                                                above
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                            </React.Fragment>
                                                                                            {visibleFuelMachineries
                                                                                                ?.filter(
                                                                                                    (item: any) =>
                                                                                                        item.visible === true
                                                                                                )
                                                                                                .map(
                                                                                                    (cons: any, indx: number) => {
                                                                                                        return (
                                                                                                            <tr>
                                                                                                                <td className="p-2 align-middle text-center">
                                                                                                                    {indx + 1}
                                                                                                                </td>
                                                                                                                <td className="p-2 align-middle">
                                                                                                                    <Label className="mb-0">
                                                                                                                        {cons.machine}
                                                                                                                    </Label>
                                                                                                                </td>
                                                                                                                {other.fuelConsumption
                                                                                                                    .filter(
                                                                                                                        (item: any) =>
                                                                                                                            item.visible ===
                                                                                                                            true &&
                                                                                                                            cons.id ===
                                                                                                                            item.vessel_machinery_fc_group
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (fuelCons: any) => {
                                                                                                                            return (
                                                                                                                                <td className="p-2 text-center">
                                                                                                                                    <div className="d-inline-block">
                                                                                                                                        <div className="input-group max-width-7">
                                                                                                                                            <Field
                                                                                                                                                type="text"
                                                                                                                                                className="form-control text-right"
                                                                                                                                                name={`otherOperation.${index}.fuelConsumption.${other.fuelConsumption.indexOf(
                                                                                                                                                    fuelCons
                                                                                                                                                )}.fuel_consumption_value`}
                                                                                                                                            />
                                                                                                                                            <div className="input-group-text">
                                                                                                                                                mt
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    )}
                                                                                                            </tr>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Row>
                                                                    <Row className="m-0">
                                                                        <Card className="mb-2 border-0">
                                                                            <CardHeader className="p-2">
                                                                                <div className="text-center">
                                                                                    <Row>
                                                                                        <Col className="ps-0">
                                                                                            <h4 className="page_title pos-start mb-0">
                                                                                                Energy Consumption
                                                                                            </h4>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </div>
                                                                            </CardHeader>
                                                                            <CardBody className="p-0 mt-2">
                                                                                <Row>
                                                                                    {machineryEnergyGroup?.map(
                                                                                        (energy: any, indx: number) => {
                                                                                            return (
                                                                                                <Col lg={3}>
                                                                                                    <div className="ele_row1">
                                                                                                        <div className="form-check mb-2">
                                                                                                            <Input
                                                                                                                className="form-check-input"
                                                                                                                type="checkbox"
                                                                                                                id={`energy_machine${indx}`}
                                                                                                                name={`otherOperation.${index}.energyConsumption.applicable.${indx}.is_used`}
                                                                                                                onChange={(e: any) => {
                                                                                                                    props.handleChange(e);
                                                                                                                    if (
                                                                                                                        e.target.checked ===
                                                                                                                        true
                                                                                                                    ) {
                                                                                                                        other?.energyConsumption
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.vessel_machinery_ec_group ===
                                                                                                                                    energy.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    energy: any
                                                                                                                                ) => {
                                                                                                                                    energy.visible =
                                                                                                                                        true;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                        visibleEnergyMachineries
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.id ===
                                                                                                                                    energy.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    energy: any
                                                                                                                                ) => {
                                                                                                                                    energy.visible =
                                                                                                                                        true;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                    } else {
                                                                                                                        other?.energyConsumption
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.vessel_machinery_ec_group ===
                                                                                                                                    energy.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    energy: any
                                                                                                                                ) => {
                                                                                                                                    energy.visible =
                                                                                                                                        false;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                        visibleEnergyMachineries
                                                                                                                            ?.filter(
                                                                                                                                (item: any) =>
                                                                                                                                    item.id ===
                                                                                                                                    energy.id
                                                                                                                            )
                                                                                                                            .map(
                                                                                                                                (
                                                                                                                                    energy: any
                                                                                                                                ) => {
                                                                                                                                    energy.visible =
                                                                                                                                        false;
                                                                                                                                    return "";
                                                                                                                                }
                                                                                                                            );
                                                                                                                    }
                                                                                                                }}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <Label
                                                                                                            for={`energy_machine${indx}`}
                                                                                                        >
                                                                                                            {energy.machinery_name}
                                                                                                        </Label>
                                                                                                    </div>
                                                                                                </Col>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </Row>
                                                                                <div className="table-responsive">
                                                                                    <table className="table mb-0">
                                                                                        <thead className="table-light">
                                                                                            <tr>
                                                                                                <th className="p-2 align-middle sr-no-width">
                                                                                                    #
                                                                                                </th>
                                                                                                <th
                                                                                                    className="p-2 align-middle text-center"
                                                                                                    style={{ width: "15%" }}
                                                                                                >
                                                                                                    Machinery name
                                                                                                </th>
                                                                                                <th
                                                                                                    className="p-2 align-middle asteric text-center"
                                                                                                    style={{ width: "35%" }}
                                                                                                >
                                                                                                    Energy consumed
                                                                                                </th>
                                                                                                <th
                                                                                                    className="p-2 align-middle text-center"
                                                                                                    style={{ width: "25%" }}
                                                                                                >
                                                                                                    Equivalent energy consumption
                                                                                                </th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            <>
                                                                                                {visibleEnergyMachineries?.filter(
                                                                                                    (item: any) =>
                                                                                                        item.visible === true
                                                                                                ).length === 0 && (
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                colSpan={4}
                                                                                                                className="text-center"
                                                                                                            >
                                                                                                                Please select the
                                                                                                                applicable machinery from
                                                                                                                above
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    )}
                                                                                            </>
                                                                                            {visibleEnergyMachineries
                                                                                                ?.filter(
                                                                                                    (item: any) =>
                                                                                                        item.visible === true
                                                                                                )
                                                                                                .map(
                                                                                                    (cons: any, indx: number) => {
                                                                                                        return (
                                                                                                            <tr>
                                                                                                                <td className="p-2 align-middle text-center">
                                                                                                                    {indx + 1}
                                                                                                                </td>
                                                                                                                <td className="p-2 align-middle text-center">
                                                                                                                    <Label>
                                                                                                                        {cons.machine}
                                                                                                                    </Label>
                                                                                                                </td>
                                                                                                                {other.energyConsumption
                                                                                                                    .filter(
                                                                                                                        (item: any) =>
                                                                                                                            item.visible ===
                                                                                                                            true &&
                                                                                                                            cons.id ===
                                                                                                                            item.vessel_machinery_ec_group
                                                                                                                    )
                                                                                                                    .map(
                                                                                                                        (
                                                                                                                            energyCons: any
                                                                                                                        ) => {
                                                                                                                            return (
                                                                                                                                <>
                                                                                                                                    <td className="p-2 align-middle text-center">
                                                                                                                                        <div className="d-inline-block">
                                                                                                                                            <div className="input-group">
                                                                                                                                                <Field
                                                                                                                                                    name={`otherOperation.${index}.energyConsumption.${props.values.otherOperation[
                                                                                                                                                        index
                                                                                                                                                    ].energyConsumption.indexOf(
                                                                                                                                                        energyCons
                                                                                                                                                    )}.energy_consumption_value`}
                                                                                                                                                >
                                                                                                                                                    {({
                                                                                                                                                        field,
                                                                                                                                                        form,
                                                                                                                                                    }: FieldProps) => (
                                                                                                                                                        <Input
                                                                                                                                                            type="text"
                                                                                                                                                            className="form-control max-width-7 text-right"
                                                                                                                                                            name={
                                                                                                                                                                field.name
                                                                                                                                                            }
                                                                                                                                                            onChange={(
                                                                                                                                                                e: any
                                                                                                                                                            ) => {
                                                                                                                                                                props.handleChange(
                                                                                                                                                                    e
                                                                                                                                                                );
                                                                                                                                                                form.setFieldValue(
                                                                                                                                                                    `otherOperation.${index}.energyConsumption.${props.values.otherOperation[
                                                                                                                                                                        index
                                                                                                                                                                    ].energyConsumption.indexOf(
                                                                                                                                                                        energyCons
                                                                                                                                                                    )}.equivalent_fuel_consumption`,
                                                                                                                                                                    (
                                                                                                                                                                        (AuxEngineMaxSFOC.max_eedi_eexi_technical_file *
                                                                                                                                                                            energyCons.energy_consumption_value) /
                                                                                                                                                                        1000000
                                                                                                                                                                    ).toFixed(
                                                                                                                                                                        4
                                                                                                                                                                    )
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            onBlur={(
                                                                                                                                                                e: any
                                                                                                                                                            ) => {
                                                                                                                                                                props.handleChange(
                                                                                                                                                                    e
                                                                                                                                                                );
                                                                                                                                                                form.setFieldValue(
                                                                                                                                                                    `otherOperation.${index}.energyConsumption.${props.values.otherOperation[
                                                                                                                                                                        index
                                                                                                                                                                    ].energyConsumption.indexOf(
                                                                                                                                                                        energyCons
                                                                                                                                                                    )}.equivalent_fuel_consumption`,
                                                                                                                                                                    (
                                                                                                                                                                        (AuxEngineMaxSFOC.max_eedi_eexi_technical_file *
                                                                                                                                                                            energyCons.energy_consumption_value) /
                                                                                                                                                                        1000000
                                                                                                                                                                    ).toFixed(
                                                                                                                                                                        4
                                                                                                                                                                    )
                                                                                                                                                                );
                                                                                                                                                            }}
                                                                                                                                                            defaultValue={
                                                                                                                                                                energyCons.energy_consumption_value
                                                                                                                                                            }
                                                                                                                                                        />
                                                                                                                                                    )}
                                                                                                                                                </Field>
                                                                                                                                                <div className="input-group-text">
                                                                                                                                                    mt
                                                                                                                                                </div>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td className="p-2 align-middle text-center">
                                                                                                                                        <Label>
                                                                                                                                            {(
                                                                                                                                                (AuxEngineMaxSFOC.max_eedi_eexi_technical_file *
                                                                                                                                                    energyCons.energy_consumption_value) /
                                                                                                                                                1000000
                                                                                                                                            ).toFixed(
                                                                                                                                                4
                                                                                                                                            )}
                                                                                                                                        </Label>
                                                                                                                                    </td>
                                                                                                                                </>
                                                                                                                            );
                                                                                                                        }
                                                                                                                    )}
                                                                                                            </tr>
                                                                                                        );
                                                                                                    }
                                                                                                )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Row>
                                                                    {index ===
                                                                        props?.values?.otherOperation?.length -
                                                                        1 && (
                                                                            <Label
                                                                                className="link_color_blue p-2 pb-0 pt-0"
                                                                                onClick={() =>
                                                                                    push({
                                                                                        fuelConsumption:
                                                                                            getInitialFuelConsumptionValue(),
                                                                                        energyConsumption:
                                                                                            getInitialEnergyConsumptionValue(),
                                                                                    })
                                                                                }
                                                                            >
                                                                                Add new Other Operation
                                                                            </Label>
                                                                        )}
                                                                    <hr className="m-0" />
                                                                </>
                                                            );
                                                        }
                                                    )}
                                            </React.Fragment>
                                        )}
                                    </FieldArray>
                                </CardBody>
                                <Row className="ele_row1 px-2 py-3">
                                    <div className="d-flex flex-wrap gap-5 m-0 btn_Margin">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleDynamicTabs(previousTabIndex + 1);
                                                }
                                            }}
                                        >
                                            Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        {tabsIdList[0] ===
                                            tabsList.filter(
                                                (item: any) => item.tab === "Other Special Operation"
                                            )[0]?.id &&
                                            record &&
                                            record.operation_precedence_id === 1 ? (
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm pos-start-0_75"
                                                onClick={() => {
                                                    props.setErrors({});
                                                    toggleTab(tabsList[0]?.id - 1);
                                                }}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        ) : tabsIdList[0] ===
                                            tabsList.filter(
                                                (item: any) => item.tab === "Other Special Operation"
                                            )[0]?.id &&
                                            record &&
                                            record.operation_precedence_id !== 1 ? (
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm pos-start-0_75"
                                                onClick={() => {
                                                    toggleTab(tabsList[0]?.id - 3);
                                                }}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                color="primary"
                                                className="btn_size_cstm pos-start-0_75"
                                                onClick={() => {
                                                    toggleDynamicTabs(previousTabIndex - 1);
                                                }}
                                            >
                                                <i className="bx bx-chevron-left me-1" /> Previous
                                            </Button>
                                        )}
                                    </div>
                                </Row>
                                <FormValuesDebug
                                    values={[
                                        props.values,
                                        props.errors,
                                        OtherSpecialOperationFormik.initialValues,
                                    ]}
                                />
                            </Form>
                        )}
                    </Formik>
                )}
        </Card>
    );
};

export default OtherSpecialOperationComponent;
