import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { Card, CardHeader, Row, Form, Button, CardFooter } from 'reactstrap'
import { loadVesselLubeOils } from 'VesselMaster/vesselMaster.hooks'
import { RootState } from 'index'
import { useSelector } from 'react-redux'
import env from 'environment/env'
import FirstReportForm, { fieldObjectInterface } from './FirstReportForm'
import FormValuesDebug from 'utils/debugTools/FormValuesDebug'
import { useQuery } from 'react-query'
import { queryKeyes } from 'shared/queryKeys'
import Loading from 'Components/Loading'
import ErrorComponent from 'Components/ErrorComponent'

interface FirstLoBunkeringType {
    toggleTab: (activeTab: number) => void,
    activeTab: number,
    voyageId: number,
    reportId: number
}

const FirstLoBunkering = ({
    toggleTab,
    activeTab,
    voyageId,
    reportId
}: FirstLoBunkeringType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /** Queries */
    /** Load vessel Lube Oil */
    const {
        data: VesselLubeOils,
        isLoading: VesselLubeOilsLoading,
        isError: VesselLubeOilsError,
    } = useQuery(
        [queryKeyes.vessel.VesselLubeOils.key, VesselID],
        async () => {
            return await loadVesselLubeOils(VesselID);
        },
        { staleTime: Infinity }
    );

    /** Assign initial values to formik object */
    const getVesselLubeOils = () => {
        return VesselLubeOils 
            ? VesselLubeOils.map((op: any) => ({
                label: op?.vessel_lube_oil_name,
                field: op?.vessel_lube_oil_name.toLowerCase().replace(/\s+/g, '_'),
                input: "text",
                unit: "ltr"
            }))
            : [];
    };

    const fieldArray: fieldObjectInterface[] = [
        ...getVesselLubeOils(),
        {
            label: "Vessel",
            field: "vessel",
            input: "text",
            value: VesselID
        },
        {
            label: "Voyage Information",
            field: "voyage_information",
            input: "text",
            value: voyageId
        },
        {
            label: "Vessel Reporting Information",
            field: "vessel_reporting_information",
            input: "text",
            value: reportId
        }
    ]

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        let arr: { [key: string]: string | number | boolean | null }[] = [];
        let obj: { [key: string]: string | number | boolean | null } = {};
        fieldArray.forEach((fieldObj: fieldObjectInterface) => {
            obj[fieldObj?.field] = fieldObj?.value ?? null;
        })
        arr.push(obj);
        return arr;
    }


    /** useEffect */
    useEffect(() => {
        FirstLoBunkeringFormik.initialValues.lubeBunkering = getInitialValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [VesselID, voyageId, VesselLubeOils, fieldArray])
    /** useEffect end */


    /** First Lube Oil Bunkering's formik object */
    const FirstLoBunkeringFormik: {
        initialValues: {
            lubeBunkering: { [key: string]: string | number | boolean | Date | null }[];
        };
    } = {
        initialValues: {
            lubeBunkering: getInitialValues(),
        },
    };

    if(VesselLubeOilsLoading) return <Loading message='Loading required data!' />
    if(VesselLubeOilsError) return <ErrorComponent message='Unable to load required data!' />

    return (
        <Card className="mb-0 p-0">
            <React.Fragment>
                <CardHeader className="p-2 border-0">
                    <h5 className="mb-0">Lube Oil ROB</h5>
                </CardHeader>
                <Formik
                    onSubmit={(_values: any, _actions: any) => {
                    }}
                    initialValues={FirstLoBunkeringFormik.initialValues}
                >
                    {(props) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <FirstReportForm
                                fieldArray={fieldArray}
                                isArray={true}
                                arrayName='lubeBunkering'
                                initialValues={FirstLoBunkeringFormik.initialValues.lubeBunkering}
                            />
                            <CardFooter className="p-2 py-3">
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleTab(activeTab + 1);
                                                }
                                            }}
                                        >
                                            Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm"
                                            onClick={() => {
                                                toggleTab(activeTab - 1);
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[props?.values, props?.errors, FirstLoBunkeringFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </Card>
    )
}

export default FirstLoBunkering
