import React, { Component } from "react";
import ReactEcharts from "echarts-for-react";
import { Row } from "reactstrap";

class FuelEnergyDeveloped extends Component<any> {
    Chart = () => {
        const data = this.props.fuelEUData;
        return {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                top: '5%',
                left: 'center'
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 40,
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data:
                        data?.cumulative_fuel_consumption_data?.map((item: any) => {
                            return (
                                { value: item?.total_fuel_consumed, name: item?.fuel_sub_type_name }
                            )
                        })
                }
            ]
        };
    }

    render() {
        return (
            <>
                <Row>
                    {this.props.fuelEUData && this.props.fuelEUData?.length > 0 ?
                        <ReactEcharts style={{ height: "350px" }} option={this.Chart()} /> :
                        <p className="mb-0 mt-2">No data available</p>
                    }
                </Row>
            </>
        );
    }
}

export default FuelEnergyDeveloped;
