import React from 'react'
import { Formik } from 'formik'
import { Card, CardHeader, Row, Form, Button, CardFooter } from 'reactstrap'
import { loadPortOptions, loadVesselFuelTypes } from 'VesselMaster/vesselMaster.hooks';
import { RootState } from 'index';
import { useSelector } from 'react-redux';
import env from 'environment/env';
import { fieldObjectInterface } from './FirstReportForm';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import BunkeringComponent from 'ReportingWizard/EngineerReport/partials/Bunkering/BunkeringComponent';

interface FirstBunkeringType {
    toggleTab: (activeTab: number) => void,
    activeTab: number,
    voyageId: number,
    reportId: number,
    report: { [key: string]: string | number | Date | boolean }
}

const FirstBunkering = ({
    toggleTab,
    activeTab,
    voyageId,
    reportId,
    report
}: FirstBunkeringType) => {
    /** State variables */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const fieldArray: fieldObjectInterface[] = [
        {
            label: "Fuel type",
            field: "fuel_type_name",
            input: "select",
            selectLabel: "fuel_type_name",
            selectValue: "id",
            loadOptions: () => loadVesselFuelTypes(VesselID)
        },
        {
            label: "Quanity bunkered",
            field: "quantity_bunkered",
            input: "text",
            unit: "mt"
        },
        {
            label: "Port of bunkering",
            field: "port_of_bunkering",
            input: "select",
            selectLabel: "port_name",
            selectValue: "id",
            loadOptions: loadPortOptions
        },
        {
            label: "Co2 equivalent factor",
            field: "co2_equivalent_factor_WtT",
            input: "text"
        },
        {
            label: "LCV",
            field: "lcv",
            input: "text"
        },
        {
            label: "E",
            field: "e",
            input: "text"
        },
        {
            label: "BDN identification number",
            field: "bdn_identification_number",
            input: "text"
        },
        {
            label: "Viscosity",
            field: "viscosity",
            input: "text",
            unit: "cst@50°C"
        },
        {
            label: "Sulphur percentage",
            field: "sulphur_percentage",
            input: "text",
            unit: "%m/m"
        },
        {
            label: "Density",
            field: "density",
            input: "text",
            unit: "kg/m3"
        },
        {
            label: "Density at temperature",
            field: "density_temperature",
            input: "text",
            unit: "kg/m3@15°C"
        },
        {
            label: "Date of bunkering",
            field: "date_of_bunkering",
            input: "datetime-local",
        },
        {
            label: "Vessel",
            field: "vessel",
            input: "text",
            value: VesselID
        },
        {
            label: "Voyage information",
            field: "voyage_information",
            input: "text",
            value: voyageId
        },
        {
            label: "Vessel reporting information",
            field: "vessel_reporting_information",
            input: "text",
            value: reportId
        }
    ]
    /** State variables end */

    /** Assign initial values to formik object */
    const getInitialValues = () => {
        let arr: { [key: string]: string | number | Date | boolean | null }[] = [];
        let obj: { [key: string]: string | number | Date | boolean | null } = {};
        fieldArray.forEach((fieldObj: fieldObjectInterface) => {
            obj[fieldObj?.field] = fieldObj?.value ?? null;
        })
        arr.push(obj);
        return arr;
    }

    /** Firt bunkering's formik object */
    const FirstBunkeringFormik: {
        initialValues: {
            bunkering: { [key: string]: string | number | boolean | Date | null }[];
        };
    } = {
        initialValues: {
            bunkering: getInitialValues(),
        },
    };

    return (
        <Card className="mb-0 p-0">
            <React.Fragment>
                <CardHeader className="p-2 border-0">
                    <h5 className="mb-0">Fuel ROB (batchwise)</h5>
                </CardHeader>
                <Formik
                    onSubmit={(_values: any, _actions: any) => {
                    }}
                    initialValues={FirstBunkeringFormik.initialValues}
                >
                    {(props) => (
                        <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                            <BunkeringComponent
                                ReportID={reportId}
                                VoyageID={voyageId}
                                VesselID={VesselID}
                                firstReport={true}
                                report={report}
                            />
                            <CardFooter className="p-2 py-3">
                                <Row className="ele_row1">
                                    <div className="d-flex flex-wrap gap-5">
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm pos-end"
                                            onClick={() => {
                                                if (env?.form_validation === false) {
                                                    toggleTab(activeTab + 1);
                                                }
                                            }}
                                        >
                                            Next <i className="bx bx-chevron-right ms-1" />
                                        </Button>
                                        <Button
                                            type="submit"
                                            color="primary"
                                            className="btn_size_cstm"
                                            onClick={() => {
                                                toggleTab(activeTab - 1);
                                            }}
                                        >
                                            <i className="bx bx-chevron-left me-1" /> Previous
                                        </Button>
                                    </div>
                                </Row>
                            </CardFooter>
                            <FormValuesDebug values={[props?.values, props?.errors, FirstBunkeringFormik.initialValues]} />
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        </Card>
    )
}

export default FirstBunkering