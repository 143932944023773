import Loading from "Components/Loading";
import { errorToast, successToast } from "Components/Toasts";
import {
  loadAuxEngineParameterObject,
  loadEnergyParameterObject,
  loadMainEngineParameterObject,
  loadVesselAuxEngines,
  loadVoyageParameterObject,
} from "VesselMaster/vesselMaster.hooks";
import { Field, FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useQuery } from "react-query";
import {
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Label,
  Form,
  Button,
} from "reactstrap";
import { queryKeyes } from "shared/queryKeys";
import FormValuesDebug from "utils/debugTools/FormValuesDebug";
import { commonValidationMessages } from "Components/ValidationErrorMessages";
import { EventPrecedence } from "shared/constants";
import apiGlobal from "global/api.global";
import { queryClient } from "react-query/queryClient";
import { errResponse } from "GenericForms/Helper";
import ErrorComponent from "Components/ErrorComponent";
import ErrorTooltip from "Components/ErrorTooltip";
import env from "environment/env";

interface EngineParameterComponentType {
  VesselID: number;
  VoyageID: number;
  ReportID: number;
  toggleAddTab: any;
  activeAddTab: number;
  record: any;
  toggleDynamicTabs: any;
  previousTabIndex: number;
}

const EngineParameterComponent = ({
  VesselID,
  VoyageID,
  ReportID,
  toggleAddTab,
  activeAddTab,
  record,
  toggleDynamicTabs,
  previousTabIndex,
}: EngineParameterComponentType) => {
  const [engineCount, setEngineCount] = useState(0);
  /** Queries */
  /** Vessel's auxilliary engines */
  const {
    data: AuxEngines,
    isLoading: AuxEnginesLoading,
    isError: AuxEnginesError,
  } = useQuery(
    [queryKeyes.vessel.VesselAuxEngines.key, VesselID],
    async () => {
      return await loadVesselAuxEngines(VesselID);
    },
    { staleTime: Infinity }
  );
  /** Voyage Parameter Object */
  const {
    data: VoyageParameterObject,
    isLoading: VoyageParameterObjectLoading,
    isError: VoyageParameterObjectError,
  } = useQuery(
    [queryKeyes.vessel.VoyageParameterObject.key, VesselID, ReportID],
    async () => {
      return await loadVoyageParameterObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Energy parameter object used for edit */
  const {
    data: EnergyParameterObject,
    isLoading: EnergyParameterObjectLoading,
    isError: EnergyParameterObjectError,
  }: { data: any[]; isLoading: any; isError: any } = useQuery(
    [queryKeyes.vessel.EnergyParameterObject.key, VesselID, ReportID],
    async () => {
      return await loadEnergyParameterObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Main Engine Parameter Object used for edit */
  const {
    data: MainEngineParameterObject,
    isLoading: MainEngineParameterObjectLoading,
    isError: MainEngineParameterObjectError,
  } = useQuery(
    [queryKeyes.vessel.MainEngineParameterObject.key, VesselID, ReportID],
    async () => {
      return await loadMainEngineParameterObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Auxilliary Engine Parameter Object used for edit */
  const {
    data: AuxEngineParameterObject,
    isLoading: AuxEngineParameterObjectLoading,
    isError: AuxEngineParameterObjectError,
  } = useQuery(
    [queryKeyes.vessel.AuxEngineParameterObject.key, VesselID, ReportID],
    async () => {
      return await loadAuxEngineParameterObject(VesselID, ReportID);
    },
    { staleTime: Infinity }
  );
  /** Queries end */

  /** Assign values to initial object of Engine Parameter */
  const getInitialMainEngineValues = () => {
    let obj: any[] = [];
    if (MainEngineParameterObject && MainEngineParameterObject.length > 0) {
      return MainEngineParameterObject;
    } else {
      if (
        VoyageParameterObject &&
        VoyageParameterObject.length > 0 &&
        obj.length <= VoyageParameterObject.length
      ) {
        VoyageParameterObject.map((machine: any) => {
          obj.push({
            load_indicator: 0,
            engine_rpm: machine.avg_rpm,
            slip_percentage: machine.slip_percentage,
            propeller_pitch: machine.propeller_pitch,
            machineryname: machine.machineryname,
            output_kw: EnergyParameterObject?.filter(
              (item: any) => item.machinery_name === machine.machinery_name
            ).map((energy: any) => {
              return energy.avg_power;
            })[0] ?? 0,
            percentage_mcr: 0,
            me_exh_temp_max: 0,
            me_exh_temp_min: 0,
            tc_1_inlet_exh_temp: 0,
            tc_1_outlet_exh_temp: 0,
            tc_2_inlet_exh_temp: 0,
            tc_2_outlet_exh_temp: 0,
            vessel_machinery: machine.machinery_name,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
          });
          return "";
        });
        return obj;
      }
    }
  };

  /** Assign values to initial object of Engine Parameter */
  const getInitialAuxEngineValues = () => {
    let obj: any[] = [];
    if (AuxEngineParameterObject && AuxEngineParameterObject.length > 0) {
      return AuxEngineParameterObject;
    } else {
      if (
        obj !== undefined &&
        AuxEngines !== undefined &&
        obj.length <= AuxEngines.length
      ) {
        AuxEngines.map((aux: any) => {
          obj.push({
            vessel_machinery: aux.id,
            machineryname: aux.vessel_machinery_name,
            alternator_load: 0,
            ae_avg_exh_temp: 0,
            ae_scavenge_pressure: 0,
            vessel: VesselID,
            voyage_information: VoyageID,
            vessel_reporting_information: ReportID,
          });
          return "";
        });
        return obj;
      }
    }
  };

  /** useEffect */
  useEffect(() => {
    setEngineCount(engineCount + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VoyageParameterObject, EnergyParameterObject]);
  useEffect(() => {
    EngineParameterFormik.initialValues.mainEngineParameter =
      getInitialMainEngineValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VoyageParameterObject, MainEngineParameterObject, EnergyParameterObject]);
  useEffect(() => {
    EngineParameterFormik.initialValues.auxEngineParamter =
      getInitialAuxEngineValues();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AuxEngines, AuxEngineParameterObject]);

  /** Engine parameter's formik object */
  const EngineParameterFormik = {
    initialValues: {
      mainEngineParameter: getInitialMainEngineValues(),
      auxEngineParamter: getInitialAuxEngineValues(),
    },
    validationSchema: Yup.object().shape({
      mainEngineParameter: Yup.array(
        Yup.object({
          load_indicator: Yup.string()
            .matches(
              /^\d{0,3}(?:\.\d{1,2})?$/,
              `${commonValidationMessages.before3after2}`
            )
            .test(
              "max-value",
              `${commonValidationMessages.maxVal100}`,
              (value) => parseFloat(value) <= 100.0
            ),
          percentage_mcr: Yup.string().matches(
            /^\d{0,3}(?:\.\d{1,2})?$/,
            `${commonValidationMessages.before3after2}`
          ),
          me_exh_temp_max: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          me_exh_temp_min: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          tc_1_inlet_exh_temp: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          tc_1_outlet_exh_temp: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          tc_2_inlet_exh_temp: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
          tc_2_outlet_exh_temp: Yup.string().matches(
            /^\d{0,3}(\.\d{1})?$/,
            `${commonValidationMessages.before3after1}`
          ),
        })
      ),
    }),
  };
  return (
    <>
      <CardHeader className="p-2">
        <div className="text-center mb-2">
          <Row>
            <Col>
              <h4 className="page_title pos-start mb-0">Engine Parameters</h4>
              <p className="card-title-desc pos-start">
                All readings since last report
              </p>
            </Col>
          </Row>
        </div>
      </CardHeader>
      {(VoyageParameterObjectLoading ||
        AuxEnginesLoading ||
        MainEngineParameterObjectLoading ||
        AuxEngineParameterObjectLoading ||
        EnergyParameterObjectLoading) && (
          <Loading message="Loading required data!" />
        )}
      {(VoyageParameterObjectError ||
        EnergyParameterObjectError ||
        AuxEnginesError) && (
          <ErrorComponent message="Unable to load required data!" />
        )}
      {MainEngineParameterObjectError && getInitialMainEngineValues()}
      {AuxEngineParameterObjectError && getInitialAuxEngineValues()}
      {!(
        VoyageParameterObjectLoading ||
        AuxEnginesLoading ||
        MainEngineParameterObjectLoading ||
        AuxEngineParameterObjectLoading ||
        EnergyParameterObjectLoading
      ) &&
        !(
          VoyageParameterObjectError ||
          EnergyParameterObjectError ||
          AuxEnginesError
        ) && (
          <Formik
            key={engineCount}
            onSubmit={(values: any, actions: any) => {
              actions.setSubmitting(false);
              const handleResponse = (response: any) => {
                if (response.status === 201 || response.status === 200) {
                  successToast("Data saved successfully!");
                  queryClient.invalidateQueries(
                    queryKeyes.vessel.MainEngineParameterObject.key
                  );
                }
              };
              if (VoyageParameterObject && VoyageParameterObject.length > 0) {
                if (
                  MainEngineParameterObject &&
                  MainEngineParameterObject.length > 0
                ) {
                  values.mainEngineParameter.map((engine: any) => {
                    apiGlobal
                      .put(`/lfonds_engine_parameter/${engine.id}/`, engine)
                      .then((res) => {
                        handleResponse(res);
                      })
                      .catch((err) => {
                        if (errResponse.includes(err.response.status)) {
                          errorToast(
                            "Internal error occured, please contact the admin"
                          );
                        }
                      });
                    return "";
                  });
                } else {
                  apiGlobal
                    .post(
                      `/lfonds_engine_parameter/`,
                      values.mainEngineParameter
                    )
                    .then((res) => {
                      handleResponse(res);
                    })
                    .catch((err) => {
                      if (errResponse.includes(err.response.status)) {
                        errorToast(
                          "Internal error occured, please contact the admin"
                        );
                      }
                    });
                }
              }
              if (
                AuxEngineParameterObject &&
                AuxEngineParameterObject.length > 0
              ) {
                values.auxEngineParamter.map((engine: any) => {
                  apiGlobal
                    .put(
                      `/lfonds_auxiliary_engine_parameter/${engine.id}/`,
                      engine
                    )
                    .then((res) => {
                      handleResponse(res);
                      queryClient.invalidateQueries(
                        queryKeyes.vessel.AuxEngineParameterObject.key
                      );
                    })
                    .catch((err) => {
                      if (errResponse.includes(err.response.status)) {
                        errorToast(
                          "Internal error occured, please contact the admin"
                        );
                      }
                    });
                  return "";
                });
              } else {
                apiGlobal
                  .post(
                    `/lfonds_auxiliary_engine_parameter/`,
                    values.auxEngineParamter
                  )
                  .then((res) => {
                    handleResponse(res);
                    queryClient.invalidateQueries(
                      queryKeyes.vessel.AuxEngineParameterObject.key
                    );
                  })
                  .catch((err) => {
                    if (errResponse.includes(err.response.status)) {
                      errorToast(
                        "Internal error occured, please contact the admin"
                      );
                    }
                  });
              }
            }}
            initialValues={EngineParameterFormik.initialValues}
            validationSchema={EngineParameterFormik.validationSchema}
          >
            {({
              values,
              errors,
              handleSubmit,
            }: {
              values: any;
              errors: any;
              handleSubmit: any;
              handleChange: any;
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <FieldArray name="mainEngineParameter">
                  {() => (
                    <>
                      {values &&
                        values?.mainEngineParameter?.map(
                          (engine: any, index: number) => {
                            return (
                              <Card className="mb-0 border-0">
                                <CardHeader className="p-2">
                                  <h5>{engine.machineryname}</h5>
                                </CardHeader>
                                <CardBody className="p-2">
                                  <Row className="mb-3">
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`load_indicator_${index}`}
                                      >
                                        Load indicator
                                      </Label>
                                      <Field
                                        name={`mainEngineParameter.${index}.load_indicator`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`load_indicator_${index}`}
                                      />
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          .load_indicator &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`load_indicator_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                .load_indicator
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  .load_indicator
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`mainEngineParameter.${index}.slip_percentage`}
                                      >
                                        Engine RPM
                                      </Label>
                                      <Field
                                        name={`mainEngineParameter.${index}.engine_rpm`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`mainEngineParameter.${index}.engine_rpm`}
                                        value={engine.engine_rpm}
                                        disabled
                                      />
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`mainEngineParameter.${index}.slip_percentage`}
                                      >
                                        Slip percentage
                                      </Label>
                                      <Field
                                        name={`mainEngineParameter.${index}.slip_percentage`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`mainEngineParameter.${index}.slip_percentage`}
                                        value={engine.slip_percentage}
                                        disabled
                                      />
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`mainEngineParameter.${index}.propeller_pitch`}
                                      >
                                        Propeller pitch
                                      </Label>
                                      <Field
                                        name={`mainEngineParameter.${index}.propeller_pitch`}
                                        type="text"
                                        className="form-control text-right max-width-7"
                                        id={`mainEngineParameter.${index}.propeller_pitch`}
                                        value={engine.propeller_pitch}
                                        disabled
                                      />
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`output_kw-${index}`}
                                      >
                                        Output
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.output_kw`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`output_kw-${index}`}
                                          disabled
                                        />
                                        <div className="input-group-text round_border">
                                          kW
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          .output_kw &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`output_kw-${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                .output_kw
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  .output_kw
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`percentage_mcr_${index}`}
                                      >
                                        Percentage MCR
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.percentage_mcr`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`percentage_mcr_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          %
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.percentage_mcr &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`percentage_mcr_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.percentage_mcr
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.percentage_mcr
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`me_exh_temp_max_${index}`}
                                      >
                                        M/E exh. temp. max
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.me_exh_temp_max`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`me_exh_temp_max_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.me_exh_temp_max &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`me_exh_temp_max_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.me_exh_temp_max
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.me_exh_temp_max
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`me_exh_temp_min_${index}`}
                                      >
                                        M/E exh. temp. min
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.me_exh_temp_min`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`me_exh_temp_min_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.me_exh_temp_min &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`me_exh_temp_min_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.me_exh_temp_min
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.me_exh_temp_min
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                  </Row>
                                  <Row className="mb-3">
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`tc_1_inlet_exh_temp_${index}`}
                                      >
                                        T/C 1 inlet exh. temp.
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.tc_1_inlet_exh_temp`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`tc_1_inlet_exh_temp_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.tc_1_inlet_exh_temp &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`tc_1_inlet_exh_temp_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.tc_1_inlet_exh_temp
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.tc_1_inlet_exh_temp
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`tc_1_outlet_exh_temp_${index}`}
                                      >
                                        T/C 1 outlet exh. temp.
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.tc_1_outlet_exh_temp`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`tc_1_outlet_exh_temp_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.tc_1_outlet_exh_temp &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`tc_1_outlet_exh_temp_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.tc_1_outlet_exh_temp
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.tc_1_outlet_exh_temp
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`tc_2_inlet_exh_temp_${index}`}
                                      >
                                        T/C 2 inlet exh. temp.
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.tc_2_inlet_exh_temp`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`tc_2_inlet_exh_temp_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.tc_2_inlet_exh_temp &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`tc_2_inlet_exh_temp_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.tc_2_inlet_exh_temp
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.tc_2_inlet_exh_temp
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`tc_2_outlet_exh_temp_${index}`}
                                      >
                                        T/C 2 outlet exh. temp.
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          name={`mainEngineParameter.${index}.tc_2_outlet_exh_temp`}
                                          type="text"
                                          className="form-control text-right max-width-7"
                                          id={`tc_2_outlet_exh_temp_${index}`}
                                        />
                                        <div className="input-group-text round_border">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                      {errors?.mainEngineParameter &&
                                        errors?.mainEngineParameter[index]
                                          ?.tc_2_outlet_exh_temp &&
                                        env?.form_validation === true && (
                                          <ErrorTooltip
                                            target={`tc_2_outlet_exh_temp_${index}`}
                                            message={
                                              errors?.mainEngineParameter[index]
                                                ?.tc_2_outlet_exh_temp
                                            }
                                            open={
                                              errors?.mainEngineParameter &&
                                                errors?.mainEngineParameter[index]
                                                  ?.tc_2_outlet_exh_temp
                                                ? true
                                                : false
                                            }
                                          />
                                        )}
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            );
                          }
                        )}
                    </>
                  )}
                </FieldArray>
                <FieldArray name="auxEngineParamter">
                  {() => (
                    <>
                      {values &&
                        values?.auxEngineParamter?.map(
                          (engine: any, index: number) => {
                            return (
                              <Card className="mb-0 rounded-0">
                                <CardHeader className="p-2">
                                  <h5>{engine.machineryname}</h5>
                                </CardHeader>
                                <CardBody className="p-2">
                                  <Row className="mb-3">
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`alternator_load_${index}`}
                                      >
                                        Alternator load
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          type="text"
                                          id={`alternator_load_${index}`}
                                          name={`auxEngineParamter.${index}.alternator_load`}
                                          className="form-control text-right max-width-7"
                                        />
                                        <div className="input-group-text">
                                          kW
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`ae_avg_exh_temp_${index}`}
                                      >
                                        A/E avg exhaust temperature
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          type="text"
                                          id={`ae_avg_exh_temp_${index}`}
                                          name={`auxEngineParamter.${index}.ae_avg_exh_temp`}
                                          className="form-control text-right max-width-7"
                                        />
                                        <div className="input-group-text">
                                          <sup>o</sup>C
                                        </div>
                                      </div>
                                    </Col>
                                    <Col lg={3}>
                                      <Label
                                        className="mb-0"
                                        for={`ae_scavenge_pressure_${index}`}
                                      >
                                        A/E scavenge pressure
                                      </Label>
                                      <div className="input-group">
                                        <Field
                                          type="text"
                                          id={`ae_scavenge_pressure_${index}`}
                                          name={`auxEngineParamter.${index}.ae_scavenge_pressure`}
                                          className="form-control text-right max-width-7"
                                        />
                                        <div className="input-group-text">
                                          kg/cm<sup>2</sup>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            );
                          }
                        )}
                    </>
                  )}
                </FieldArray>
                <Row className="ele_row1">
                  <div className="d-flex flex-wrap gap-5 mt-3 btn_Margin">
                    <Button
                      type="submit"
                      color="primary"
                      className="btn_size_cstm pos-end mr-0-60"
                      onClick={() => {
                        toggleAddTab(activeAddTab + 1);
                      }}
                    >
                      Next <i className="bx bx-chevron-right ms-1" />
                    </Button>
                    {record &&
                      record.operation_precedence_id === EventPrecedence.NOON ? (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm pos-start-0_75"
                        onClick={() => {
                          toggleDynamicTabs(previousTabIndex - 1);
                        }}
                      >
                        <i className="bx bx-chevron-left me-1" /> Previous
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        color="primary"
                        className="btn_size_cstm pos-start-0_75 "
                        onClick={() => {
                          toggleAddTab(1);
                        }}
                      >
                        <i className="bx bx-chevron-left me-1" /> Previous
                      </Button>
                    )}
                  </div>
                </Row>
                <FormValuesDebug
                  values={[values, errors, EngineParameterFormik.initialValues]}
                />
              </Form>
            )}
          </Formik>
        )}
    </>
  );
};

export default EngineParameterComponent;
