import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Row,
    Input,
    Label,
    Button,
    Form,
    FormFeedback,
    FormGroup,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import apiGlobal from "../../global/api.global";
import { successToast } from "Components/Toasts";
import { useSelector } from "react-redux";
import { RootState } from "index";

const SatelliteCommunication = () => {
    const { VesselID } = useSelector((state: RootState) => state.Reporting);
    const [data, setData] = useState({
        email: "",
        phone_bridge: "",
        phone_master: "",
        fax: "",
        immrst_c1: "",
        immrst_c2: "",
        mmmsi: "",
        vessel: VesselID,
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            phone_bridge: "",
            phone_master: "",
            fax: "",
            immrst_c1: "",
            immrst_c2: "",
            mmmsi: "",
            vessel: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Must be a valid Email")
                .required("Please enter vessel's email id"),
            phone_bridge: Yup.string()
                .matches(/^[0-9]{1,14}$/, "Enter upto 14 digits only")
                .required("Please enter bridge phone number"),
            phone_master: Yup.string()
                .matches(/^[0-9]{1,14}$/, "Enter upto 14 digits only")
                .required("Please enter master phone number"),
            fax: Yup.string()
                .matches(/^[0-9]{1,14}$/, "Please enter upto 14 digits only")
                .required("Please enter company's fax number"),
            immrst_c1: Yup.string()
                .matches(
                    /^[4][0-9]{1,9}$/,
                    "Please enter 9 upto digits only and the first digit should be 4"
                )
                .required("Please enter the vessel's Immrst.C1"),
            immrst_c2: Yup.string().matches(
                /^[4][0-9]{1,9}$/,
                "Please enter 9 upto digits only and the first digit should be 4"
            ),
            mmmsi: Yup.string()
                .matches(/^[0-9]{1,9}$/, "Please enter upto 9 digits only")
                .required("Please enter the vessel's MMSI"),
        }),
        onSubmit: () => { },
    });

    const handleChanges = (event: any, validation: any) => {
        validation.handleChange(event);
        handle(event);
    };

    function handle(e: any) {
        const newdata: any = { ...data };
        newdata[e.target.name] = e.target.value;
        setData(newdata);
    }

    const datas = {
        email: data.email,
        phone_bridge: data.phone_bridge,
        phone_master: data.phone_master,
        fax: data.fax,
        immrst_c1: data.immrst_c1,
        immrst_c2: data.immrst_c2,
        mmmsi: data.mmmsi,
        vessel: data.vessel,
    };

    function submit() {
        apiGlobal
            .post(`/vessel_satellite_information/`, datas)
            .then(() => {
                successToast("Data saved successfully!");
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <Card>
                <CardBody>
                    <Form
                        className="needs-validation"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            submit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.email || data.email}
                                        invalid={
                                            validation.touched.email && validation.errors.email
                                                ? true
                                                : false
                                        }
                                        autoFocus
                                    />
                                    {validation.touched.email && validation.errors.email ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.email}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="email" className="asteric">
                                        Email id
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="phone_bridge"
                                        name="phone_bridge"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.phone_bridge || data.phone_bridge}
                                        invalid={
                                            validation.touched.phone_bridge &&
                                                validation.errors.phone_bridge
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.phone_bridge &&
                                        validation.errors.phone_bridge ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.phone_bridge}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="phone_bridge" className="asteric">
                                        Phone bridge
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="phone_master"
                                        name="phone_master"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.phone_master || data.phone_master}
                                        invalid={
                                            validation.touched.phone_master &&
                                                validation.errors.phone_master
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.phone_master &&
                                        validation.errors.phone_master ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.phone_master}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="phone_master" className="asteric">
                                        Phone master
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="fax"
                                        name="fax"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.fax || data.fax}
                                        invalid={
                                            validation.touched.fax && validation.errors.fax
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.fax && validation.errors.fax ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.fax}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="fax" className="asteric">
                                        Fax
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="immrst_c1"
                                        name="immrst_c1"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.immrst_c1 || data.immrst_c1}
                                        invalid={
                                            validation.touched.immrst_c1 &&
                                                validation.errors.immrst_c1
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.immrst_c1 &&
                                        validation.errors.immrst_c1 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.immrst_c1}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="immrst_c1" className="asteric">
                                        Immrst.C1
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="immrst_c2"
                                        name="immrst_c2"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.immrst_c2 || data.immrst_c2}
                                        invalid={
                                            validation.touched.immrst_c2 &&
                                                validation.errors.immrst_c2
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.immrst_c2 &&
                                        validation.errors.immrst_c2 ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.immrst_c2}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="immrst_c2">Immrst.C2</Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <FormGroup className="form-floating mb-3">
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="mmmsi"
                                        name="mmmsi"
                                        placeholder="example"
                                        onChange={(e: any) => handleChanges(e, validation)}
                                        value={validation.values.mmmsi || data.mmmsi}
                                        invalid={
                                            validation.touched.mmmsi && validation.errors.mmmsi
                                                ? true
                                                : false
                                        }
                                    />
                                    {validation.touched.mmmsi && validation.errors.mmmsi ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.mmmsi}
                                        </FormFeedback>
                                    ) : null}
                                    <Label for="mmmsi" className="asteric">
                                        MMSI
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="d-flex flex-wrap gap-5 grp_justify_right">
                                <Button
                                    type="submit"
                                    color="primary"
                                    className="btn_size4_5_cstm"
                                    onClick={submit}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="reset"
                                    color="danger"
                                    className="btn_size4_5_cstm"
                                >
                                    Reset
                                </Button>
                            </div>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default SatelliteCommunication;
