import React from 'react'

const NotificationTableHead = () => {
    return (
        <thead className="thead-light">
            <tr>
                <th style={{ width: '5%' }} className='sr-no-width'>#</th>
                <th style={{ width: '10%' }} className='text-center'>Category</th>
                <th style={{ width: '10%' }} className='text-center'>Priority</th>
                <th style={{ width: '21%' }} className='text-center'>Date & Time</th>
                <th style={{ width: '18%' }} className='text-center'>Vessel Name</th>
                <th style={{ width: '57%' }} >Title</th>
            </tr>
        </thead>
    )
}

export default NotificationTableHead