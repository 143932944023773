
import React from 'react';
import { Badge } from 'reactstrap';
import { CSSModule } from 'reactstrap/types/lib/utils';

export interface BadgeProps {
    text: string;
    badgeColors: String; // badgeColors is a string in the format {'color': '#000', 'background': '#FFF'}
    innerRef?: React.Ref<HTMLElement>;
    cssModule?: CSSModule;
    [key: string]: any;
}

const BadgeComponent: React.FC<BadgeProps> = ({ text, badgeColors, ...props }: { text: String, badgeColors: String }) => {
    // Parse badgeColors if it's a string
    let colors = { 'color': '', 'background': '' };
    try {
        // Try parsing the badgeColors string to extract color and background
        colors = typeof badgeColors === 'string' ? JSON.parse(badgeColors?.replace(/'/g, '"')) : badgeColors;
    } catch (error) {
        console.error('Invalid JSON format for badgeColors', error);
    }
    return (
        <Badge color={'custom'} style={{ color: colors?.color, backgroundColor: colors?.background }} {...props}>
            {text}
        </Badge>)
};

export default BadgeComponent;