import React from 'react'
import { Roles } from 'shared/constants'
import { hasRole } from 'utils/auth/authUtils'

const VoyageAdjustmentTableHeadComponent = () => {
    return (
        <thead className="table-light">
            <tr>
                <th className='p-2 align-middle sr-no-width'>#</th>
                <th className='p-2 align-middle' style={{ width: '22%' }}>Duration</th>
                <th className='p-2 align-middle' style={{ width: '40%' }}>Voyage Adjustment Event</th>
                {!(hasRole(Roles.CHIEFENGINEER)) &&
                    <th className='p-2 aligen-middle text-center' style={{ width: '20%' }}>Actions</th>
                }
            </tr>
        </thead>
    )
}

export default VoyageAdjustmentTableHeadComponent