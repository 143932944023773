import apiGlobal from '../../../global/api.global';
import { useSelector } from 'react-redux';
import { RootState } from '../../../';
import { Button, Card, CardBody, CardFooter, Col, Form, Input, Row } from 'reactstrap';
import { errorToast, successToast } from '../../../Components/Toasts';
import { errResponse } from 'GenericForms/Helper';
import { loadCargoReliquificationWithkWh } from 'VesselMaster/vesselMaster.hooks';
import { queryKeyes } from 'shared/queryKeys';
import { useQuery } from 'react-query';
import { Field, FieldArray, FieldProps, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import { queryClient } from 'react-query/queryClient';

const CargoReliquification = () => {
    /** Retrieves the VesselID from the Redux store. */
    const { VesselID } = useSelector((state: RootState) => state.Reporting);

    /**
     * Fetches cargo reliquification data along with machinery kWh meter details using the `useQuery` hook.
     * It loads data specific to the vessel's ID and keeps the result in cache indefinitely (`staleTime: Infinity`).
     */
    const { data: CargoReliquification, isLoading: CargoReliquificationLoading, isError: CargoReliquificationError }:
        { data: any[], isLoading: any, isError: any } = useQuery(
            [queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key, VesselID],
            async () => { return await loadCargoReliquificationWithkWh(VesselID) },
            {
                enabled: true,
                staleTime: Infinity,
            }
        );

    /**
     * Determines the initial values for the cargo reliquification form based on the fetched data.
     * If there is any cargo reliquification data available, it is returned for use as initial values.
     */
    const getCargoReliquificationInitailValues = () => {
        if (CargoReliquification?.length > 0) {
            return CargoReliquification;
        }
    };

    /** Defines the Formik configuration object for handling the cargo reliquification form. */
    const CargoReliquificationFormik = {
        initialValues: {
            cargoReliquification: getCargoReliquificationInitailValues(),
        },
        validationSchema: {
            // Add validation schema here if needed
        },
    };

    return (
        <>
            <Card className='border-0'>
                {CargoReliquificationLoading && <Loading message="Loading required data!" />}
                {CargoReliquificationError && <ErrorComponent message="Error loading required data!" />}
                {!(CargoReliquificationLoading) && !(CargoReliquificationError) &&
                    <Formik
                        onSubmit={(values: any, actions: any) => {
                            actions.setSubmitting(false)
                            values?.cargoReliquification?.forEach((val: any) => {
                                apiGlobal.put(`/special_operation_energy_setting/${val.id}/`, val)
                                    .then(res => {
                                        if (res.status === 200) {
                                            successToast("Data saved successfully!");
                                            queryClient.invalidateQueries(queryKeyes.vessel.CargoReliquificationWithkWhMachineries.key);
                                        }
                                    })
                                    .catch(err => {
                                        if (errResponse.includes(err?.response?.status)) {
                                            errorToast("Internal error occured, please contact the admin");
                                        }
                                    });
                            })
                        }}
                        initialValues={CargoReliquificationFormik.initialValues}
                    >
                        {props => (
                            <Form onSubmit={props?.handleSubmit} noValidate autoComplete='off'>
                                <CardBody className='p-0'>
                                    <Row>
                                        <Col sm={12}>
                                            <div className="table-responsive p-0">
                                                <table className="table mb-2">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th className='p-2 text-center align-middle sr-no-width'>#</th>
                                                            <th className='p-2 align-middle'>Machinery name</th>
                                                            <th className='p-2 align-middle text-center'>Is kWh meter fitted on power pack/electrical motor panel?</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FieldArray name='cargoReliquification'>
                                                            {() => (
                                                                <>
                                                                    {CargoReliquificationFormik?.initialValues &&
                                                                        CargoReliquificationFormik?.initialValues?.cargoReliquification?.map((cargoReliquification: any, index: number) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td className='p-2 align-middle text-center'>{index + 1}</td>
                                                                                    <td className='p-2 align-middle'>{cargoReliquification.vessel_machinery_ec_group_name}</td>
                                                                                    <td className='p-2 align-middle text-center'>
                                                                                        <Field name={`cargoReliquification.${index}.cargo_reliquification_kwh_meter`}>
                                                                                            {({ field }: FieldProps) => (
                                                                                                <Input
                                                                                                    type='checkbox'
                                                                                                    id={`cargoReliquification.${index}.cargo_reliquification_kwh_meter`}
                                                                                                    name={field.name}
                                                                                                    {...field}
                                                                                                />
                                                                                            )}
                                                                                        </Field>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            )
                                                            }
                                                        </FieldArray>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter className='p-2 py-3 mb-3'>
                                    <Button type="submit" color='primary' className='justify_right'>Save</Button>
                                </CardFooter>
                                <Row className='mt-2'>
                                    <FormValuesDebug values={[props?.values, props?.errors, CargoReliquificationFormik.initialValues]} />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                }
            </Card>
        </>
    )
}
export default CargoReliquification;