import { Navigate } from 'react-router-dom';
import { clearLogout } from 'utils/auth/authUtils';
import env from '../environment/env';

const Logout = () => {
  clearLogout();
  return (
        <Navigate to={env.desktop_app === true ? "/#login" : "/login"} />
  )
}

export default Logout;