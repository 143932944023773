import ReportDetailsHeader from 'Components/ReportDetailsHeader'
import React, { useState } from 'react'
import { Row, Col, TabContent, TabPane, Card, CardHeader, CardBody } from 'reactstrap'
import OtherDetailsComponent from './partials/OtherDetailsComponent';
import ProactiveETADComponent from './partials/ProactiveETADComponent';

const ProactiveAddInformation = ({
    activeTab,
    toggleTab,
    ReportID,
    VesselID,
    VoyageID,
    record,
    lastRecord
}: any) => {
    /** State variables */
    const [activeAddTab, setactiveAddTab] = useState(1);
    /** State variables end */

    /** Fucntion to change tabs */
    function toggleAddTab(tab: any) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 2) {
                setactiveAddTab(tab);
            }
        }
    }
    return (
        <Card className='p-0 mb-0 border-0 '>
            <CardHeader className='p-2'>
                <div className="text-center">
                    <Row>
                        <Col>
                            <h4 className="page_title pos-start mb-0">Additional Information</h4>
                            <p className="card-title-desc pos-start">All readings since last report</p>
                        </Col>
                        <Col>
                            <ReportDetailsHeader />
                        </Col>
                    </Row>
                </div>
            </CardHeader>
            <CardBody className='p-0'>
                <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <TabContent activeTab={activeAddTab}>
                        <TabPane tabId={1}>
                            <ProactiveETADComponent
                                ReportID={ReportID}
                                VesselID={VesselID}
                                VoyageID={VoyageID}
                                activeTab={activeTab}
                                toggleTab={toggleTab}
                                activeAddTab={activeAddTab}
                                toggleAddTab={toggleAddTab}
                                record={record}
                                lastRecord={lastRecord}
                            />
                        </TabPane>
                        <TabPane tabId={2}>
                            <OtherDetailsComponent
                                ReportID={ReportID}
                                VesselID={VesselID}
                                VoyageID={VoyageID}
                                activeTab={activeTab}
                                toggleTab={toggleTab}
                                activeAddTab={activeAddTab}
                                toggleAddTab={toggleAddTab}
                                record={record}
                                lastRecord={lastRecord}
                            />
                        </TabPane>
                    </TabContent>
                </div>
            </CardBody>
        </Card>
    )
}

export default ProactiveAddInformation